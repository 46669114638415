import { useEffect, useRef, useState, useContext } from "react";
import { defaults_t } from "../../../types";
import ReactPlayer from "react-player";
import "./App.css";
import "./AddProject.css";
import "react-datepicker/dist/react-datepicker.css";
import KonvaEditor from "../../../components/KonvaEditorLite";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import { useParams, useNavigate } from "react-router-dom";
import Konva from "konva";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import LoadingScreen from "../../global/LoadingScreen";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Box, Typography, useTheme, Grid, Fade, Alert, AlertTitle, Container, Dialog, DialogContent } from "@mui/material";
import { Formik } from "formik";
import { format } from "date-fns";
import { fullDiagramDescription, useWindowSize } from "../../../utils/konvaUtils";

function CreateReport() {
  const { t } = useTranslation();

  const [currentArrows, setCurrentArrows] = useState<
    {
      start: Konva.Group;
      children: { x: number; y: number }[];
      end: Konva.Group;
    }[]
  >([]);
  const { idSimulation } = useParams();
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const editorRef = useRef<Konva.Stage>();
  const editorContainerRef = useRef<HTMLInputElement>();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [project, setProject] = useState<any>();
  const [isSent, setIsSent] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [scenarios, setScenarios] = useState<any>([]);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [pressDelete, setPressDelete] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [canCreateScenario, setCanCreateScenario] = useState(false);
  const [render, setRender] = useState(0);
  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const [idNav, setIdNav] = useState(0);
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  const { currentUser, logout } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const handleClose = () => {
    deleteScenario(idToDelete);
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const handleCloseDialog = () => {
    closeMaybe();
    setOpen(false);
  };

  const pressDeleted = () => {
    setPressDelete(true);
  };

  const closeMaybe = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_CALL + "users", config);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getProject = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + `simulation/${idSimulation}`, config).then();
      if (response.data.data.email_comercial !== currentUser.email) {
        setIsLoading(true);
        navigate("/lite/simulations")
      }
      setProject({ ...response.data.data, scenario_name: "" });
      setPePcLayout(response.data.data["pepc_layout"]);
      setCanCreateScenario(true);

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `scenario/simulation/${idSimulation}`, config);
      setScenarios(response2.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const deleteScenario = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `scenario/${id}`, config);
      getProject();
      setOpen(false);
      setIsEliminated(true);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDefaults();
    getProject();
    // if (localStorage.getItem("connections")) {
    //   setPePcConnections(JSON.parse(localStorage.getItem("connections")));
    // } else {
    //   setPePcConnections(pePcConnections);
    // }
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_CALL + "scenario", values, config);
      window.scrollTo(0, 0);
      setIsSent(true);
      setIdNav(response.data.data.insertId);
      setIsLoading(false);
      setHide(true);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const [pePcConnections, setPePcConnections] = useState<{
    [key: string]: { [key: string]: number };
  }>({});
  const [pePcConnectionsRe, setPePcConnectionsRe] = useState<{
    [key: string]: { [key: string]: number };
  }>({});
  const [konvaWidth, setKonvaWidth] = useState(100);
  useWindowSize(editorContainerRef, setKonvaWidth);
  const [pePcConnectionsAdjustedDiameter, setPePcConnectionsAdjustedDiameter] = useState<{ [key: string]: { [key: string]: number } }>({});

  const getDefaults = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getDivTotalCaudal = (children: string) => {
    const layout = project.pepc_layout;
    const div_key = Object.keys(layout).find((key) => layout[key]["nickname"] === children);
    let res = 0;
    if (div_key) {
      // CHANGE JUST IN CASE
      // res += project.pepc_layout[div_key]["caudal_total"];
      res += parseFloat(layout[div_key]["caudal_total"]);
    }
    return res;
  };

  const getChildren = (connectionStart: string) => {
    let res = [];
    if (connectionStart in pePcConnections)
      for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
        const children = getChildren(connectionEnd);
        res = [...res, ...children];
      }
    else return [connectionStart];
    return res;
  };
  const calculateAdjustedDiameter = (caudal, tipo_tubo) => {
    if (tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };

  const getAdjustedDiameter = (connectionStart: string, connectionEnd: string) => {
    if (!pePcConnections) return 16;
    const connectionEndChildren = getChildren(connectionEnd);
    const current_res: {
      ligacao: string;
      comprimento: number;
      coef_simultaniedade: number;
      caudal: number;
      diametro_ajustado: number;
      agua_estagnada: number;
      perda_termica: number;
    } = {
      ligacao: "",
      comprimento: 0,
      coef_simultaniedade: 0,
      caudal: 0,
      diametro_ajustado: 0,
      agua_estagnada: 0,
      perda_termica: 0,
    };
    current_res.ligacao = connectionStart + " -> " + connectionEnd;
    current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
    let caudal_total = 0;
    for (let child of connectionEndChildren) {
      // CHANGE JUST IN CASE
      caudal_total += getDivTotalCaudal(child);
      // caudal_total = getDivTotalCaudal(child);
    }
    if (!caudal_total) caudal_total = 0;
    current_res.caudal = current_res.coef_simultaniedade * caudal_total;
    const tubo_stats = calculateAdjustedDiameter(current_res.caudal, project.tipo_tubo);
    return tubo_stats.diametro;
  };

  useEffect(() => {
    const res = {};

    if (pePcConnections && render < 1) {
      for (let connectionStart of Object.keys(pePcConnections)) {
        res[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart]))
          res[connectionStart][connectionEnd] = getAdjustedDiameter(connectionStart, connectionEnd);
      }
      setPePcConnectionsAdjustedDiameter(res);
    }
  }, [pePcConnections]);

  useEffect(() => {
    const res2 = {};
    if (pePcConnections && render < 1) {
      for (let connectionStart of Object.keys(pePcConnections)) {
        res2[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) res2[connectionStart][connectionEnd] = 0;
      }
      setPePcConnectionsRe(res2);
    }
  }, [pePcConnections]);

  const loadFromSavedObject = localStorage.getItem("editor_image")
    ? JSON.parse(localStorage.getItem("editor_image"))
    : scenarios?.editor_image; // Use scenarios?.editor_image if localStorage is empty

  //LOCAL STORAGE IMAGE

  useEffect(() => {
    // Function that will run every 10 seconds
    const intervalId = setInterval(() => {
      const complete_project = { ...project };
      const connections_res = {};
      let hasRecVar = false;

      // Build connections_res object
      for (let connectionStart of Object.keys(pePcConnections)) {
        connections_res[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
          const recirculacaoValue = pePcConnectionsRe[connectionStart][connectionEnd];
          if (recirculacaoValue === 1 || recirculacaoValue === "1") {
            hasRecVar = true;
          }

          connections_res[connectionStart][connectionEnd] = {
            width: pePcConnections[connectionStart][connectionEnd]?.width || 5,
            adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
            recirculacao: recirculacaoValue,
          };
        }
      }

      complete_project["pepc_connections"] = connections_res;

      complete_project["editor_image"] = JSON.stringify(fullDiagramDescription(editorRef.current, currentArrows));

      const transformedArray = [];

      for (const start in pePcConnections) {
        for (const end in pePcConnections[start]) {
          transformedArray.push({
            start: start,
            children: [],
            end: end,
          });
        }
      }

      // Update localStorage
      localStorage.setItem(
        "editor_image",
        // JSON.stringify({
        //   pePcLayout: pePcLayout,
        //   pePcConnections: complete_project["pepc_connections"],
        // })
        JSON.stringify({
          pePcLayout: fullDiagramDescription(editorRef.current, currentArrows).pePcLayout,
          pePcConnections: transformedArray,
        })
      );

      localStorage.setItem(
        "connections",
        // JSON.stringify({
        //   pePcLayout: pePcLayout,
        //   pePcConnections: complete_project["pepc_connections"],
        // })
        JSON.stringify(connections_res)
      );
    }, 10000); // 10,000 ms = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [project, pePcConnections, pePcConnectionsRe, pePcConnectionsAdjustedDiameter, currentArrows, editorRef]);

  const handleClickOpenVideo = () => {
    setOpen(true);
  };

  const handleCloseVideo = () => {
    setOpen(false);
  };


  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("create_scenario.title")} subtitle={t("create_scenario.subtitle")} />
          </Box>

          <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          maxWidth="xl"
          fullWidth
          onContextMenu={(e) => e.preventDefault()} // Prevent context menu when the dialog is open
        >
          <DialogContent sx={{ padding: 0 }}>
            {" "}
            {/* Remove padding to maximize video space */}
            <ReactPlayer
              playing
              url="https://vimeo.com/1025227357?share=copy#t=0"
              width="100%"
              height="100%"
              style={{ aspectRatio: "16/9" }} // Maintains a 16:9 aspect ratio
            />
          </DialogContent>
        </Dialog>

          <Grid container justifyContent="space-between" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              ml="20px"
              onClick={() => navigate("/lite/my_simulations/")}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                As Minhas Simulações
              </Typography>
            </Box>
            <Box
            onClick={() => handleClickOpen()}
            mr="40px"
            p="5px 7px 5px 5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            alignItems="center"
            backgroundColor={colors.greenAccent[700]}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <MovieOutlinedIcon
              sx={{
                textDecoration: "none",
                color: colors.primary[100],
                justifyContent: "center",
              }}
            />
            <Typography
              justifyContent="center"
              color={colors.primary[100]}
              sx={{
                ml: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                justifyContent: "center",
                marginTop: "1px",
              }}
            >
              Ver Tutorial (00:27)
            </Typography>
          </Box>
          </Grid>

          {project && project !== undefined && isLoading === false && canCreateScenario === true ? (
            <>
              <Container component="main" maxWidth="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  //AQUI
                  // alignItems="center"
                  mt="30px"
                >
                  <Fade
                    in={generalError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setGeneralError(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.general_error.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.general_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isResidential}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsResidential(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_residential.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_residential.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={sessionError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setSessionError(false);
                        navigate("/login");
                        logout();
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.session_error.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.session_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEmpty}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsEmpty(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_empty.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_empty.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isSent}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsSent(false);
                        if (idSimulation) {
                          navigate("/lite/report/" + idSimulation);
                          window.location.reload();
                        }
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_sent.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_sent.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEliminated}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsEliminated(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_eliminated.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_eliminated.description")}
                    </Alert>
                  </Fade>

                  {hide === false ? (
                    <Formik
                      // enableReinitialize={true}
                      onSubmit={handleFormSubmit}
                      initialValues={project}
                      validateOnChange={false}
                      validateOnBlur={true}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={4} justifyContent="left">
                            <Grid item xs={12}>
                              <Typography component="h1" variant="h3">
                                {t("create_scenario.form.title2")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box>
                                <div className="canvasContainerContainer">
                                  <div className="canvasContainer" ref={editorContainerRef}>
                                    <KonvaEditor
                                      floors_count={project?.floors_count}
                                      floorsNames={project?.floors_names}
                                      sourceTitle={project?.water_heating_system ?? "T0"}
                                      width={konvaWidth}
                                      refEditor={editorRef}
                                      pePcLayout={project?.pepc_layout}
                                      loadFromSavedObject={loadFromSavedObject}
                                      pePcConnections={pePcConnections}
                                      canEdit={true}
                                      defaults={defaults}
                                      pePcConnectionsAdjustedDiameter={pePcConnectionsAdjustedDiameter}
                                      pePcConnectionsRe={pePcConnectionsRe}
                                      project={project}
                                      setProject={(newProject) => {
                                        setProject(newProject);
                                      }}
                                      setPePcConnectionsRe={(pepcConnectionsRe) => {
                                        setPePcConnectionsRe(pepcConnectionsRe);
                                      }}
                                      t={t}
                                      setPePcConnectionsAdjustedDiameter={(pepcConnectionsAdjustedDiameter) => {
                                        setPePcConnectionsAdjustedDiameter(pepcConnectionsAdjustedDiameter);
                                      }}
                                      setPePcConnections={(pepcConnections) => {
                                        const newPePcConnections = JSON.parse(JSON.stringify(pepcConnections));
                                        // delete entries that are not in the pepcConnections
                                        // add entries that are not in the scenarios.pepc_connections
                                        if (Object.keys(pepcConnections).length === 0) return;
                                        Object.keys(newPePcConnections).forEach((connectionStart) => {
                                          Object.keys(newPePcConnections[connectionStart]).forEach((connectionEnd) => {
                                            if (!pepcConnections[connectionStart]) {
                                              delete newPePcConnections[connectionStart];
                                            } else if (!pepcConnections[connectionStart][connectionEnd]) {
                                              delete newPePcConnections[connectionStart][connectionEnd];
                                            }
                                          });
                                        });
                                        Object.keys(pepcConnections).forEach((connectionStart) => {
                                          if (!newPePcConnections[connectionStart]) {
                                            newPePcConnections[connectionStart] = {};
                                          }
                                          Object.keys(pepcConnections[connectionStart]).forEach((connectionEnd) => {
                                            if (!newPePcConnections[connectionStart][connectionEnd]) {
                                              newPePcConnections[connectionStart][connectionEnd] = {
                                                width: 5,
                                                adjustedDiameter: undefined,
                                                recirculacao: 0,
                                              };
                                            }
                                          });
                                        });
                                        setPePcConnections(newPePcConnections);
                                      }}
                                      currentArrows={currentArrows}
                                      setCurrentArrows={setCurrentArrows}
                                    />
                                  </div>
                                </div>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Alert severity="warning" variant="outlined" sx={{ fontSize: "16px" }}>
                                <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.form.guide.title")}</AlertTitle>
                                {t("create_scenario.form.guide.description")}
                              </Alert>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Alert severity="info" variant="outlined" sx={{ fontSize: "16px" }}>
                                <AlertTitle sx={{ fontSize: "16px" }}>NOTA!</AlertTitle>É realizada uma gravação automática do esquema de 10
                                em 10 segundos.
                              </Alert>
                            </Grid>
                            {/* <>
                              <div
                                className="projectSectionContainer"
                                style={{
                                  flexDirection: "row",
                                  width: "90%",
                                  flexWrap: "wrap",
                                }}
                              >
                                {Array.from(Array(project.house_parts_count).keys()).map((i) => {
                                  const setPe_PCValues = (items_in: any[]) => {
                                    let res: { [key: string]: any } = {};
                                    items_in.forEach((item: any, index) => {
                                      if (index === 0) res["name"] = item;
                                      else if (index === 1) res["nickname"] = item;
                                      else if (index === 2) res["caudal_minimo"] = item;
                                      else if (index === 3) res["caudal_total"] = item;
                                      else if (index === 4) res["hoterway"] = item;
                                      // else res["pc_" + (index - 1)] = item;
                                    });

                                    let pePcLayoutCopy = pePcLayout;

                                    pePcLayoutCopy["div_" + i] = res;
                                    setProject({
                                      ...project,
                                      pepc_layout2: { ...pePcLayoutCopy },
                                    });
                                    setPePcLayout(pePcLayoutCopy);
                                  };
                                  return (
                                    <div key={"" + i} className="projectPePcContainer">
                                      <p className="projectSectionTitle">{`${t("create_scenario.form.compartment")} ` + (i + 1)}</p>
                                      <div className="coolTablesContainer">
                                        <CoolTableHoterway
                                          t={t}
                                          editMode={Object.keys(pePcConnections).length === 0 ? 0 : 1}
                                          index={i}
                                          pepcLayout={pePcLayout}
                                          setPe_PCValues={setPe_PCValues}
                                          className="coolTable"
                                          pcCount={project.pc_count}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </> */}

                            <Grid container justifyContent="flex-end" spacing={4}>
                              <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                                <Box
                                  p="5px 7px 5px 5px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  borderRadius="4px"
                                  backgroundColor={colors.blueAccent[600]}
                                  sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  onClick={async () => {
                                    const complete_project = { ...project };
                                    const connections_res = {};
                                    const connections_res_retorno = {};

                                    for (let connectionStart of Object.keys(pePcConnections)) {
                                      connections_res[connectionStart] = {};
                                      for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
                                        connections_res[connectionStart][connectionEnd] = {
                                          width:
                                            pePcConnections[connectionStart][connectionEnd].width !== undefined
                                              ? pePcConnections[connectionStart][connectionEnd].width
                                              : 5,
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                        };
                                      }
                                    }

                                    for (let connectionStart of Object.keys(pePcConnections)) {
                                      connections_res_retorno[connectionStart] = {};
                                      for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
                                        connections_res_retorno[connectionStart][connectionEnd] = {
                                          width:
                                            pePcConnections[connectionStart][connectionEnd].width !== undefined
                                              ? pePcConnections[connectionStart][connectionEnd].width
                                              : 5,
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: 1,
                                        };
                                      }
                                    }

                                    // Create a deep copy of pePcLayout
                                    const pePcLayoutWithHoterway = JSON.parse(JSON.stringify(pePcLayout));

                                    // Change hoterway to 1 in the copied object
                                    Object.keys(pePcLayoutWithHoterway).forEach((key) => {
                                      pePcLayoutWithHoterway[key].hoterway = "1";
                                    });

                                    complete_project["editor_image"] = JSON.stringify(
                                      fullDiagramDescription(editorRef.current, currentArrows)
                                    );

                                    localStorage.removeItem("editor_image");
                                    localStorage.removeItem("connections");

                                    const formattedDate = format(Date.now(), "yyyy-MM-dd HH:mm:ss");

                                    // Define your different sets of values for submission
                                    const submitValues = [
                                      {
                                        id_simulation: idSimulation,
                                        scenario_name: "Cenário Base - " + idSimulation,
                                        email: complete_project["email"],
                                        email_comercial: values.email_comercial,
                                        date: formattedDate,
                                        editor_image: complete_project["editor_image"],
                                        konva_image: editorRef?.current?.toDataURL(),
                                        pepc_connections: connections_res,
                                        pepc_layout2: pePcLayout,
                                        notes: values.notes,
                                      },
                                      {
                                        id_simulation: idSimulation,
                                        scenario_name: "Cenário Retorno - " + idSimulation,
                                        email: complete_project["email"],
                                        email_comercial: values.email_comercial,
                                        date: formattedDate,
                                        editor_image: complete_project["editor_image"],
                                        konva_image: editorRef?.current?.toDataURL(),
                                        pepc_connections: connections_res_retorno,
                                        pepc_layout2: pePcLayout,
                                        notes: values.notes,
                                      },
                                      {
                                        id_simulation: idSimulation,
                                        scenario_name: "Cenário Hoterway - " + idSimulation,
                                        email: complete_project["email"],
                                        email_comercial: values.email_comercial,
                                        date: formattedDate,
                                        editor_image: complete_project["editor_image"],
                                        konva_image: editorRef?.current?.toDataURL(),
                                        pepc_connections: connections_res,
                                        pepc_layout2: pePcLayoutWithHoterway,
                                        notes: values.notes,
                                      },
                                    ];

                                    // Submit each set of values
                                    for (const submitData of submitValues) {
                                      await handleFormSubmit(submitData);
                                    }
                                  }}
                                >
                                  <LoupeOutlinedIcon
                                    sx={{
                                      textDecoration: "none",
                                      color: colors.grey[100],
                                      justifyContent: "center",
                                    }}
                                  />
                                  <Typography
                                    variant="h3"
                                    justifyContent="center"
                                    color={colors.grey[100]}
                                    sx={{
                                      ml: "5px",
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                      justifyContent: "center",
                                      marginTop: "1px",
                                    }}
                                  >
                                    Guardar
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  ) : (
                    <LoadingScreen />
                  )}
                </Box>
              </Container>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default CreateReport;
