import { useEffect, useRef, useState, useContext } from "react";
import { defaults_t } from "../../types";
import "./App.css";
import "./AddProject.css";
import "react-datepicker/dist/react-datepicker.css";
import KonvaEditor from "../../components/KonvaEditor.tsx";
import CoolTableHoterway from "../../components/CoolTableHoterway";
import { useParams, useNavigate, Link } from "react-router-dom";
import Konva from "konva";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import LoadingScreen from "../global/LoadingScreen";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Box,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  Container,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as yup from "yup";
import { Formik } from "formik";
import { format } from "date-fns";
import { fullDiagramDescription, useWindowSize } from "../../utils/konvaUtils";

function CreateScenario() {
  const { t } = useTranslation();

  const [currentArrows, setCurrentArrows] = useState<
    {
      start: Konva.Group;
      children: { x: number; y: number }[];
      end: Konva.Group;
    }[]
  >([]);
  const { idSimulation } = useParams();
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const editorRef = useRef<Konva.Stage>();
  const editorContainerRef = useRef<HTMLInputElement>();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [project, setProject] = useState<any>();
  const [isSent, setIsSent] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [scenarios, setScenarios] = useState<any>([]);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [pressDelete, setPressDelete] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [hasHoterway, setHasHoterway] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [canCreateScenario, setCanCreateScenario] = useState(false);
  const [render, setRender] = useState(0);
  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const [idNav, setIdNav] = useState(0);
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.data[0].type === 0 || response.data.data[0].type === 1 || response.data.data[0].type === 2) {
        setCanSubmit(true);
      }

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 4) {
        navigate("/lite/my_simulations");
      }
    }
  }, []);

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const handleClose = () => {
    deleteScenario(idToDelete);
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const handleCloseDialog = () => {
    closeMaybe();
    setOpen(false);
  };

  const pressDeleted = () => {
    setPressDelete(true);
  };

  const closeMaybe = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_CALL + "users", config);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getProject = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + `simulation/${idSimulation}`, config).then();
      setProject({ ...response.data.data, scenario_name: "" });
      setPePcLayout(response.data.data["pepc_layout"]);
      setCanCreateScenario(true);

      // EDITAR CRIAÇÃO CENÁRIO
      // if (
      //   currentUser &&
      //   currentUser.user_type === 2 &&
      //   response.data.data.email === currentUser.email &&
      //   response.data.data.isShared === 1
      // ) {
      //   setCanCreateScenario(true);
      // } else if (currentUser && response.data.data.email_comercial === currentUser.email) {
      //   setCanCreateScenario(true);
      // } else if (currentUser && currentUser.user_type === 0) {
      //   setCanCreateScenario(true);
      // } else {
      //   navigate("/");
      // }

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `scenario/simulation/${idSimulation}`, config);
      setScenarios(response2.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const deleteScenario = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `scenario/${id}`, config);
      getProject();
      setOpen(false);
      setIsEliminated(true);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDefaults();
    getProject();
    // if (localStorage.getItem("connections")) {
    //   setPePcConnections(JSON.parse(localStorage.getItem("connections")));
    // } else {
    //   setPePcConnections(pePcConnections);
    // }
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    if (values.scenario_name === "") {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        const response = await axios.post(process.env.REACT_APP_API_CALL + "scenario", values, config);
        window.scrollTo(0, 0);
        setIsSent(true);
        setIdNav(response.data.data.insertId);
        setIsLoading(false);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const [pePcConnections, setPePcConnections] = useState<{
    [key: string]: { [key: string]: number };
  }>({});
  const [pePcConnectionsRe, setPePcConnectionsRe] = useState<{
    [key: string]: { [key: string]: number };
  }>({});
  const [konvaWidth, setKonvaWidth] = useState(0);
  useWindowSize(editorContainerRef, setKonvaWidth);
  const [pePcConnectionsAdjustedDiameter, setPePcConnectionsAdjustedDiameter] = useState<{ [key: string]: { [key: string]: number } }>({});

  const getDefaults = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getDivTotalCaudal = (children: string) => {
    const layout = project.pepc_layout;
    const div_key = Object.keys(layout).find((key) => layout[key]["nickname"] === children);
    let res = 0;
    if (div_key) {
      // CHANGE JUST IN CASE
      // res += project.pepc_layout[div_key]["caudal_total"];
      res += parseFloat(layout[div_key]["caudal_total"]);
    }
    return res;
  };

  const getChildren = (connectionStart: string) => {
    let res = [];
    if (connectionStart in pePcConnections)
      for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
        const children = getChildren(connectionEnd);
        res = [...res, ...children];
      }
    else return [connectionStart];
    return res;
  };
  const calculateAdjustedDiameter = (caudal, tipo_tubo) => {
    if (tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };

  const getAdjustedDiameter = (connectionStart: string, connectionEnd: string) => {
    if (!pePcConnections) return 16;
    const connectionEndChildren = getChildren(connectionEnd);
    const current_res: {
      ligacao: string;
      comprimento: number;
      coef_simultaniedade: number;
      caudal: number;
      diametro_ajustado: number;
      agua_estagnada: number;
      perda_termica: number;
    } = {
      ligacao: "",
      comprimento: 0,
      coef_simultaniedade: 0,
      caudal: 0,
      diametro_ajustado: 0,
      agua_estagnada: 0,
      perda_termica: 0,
    };
    current_res.ligacao = connectionStart + " -> " + connectionEnd;
    current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
    let caudal_total = 0;
    for (let child of connectionEndChildren) {
      // CHANGE JUST IN CASE
      caudal_total += getDivTotalCaudal(child);
      // caudal_total = getDivTotalCaudal(child);
    }
    if (!caudal_total) caudal_total = 0;
    current_res.caudal = current_res.coef_simultaniedade * caudal_total;
    const tubo_stats = calculateAdjustedDiameter(current_res.caudal, project.tipo_tubo);
    return tubo_stats.diametro;
  };

  useEffect(() => {
    const res = {};

    if (pePcConnections && render < 1) {
      for (let connectionStart of Object.keys(pePcConnections)) {
        res[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart]))
          res[connectionStart][connectionEnd] = getAdjustedDiameter(connectionStart, connectionEnd);
      }
      setPePcConnectionsAdjustedDiameter(res);
    }
  }, [pePcConnections]);

  useEffect(() => {
    const res2 = {};
    if (pePcConnections && render < 1) {
      for (let connectionStart of Object.keys(pePcConnections)) {
        res2[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) res2[connectionStart][connectionEnd] = 0;
      }
      setPePcConnectionsRe(res2);
    }
  }, [pePcConnections]);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };
  // const styleRequired = {
  //   "& label.Mui-focused": {
  //     color: colors.primary[100],
  //     fontSize: "16px",
  //     fontWeight: "bold",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: colors.primary[100],
  //       fontWeight: "bold",
  //     },
  //   },
  //   "& .MuiFormHelperText-root": {
  //     fontSize: "14px",
  //     fontWeight: "bold",
  //   },
  //   backgroundColor: colors.blueAccent[1000],
  //   fontWeight: "bold",
  // };

  const simulationSchema = yup.object().shape({
    email_comercial: yup.string().email(t("create_scenario.schema.email_comercial")),
    scenario_name: yup.string().required(t("create_scenario.schema.scenario_name")),
    email: yup.string().required(t("create_scenario.schema.email.required")).email(t("create_scenario.schema.email.validation")),
    notes: yup.string(),
  });

  const columns = [
    {
      field: "id",
      headerName: t("create_scenario.table_fields.id"),
      minWidth: 80,
      maxWidth: 80,
      flex: 1,
    },
    {
      field: "id_simulation",
      headerName: t("create_scenario.table_fields.id_simulation"),
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "scenario_name",
      headerName: t("create_scenario.table_fields.scenario_name"),
      minWidth: 100,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: t("create_scenario.table_fields.date"),
      minWidth: 125,
      maxWidth: 1125,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 95,
      maxWidth: 95,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        if ((currentUser && currentUser.user_type === 0) || (currentUser && params.row.email_comercial === currentUser.email)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.greenAccent[600]}
              component={Link}
              to={`/scenarios/${params.row.id}`}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
                {t("create_scenario.table_fields.edit.view")}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box
              width="100%"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.greenAccent[800]}
              component={Link}
              to={`/scenarios/${params.row.id}`}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("create_scenario.table_fields.edit.view")}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "report",
      headerName: "",
      minWidth: 120,
      maxWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={colors.blueAccent[600]}
            component={Link}
            to={`/scenario_report/${params.row.id}`}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
              {t("create_scenario.table_fields.report")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 105,
      maxWidth: 105,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        if ((currentUser && currentUser.user_type === 0) || (currentUser && params.row.email_comercial === currentUser.email)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.redAccent[600]}
              onClick={() => {
                handleClickOpen(params.row.id);
              }}
              sx={{ cursor: "pointer" }}
            >
              <DeleteOutlineOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("create_scenario.table_fields.delete")}
              </Typography>

              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  <Typography
                    justifyContent="center"
                    color={colors.primary[100]}
                    sx={{
                      textDecoration: "none",
                      justifyContent: "center",
                      mt: "5px",
                    }}
                  >
                    {t("create_scenario.table_fields.delete_warnings.alert_dialog_title")}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                    >
                      {t("create_scenario.table_fields.delete_warnings.alert_dialog_description")}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid item>
                    <Box
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.redAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={handleCloseDialog}
                    >
                      <CloseOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {t("create_scenario.table_fields.delete_warnings.alert_deny")}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.greenAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={handleClose}
                    >
                      <CheckOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {t("create_scenario.table_fields.delete_warnings.alert_confirm")}
                      </Typography>
                    </Box>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Box>
          );
        } else {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.grey[800]}
              sx={{ cursor: "not-allowed" }}
              onClick={pressDeleted}
            >
              <DoDisturbAltOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("create_scenario.table_fields.delete")}
              </Typography>
            </Box>
          );
        }
      },
    },
  ];

  const loadFromSavedObject = localStorage.getItem("editor_image")
    ? JSON.parse(localStorage.getItem("editor_image"))
    : scenarios?.editor_image; // Use scenarios?.editor_image if localStorage is empty

  //LOCAL STORAGE IMAGE

  useEffect(() => {
    // Function that will run every 10 seconds
    const intervalId = setInterval(() => {
      const complete_project = { ...project };
      const connections_res = {};
      let hasRecVar = false;

      // Build connections_res object
      for (let connectionStart of Object.keys(pePcConnections)) {
        connections_res[connectionStart] = {};
        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
          const recirculacaoValue = pePcConnectionsRe[connectionStart][connectionEnd];
          if (recirculacaoValue === 1 || recirculacaoValue === "1") {
            hasRecVar = true;
          }

          connections_res[connectionStart][connectionEnd] = {
            width: pePcConnections[connectionStart][connectionEnd]?.width || 5,
            adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
            recirculacao: recirculacaoValue,
          };
        }
      }

      complete_project["pepc_connections"] = connections_res;

      complete_project["editor_image"] = JSON.stringify(fullDiagramDescription(editorRef.current, currentArrows));

      const transformedArray = [];

      for (const start in pePcConnections) {
        for (const end in pePcConnections[start]) {
          transformedArray.push({
            start: start,
            children: [],
            end: end,
          });
        }
      }

      // Update localStorage
      localStorage.setItem(
        "editor_image",
        // JSON.stringify({
        //   pePcLayout: pePcLayout,
        //   pePcConnections: complete_project["pepc_connections"],
        // })
        JSON.stringify({
          pePcLayout: fullDiagramDescription(editorRef.current, currentArrows).pePcLayout,
          pePcConnections: transformedArray,
        })
      );

      localStorage.setItem(
        "connections",
        // JSON.stringify({
        //   pePcLayout: pePcLayout,
        //   pePcConnections: complete_project["pepc_connections"],
        // })
        JSON.stringify(connections_res)
      );
    }, 10000); // 10,000 ms = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [project, pePcConnections, pePcConnectionsRe, pePcConnectionsAdjustedDiameter, currentArrows, editorRef]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("create_scenario.title")} subtitle={t("create_scenario.subtitle")} />
          </Box>

          <Grid container xs={2} sm={2} md={2} lg={2} xl={2}>
            <Box
              ml="20px"
              onClick={() => navigate("/simulations/" + idSimulation)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                Voltar à simulação
              </Typography>
            </Box>
          </Grid>

          {project && project !== undefined && isLoading === false && canCreateScenario === true ? (
            <>
              <Container component="main" maxWidth="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  //AQUI
                  // alignItems="center"
                  mt="30px"
                >
                  <Fade
                    in={generalError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setGeneralError(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.general_error.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.general_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isResidential}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsResidential(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_residential.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_residential.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={sessionError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setSessionError(false);
                        navigate("/login");
                        logout();
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.session_error.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.session_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEmpty}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsEmpty(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_empty.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_empty.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isSent}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsSent(false);
                        if (idNav !== 0) {
                          // navigate("/scenario_report/" + idNav);
                          window.location.reload();
                        }
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_sent.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_sent.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEliminated}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsEliminated(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_eliminated.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.is_eliminated.description")}
                    </Alert>
                  </Fade>

                  {hide === false ? (
                    <Formik
                      // enableReinitialize={true}
                      onSubmit={handleFormSubmit}
                      initialValues={project}
                      validationSchema={simulationSchema}
                      validateOnChange={false}
                      validateOnBlur={true}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={4} mt="10px" justifyContent="left">
                            <Grid item xs={12}>
                              <Typography component="h1" variant="h3">
                                {t("create_scenario.form.title1")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3.5}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                label={t("create_scenario.form.scenario_name")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.scenario_name}
                                name="scenario_name"
                                error={!!touched.scenario_name && !!errors.scenario_name}
                                helperText={touched.scenario_name && errors.scenario_name}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2.5}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                label={t("create_scenario.form.email_comercial")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email_comercial}
                                name="email_comercial"
                                error={!!touched.email_comercial && !!errors.email_comercial}
                                helperText={touched.email_comercial && errors.email_comercial}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                                disabled={true}
                              />
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={2.5}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                label={t("manage_project.client.email")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={styleRequired}
                                disabled={true}
                              />
                            </Grid> */}

                            <Grid item xs={12}>
                              <Typography component="h1" variant="h6" mt="-20px">
                                {t("create_scenario.form.required")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography component="h1" variant="h3">
                                {t("create_scenario.form.title2")}
                              </Typography>
                            </Grid>


                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Alert severity="info" variant="outlined" sx={{ fontSize: "16px" }}>
                                <AlertTitle sx={{ fontSize: "16px" }}>NOTA!</AlertTitle>
                                É realizada uma gravação automática do esquema de 10 em 10 segundos.
                              </Alert>
                              </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="canvasContainerContainer">
                                <div className="canvasContainer" ref={editorContainerRef}>
                                  <KonvaEditor
                                    floors_count={project?.floors_count}
                                    floorsNames={project?.floors_names}
                                    sourceTitle={project?.water_heating_system ?? "T0"}
                                    width={konvaWidth}
                                    refEditor={editorRef}
                                    pePcLayout={project?.pepc_layout}
                                    loadFromSavedObject={loadFromSavedObject}
                                    pePcConnections={pePcConnections}
                                    canEdit={true}
                                    defaults={defaults}
                                    pePcConnectionsAdjustedDiameter={pePcConnectionsAdjustedDiameter}
                                    pePcConnectionsRe={pePcConnectionsRe}
                                    project={project}
                                    setProject={(newProject) => {
                                      setProject(newProject);
                                    }}
                                    setPePcConnectionsRe={(pepcConnectionsRe) => {
                                      setPePcConnectionsRe(pepcConnectionsRe);
                                    }}
                                    t={t}
                                    setPePcConnectionsAdjustedDiameter={(pepcConnectionsAdjustedDiameter) => {
                                      setPePcConnectionsAdjustedDiameter(pepcConnectionsAdjustedDiameter);
                                    }}
                                    setPePcConnections={(pepcConnections) => {
                                      const newPePcConnections = JSON.parse(JSON.stringify(pepcConnections));
                                      // delete entries that are not in the pepcConnections
                                      // add entries that are not in the scenarios.pepc_connections
                                      if (Object.keys(pepcConnections).length === 0) return;
                                      Object.keys(newPePcConnections).forEach((connectionStart) => {
                                        Object.keys(newPePcConnections[connectionStart]).forEach((connectionEnd) => {
                                          if (!pepcConnections[connectionStart]) {
                                            delete newPePcConnections[connectionStart];
                                          } else if (!pepcConnections[connectionStart][connectionEnd]) {
                                            delete newPePcConnections[connectionStart][connectionEnd];
                                          }
                                        });
                                      });
                                      Object.keys(pepcConnections).forEach((connectionStart) => {
                                        if (!newPePcConnections[connectionStart]) {
                                          newPePcConnections[connectionStart] = {};
                                        }
                                        Object.keys(pepcConnections[connectionStart]).forEach((connectionEnd) => {
                                          if (!newPePcConnections[connectionStart][connectionEnd]) {
                                            newPePcConnections[connectionStart][connectionEnd] = {
                                              width: 5,
                                              adjustedDiameter: undefined,
                                              recirculacao: 0,
                                            };
                                          }
                                        });
                                      });
                                      setPePcConnections(newPePcConnections);
                                    }}
                                    currentArrows={currentArrows}
                                    setCurrentArrows={setCurrentArrows}
                                  />
                                </div>
                              </div>

                              <Alert severity="warning" variant="outlined" sx={{ fontSize: "16px" }}>
                                <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.form.guide.title")}</AlertTitle>
                                {t("create_scenario.form.guide.description")}
                              </Alert>
                            </Grid>
                            <>
                              <div
                                className="projectSectionContainer"
                                style={{
                                  flexDirection: "row",
                                  width: "90%",
                                  flexWrap: "wrap",
                                }}
                              >
                                {Array.from(Array(project.house_parts_count).keys()).map((i) => {
                                  const setPe_PCValues = (items_in: any[]) => {
                                    let res: { [key: string]: any } = {};
                                    items_in.forEach((item: any, index) => {
                                      if (index === 0) res["name"] = item;
                                      else if (index === 1) res["nickname"] = item;
                                      else if (index === 2) res["caudal_minimo"] = item;
                                      else if (index === 3) res["caudal_total"] = item;
                                      else if (index === 4) res["hoterway"] = item;
                                      // else res["pc_" + (index - 1)] = item;
                                    });

                                    let pePcLayoutCopy = pePcLayout;

                                    pePcLayoutCopy["div_" + i] = res;
                                    setProject({
                                      ...project,
                                      pepc_layout2: { ...pePcLayoutCopy },
                                    });
                                    setPePcLayout(pePcLayoutCopy);
                                  };
                                  return (
                                    <div key={"" + i} className="projectPePcContainer">
                                      <p className="projectSectionTitle">{`${t("create_scenario.form.compartment")} ` + (i + 1)}</p>
                                      <div className="coolTablesContainer">
                                        <CoolTableHoterway
                                          t={t}
                                          editMode={Object.keys(pePcConnections).length === 0 ? 0 : 1}
                                          index={i}
                                          pepcLayout={pePcLayout}
                                          setPe_PCValues={setPe_PCValues}
                                          className="coolTable"
                                          pcCount={project.pc_count}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="filled"
                                  type="text"
                                  label={t("create_scenario.form.notes")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.notes}
                                  name="notes"
                                  error={!!touched.notes && !!errors.notes}
                                  helperText={touched.notes && errors.notes}
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                />
                              </Grid>
                            </>

                            <Grid container justifyContent="flex-end" spacing={4}>
                              <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                                <Box
                                  p="5px 7px 5px 5px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  borderRadius="4px"
                                  backgroundColor={colors.blueAccent[600]}
                                  sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  onClick={async () => {
                                    const complete_project = { ...project };
                                    const connections_res = {};

                                    if (canSubmit === false) {
                                      var hasHoterwayVar;
                                      var hasRecVar;

                                      for (const divKey in pePcLayout) {
                                        if (Object.hasOwnProperty.call(pePcLayout, divKey)) {
                                          const div = pePcLayout[divKey];

                                          // Check if the hoterway value is "1"
                                          if (div.hoterway === "1" || div.hoterway === 1) {
                                            setHasHoterway(true);
                                            hasHoterwayVar = true;
                                            break; // No need to continue checking if we already found "1"
                                          }
                                        }
                                      }

                                      for (let connectionStart of Object.keys(pePcConnections)) {
                                        connections_res[connectionStart] = {};
                                        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
                                          const recirculacaoValue = pePcConnectionsRe[connectionStart][connectionEnd];
                                          if (recirculacaoValue === 1 || recirculacaoValue === "1") {
                                            hasRecVar = true;
                                          }
                                          connections_res[connectionStart][connectionEnd] = {
                                            width:
                                              pePcConnections[connectionStart][connectionEnd].width !== undefined
                                                ? pePcConnections[connectionStart][connectionEnd].width
                                                : 5,
                                            adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                            recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                          };

                                          // if ((recirculacaoValue === 1 || recirculacaoValue === "1") && hasHoterway === true) {
                                          //   setIsResidential(true);
                                          // } else {
                                          //   setIsResidential(false);
                                          // }
                                        }
                                      }

                                      complete_project["pepc_connections"] = connections_res;

                                      complete_project["editor_image"] = JSON.stringify(
                                        fullDiagramDescription(editorRef.current, currentArrows)
                                      );

                                      if (hasHoterwayVar === true && hasRecVar === true) {
                                        setIsResidential(true);
                                      } else {
                                        setIsResidential(false);
                                        localStorage.removeItem("editor_image");
                                        localStorage.removeItem("connections");

                                        await handleFormSubmit({
                                          id_simulation: idSimulation,
                                          scenario_name: values.scenario_name,
                                          email: complete_project["email"],
                                          email_comercial: values.email_comercial,
                                          date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
                                          editor_image: complete_project["editor_image"],
                                          pepc_connections: complete_project["pepc_connections"],
                                          pepc_layout2: pePcLayout,
                                          notes: values.notes,
                                        });
                                      }
                                    } else {
                                      for (let connectionStart of Object.keys(pePcConnections)) {
                                        connections_res[connectionStart] = {};
                                        for (let connectionEnd of Object.keys(pePcConnections[connectionStart])) {
                                          connections_res[connectionStart][connectionEnd] = {
                                            width:
                                              pePcConnections[connectionStart][connectionEnd].width !== undefined
                                                ? pePcConnections[connectionStart][connectionEnd].width
                                                : 5,
                                            adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                            recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                          };
                                        }
                                      }

                                      complete_project["pepc_connections"] = connections_res;

                                      complete_project["editor_image"] = JSON.stringify(
                                        fullDiagramDescription(editorRef.current, currentArrows)
                                      );

                                      localStorage.removeItem("editor_image");
                                      localStorage.removeItem("connections");

                                      await handleFormSubmit({
                                        id_simulation: idSimulation,
                                        scenario_name: values.scenario_name,
                                        email: complete_project["email"],
                                        email_comercial: values.email_comercial,
                                        date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
                                        editor_image: complete_project["editor_image"],
                                        pepc_connections: complete_project["pepc_connections"],
                                        pepc_layout2: pePcLayout,
                                        notes: values.notes,
                                      });
                                    }
                                  }}
                                >
                                  <LoupeOutlinedIcon
                                    sx={{
                                      textDecoration: "none",
                                      color: colors.grey[100],
                                      justifyContent: "center",
                                    }}
                                  />
                                  <Typography
                                    variant="h3"
                                    justifyContent="center"
                                    color={colors.grey[100]}
                                    sx={{
                                      ml: "5px",
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                      justifyContent: "center",
                                      marginTop: "1px",
                                    }}
                                  >
                                    {t("create_scenario.form.buttons.create_scenario")}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  ) : (
                    <LoadingScreen />
                  )}
                </Box>
              </Container>

              {hide === false ? (
                <Box ml="20px" mb="20px" mr="20px" mt="40px">
                  <Header
                    title={`${t("create_scenario.table_title")} ` + idSimulation}
                    subtitle={`${t("create_scenario.table_subtitle")} ` + idSimulation + "!"}
                  />

                  <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                    <Fade
                      in={pressDelete}
                      timeout={{ enter: 1000, exit: 1000 }}
                      unmountOnExit={true}
                      addEndListener={() => {
                        setTimeout(() => {
                          setPressDelete(false);
                        }, 3000);
                      }}
                    >
                      <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                        <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.press_delete.title")}</AlertTitle>
                        {t("create_scenario.alert_fields.press_delete.description")}
                      </Alert>
                    </Fade>
                  </Box>

                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[100]} !important`,
                      },
                      "& .MuiButtonBase-root .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .css-13qp4b7-MuiButtonBase-root-MuiButton-root":
                        {
                          color: `${colors.blueAccent[700]} !important`,
                        },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                    }}
                  >
                    <DataGrid
                      rowsPerPageOptions={[50, 100, 250, 500, 1000]}
                      hideFooterSelectedRowCount
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: t("table_custom_translation.label_rows_per_page"),
                        },
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) => `${count} ` + t("table_custom_translation.label_displayed_rows"),
                        },
                        // Root
                        noRowsLabel: t("table_custom_translation.no_rows_label"),
                        noResultsOverlayLabel: t("table_custom_translation.no_results_overlay_label"),
                        errorOverlayDefaultLabel: t("table_custom_translation.error_overlay_default_label"),
                        // Density selector toolbar button text
                        toolbarDensity: t("table_custom_translation.toolbar_density"),
                        toolbarDensityLabel: t("table_custom_translation.toolbar_density_label"),
                        toolbarDensityCompact: t("table_custom_translation.toolbar_density_compact"),
                        toolbarDensityStandard: t("table_custom_translation.toolbar_density_standard"),
                        toolbarDensityComfortable: t("table_custom_translation.toolbar_density_comfortable"),
                        // Columns selector toolbar button text
                        toolbarColumns: t("table_custom_translation.toolbar_columns"),
                        toolbarColumnsLabel: t("table_custom_translation.toolbar_columns_label"),
                        // Filters toolbar button text
                        toolbarFilters: t("table_custom_translation.toolbar_filters"),
                        toolbarFiltersLabel: t("table_custom_translation.toolbar_filters_label"),
                        toolbarFiltersTooltipHide: t("table_custom_translation.toolbar_filters_tooltip_hide"),
                        toolbarFiltersTooltipShow: t("table_custom_translation.toolbar_filters_tooltip_show"),
                        toolbarFiltersTooltipActive: (count) =>
                          `${count} ${
                            count !== 1
                              ? t("table_custom_translation.toolbar_filters_tooltip_active.filter_plural")
                              : t("table_custom_translation.toolbar_filters_tooltip_active.filter_singular")
                          } ${
                            count !== 1
                              ? t("table_custom_translation.toolbar_filters_tooltip_active.active_plural")
                              : t("table_custom_translation.toolbar_filters_tooltip_active.active_singular")
                          }`,
                        // Quick filter toolbar field
                        toolbarQuickFilterPlaceholder: t("table_custom_translation.toolbar_quick_filter_placeholder"),
                        toolbarQuickFilterLabel: t("table_custom_translation.toolbar_quick_filter_label"),
                        toolbarQuickFilterDeleteIconLabel: t("table_custom_translation.toolbar_quick_filter_delete_icon_label"),
                        // Export selector toolbar button text
                        toolbarExport: t("table_custom_translation.toolbar_export"),
                        toolbarExportLabel: t("table_custom_translation.toolbar_export_label"),
                        toolbarExportCSV: t("table_custom_translation.toolbar_export_CSV"),
                        toolbarExportPrint: t("table_custom_translation.toolbar_export_print"),
                        toolbarExportExcel: t("table_custom_translation.toolbar_export_excel"),
                        // Columns panel text
                        columnsPanelTextFieldLabel: t("table_custom_translation.columns_panel_text_field_label"),
                        columnsPanelTextFieldPlaceholder: t("table_custom_translation.columns_panel_text_field_placeholder"),
                        columnsPanelDragIconLabel: t("table_custom_translation.columns_panel_drag_icon_label"),
                        columnsPanelShowAllButton: t("table_custom_translation.columns_panel_showAll_button"),
                        columnsPanelHideAllButton: t("table_custom_translation.columns_panel_hideAll_button"),
                        // Filter panel text
                        filterPanelAddFilter: t("table_custom_translation.filter_panel_add_filter"),
                        filterPanelDeleteIconLabel: t("table_custom_translation.filter_panel_delete_icon_label"),
                        filterPanelLinkOperator: t("table_custom_translation.filter_panel_link_operator"),
                        filterPanelOperators: t("table_custom_translation.filter_panel_operators"),
                        // TODO v6: rename to filterPanelOperator
                        filterPanelOperatorAnd: t("table_custom_translation.filter_panel_operator_and"),
                        filterPanelOperatorOr: t("table_custom_translation.filter_panel_operator_or"),
                        filterPanelColumns: t("table_custom_translation.filter_panel_columns"),
                        filterPanelInputLabel: t("table_custom_translation.filter_panel_input_label"),
                        filterPanelInputPlaceholder: t("table_custom_translation.filter_panel_input_placeholder"),
                        // Filter operators text
                        filterOperatorContains: t("table_custom_translation.filter_operator_contains"),
                        filterOperatorEquals: t("table_custom_translation.filter_operator_equals"),
                        filterOperatorStartsWith: t("table_custom_translation.filter_operator_starts_with"),
                        filterOperatorEndsWith: t("table_custom_translation.filter_operator_ends_with"),
                        filterOperatorIs: t("table_custom_translation.filter_operator_is"),
                        filterOperatorNot: t("table_custom_translation.filter_operator_not"),
                        filterOperatorAfter: t("table_custom_translation.filter_operator_after"),
                        filterOperatorOnOrAfter: t("table_custom_translation.filter_operator_on_or_after"),
                        filterOperatorBefore: t("table_custom_translation.filter_operator_before"),
                        filterOperatorOnOrBefore: t("table_custom_translation.filter_operator_on_or_before"),
                        filterOperatorIsEmpty: t("table_custom_translation.filter_operator_is_empty"),
                        filterOperatorIsNotEmpty: t("table_custom_translation.filter_operator_is_not_empty"),
                        filterOperatorIsAnyOf: t("table_custom_translation.filter_operator_is_any_of"),
                        // Filter values text
                        filterValueAny: t("table_custom_translation.filter_value_any"),
                        filterValueTrue: t("table_custom_translation.filter_value_true"),
                        filterValueFalse: t("table_custom_translation.filter_value_false"),
                        // Column menu text
                        columnMenuLabel: t("table_custom_translation.column_menu_label"),
                        columnMenuShowColumns: t("table_custom_translation.column_menu_show_columns"),
                        columnMenuFilter: t("table_custom_translation.column_menu_filter"),
                        columnMenuHideColumn: t("table_custom_translation.column_menu_hide_column"),
                        columnMenuUnsort: t("table_custom_translation.column_menu_unsort"),
                        columnMenuSortAsc: t("table_custom_translation.column_menu_sort_asc"),
                        columnMenuSortDesc: t("table_custom_translation.column_menu_sort_desc"),
                        // Column header text
                        columnHeaderFiltersTooltipActive: (count) =>
                          `${count} ${
                            count !== 1
                              ? t("table_custom_translation.column_header_filters_tooltip_active.filter_plural")
                              : t("table_custom_translation.column_header_filters_tooltip_active.filter_singular")
                          } ${
                            count !== 1
                              ? t("table_custom_translation.column_header_filters_tooltip_active.active_plural")
                              : t("table_custom_translation.column_header_filters_tooltip_active.active_singular")
                          }`,
                        columnHeaderFiltersLabel: t("table_custom_translation.column_header_filters_label"),
                        columnHeaderSortIconLabel: t("table_custom_translation.column_header_sort_icon_label"),
                        // Rows selected footer text
                        footerRowSelected: (count) =>
                          count !== 1
                            ? `${count.toLocaleString()} +` + t("table_custom_translation.footer_row_selected.plural")
                            : `${count.toLocaleString()} ` + t("table_custom_translation.footer_row_selected.singular"),
                        // Total row amount footer text
                        footerTotalRows: t("table_custom_translation.footer_total_rows"),
                        // Total visible row amount footer text
                        footerTotalVisibleRows: (visibleCount, totalCount) =>
                          `${visibleCount.toLocaleString()} ` +
                          t("table_custom_translation.footer_total_visible_rows") +
                          `${totalCount.toLocaleString()}`,
                        // Checkbox selection text
                        checkboxSelectionHeaderName: t("table_custom_translation.checkbox_selection_header_name"),
                        checkboxSelectionSelectAllRows: t("table_custom_translation.checkbox_selection_select_all_rows"),
                        checkboxSelectionUnselectAllRows: t("table_custom_translation.checkbox_selection_unselect_all_rows"),
                        checkboxSelectionSelectRow: t("table_custom_translation.checkbox_selection_select_row"),
                        checkboxSelectionUnselectRow: t("table_custom_translation.checkbox_selection_unselect_row"),
                        // Boolean cell text
                        booleanCellTrueLabel: t("table_custom_translation.boolean_cell_true_label"),
                        booleanCellFalseLabel: t("table_custom_translation.boolean_cell_false_label"),
                        // Actions cell more text
                        actionsCellMore: t("table_custom_translation.actions_cell_more"),
                        // Column pinning text
                        pinToLeft: t("table_custom_translation.pin_to_left"),
                        pinToRight: t("table_custom_translation.pin_to_right"),
                        unpin: t("table_custom_translation.unpin"),
                        // Tree Data
                        treeDataGroupingHeaderName: t("table_custom_translation.tree_data_grouping_header_name"),
                        treeDataExpand: t("table_custom_translation.tree_data_expand"),
                        treeDataCollapse: t("table_custom_translation.tree_data_collapse"),
                        // Grouping columns
                        groupingColumnHeaderName: t("table_custom_translation.grouping_column_header_name"),
                        groupColumn: (name) => t("table_custom_translation.group_column") + ` ${name}`,
                        unGroupColumn: (name) => t("table_custom_translation.un_group_column") + ` ${name}`,
                        // Master/detail
                        detailPanelToggle: t("table_custom_translation.detail_panel_toggle"),
                        expandDetailPanel: t("table_custom_translation.expand_detail_panel"),
                        collapseDetailPanel: t("table_custom_translation.collapse_detail_panel"),
                        // Row reordering text
                        rowReorderingHeaderName: t("table_custom_translation.row_reordering_header_name"),
                        // Aggregation
                        // aggregationMenuItemHeader: 'Aggregation',
                        // aggregationFunctionLabelSum: 'sum',
                        // aggregationFunctionLabelAvg: 'avg',
                        // aggregationFunctionLabelMin: 'min',
                        // aggregationFunctionLabelMax: 'max',
                        // aggregationFunctionLabelSize: 'size',
                      }}
                      rows={scenarios}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                    />
                  </Box>
                </Box>
              ) : (
                <LoadingScreen />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default CreateScenario;
