import React, { useEffect, useMemo, useState, useContext } from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, Font, PDFViewer } from "@react-pdf/renderer";
import "./App.css";
import "./Report.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { defaults_t } from "../../../types";
import { useTranslation } from "react-i18next";
import MatrixTable from "../../../components/MatrixTable";
import axios from "axios";
import { tokens } from "../../../theme";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Header from "../../../components/Header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  Container,
  Box,
  Typography,
  useTheme,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
} from "@mui/material";
import { AuthContext } from "../../../context/authContext";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import LoadingScreen from "../../global/LoadingScreen";
import MontserratRegular from "../../../fonts/Montserrat-Regular.ttf";
import MontserratBold from "../../../fonts/Montserrat-Bold.ttf";
import MontserratItalic from "../../../fonts/Montserrat-Italic.ttf";
import HoterwayLogo from "../../../images/hoterway1_logo.png";
import CertificateIcon from "../../../images/certificate.png";
import ElectricityIcon from "../../../images/electricity.png";
import WaterIcon from "../../../images/water.png";

function MyReport() {
  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOpenDelete, setSnackbarOpenDelete] = useState(false);
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const [cenarioBase, setCenarioBase] = useState<any>(null);
  const [cenarioRetorno, setCenarioRetorno] = useState<any>(null);
  const [cenarioHoterway, setCenarioHoterway] = useState<any>(null);
  const [connectionsData, setConnectionsData] = useState<any>(null);
  const [divsData, setDivsData] = useState<any>(null);
  const [base, setBase] = useState<any>(null);
  const [retorno, setRetorno] = useState<any>(null);
  const [hoterway, setHoterway] = useState<any>(null);
  const [waitingTime, setWaitingTime] = useState<any>(null);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [hoterwayNumber, setHoterwayNumber] = useState(0);
  const [numberHoterway, setNumberHoterway] = useState(null);
  const [hoterwayDivs, setHoterwayDivs] = useState("");
  const [numberRecirculation, setNumberRecirculation] = useState(null);
  const [percentage, setPercentage] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { idSimulation } = useParams();
  const params = idSimulation;

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/dashboard");
      }
    }
  }, []);

  const getDefaults = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
      setPercentage(10);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getScenarios = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `scenario/simulation/${params}`, config).then();
      if (response.data.data[0].email_comercial !== currentUser.email) {
        setIsLoading(true);
        navigate("/lite/simulations");
      }
      setCenarioBase(response.data.data[0]);
      setCenarioRetorno(response.data.data[1]);
      setCenarioHoterway(response.data.data[2]);
      setPercentage(20);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getDefaults();
    getScenarios();
    setIsLoading(false);
  }, []);

  if (cenarioBase && cenarioRetorno && cenarioHoterway) {
    if (
      !Object.keys(cenarioBase?.pepc_layout).every((elem) =>
        Object.keys(cenarioBase?.pepc_layout[elem]).some((other_elen) => other_elen.startsWith("notRecommended"))
      )
    ) {
      let pepc_layout_copy = { ...cenarioBase?.pepc_layout };
      Object.keys(pepc_layout_copy).forEach((layout_key) => {
        if (!("notRecommended" in Object.keys(pepc_layout_copy[layout_key]))) pepc_layout_copy[layout_key]["notRecommended"] = false;
      });
      setCenarioBase({ ...cenarioBase, pepc_layout: pepc_layout_copy });
    }

    if (
      !Object.keys(cenarioRetorno?.pepc_layout).every((elem) =>
        Object.keys(cenarioRetorno?.pepc_layout[elem]).some((other_elen) => other_elen.startsWith("notRecommended"))
      )
    ) {
      let pepc_layout_copy = { ...cenarioRetorno?.pepc_layout };
      Object.keys(pepc_layout_copy).forEach((layout_key) => {
        if (!("notRecommended" in Object.keys(pepc_layout_copy[layout_key]))) pepc_layout_copy[layout_key]["notRecommended"] = false;
      });
      setCenarioRetorno({ ...cenarioRetorno, pepc_layout: pepc_layout_copy });
    }

    if (
      !Object.keys(cenarioHoterway?.pepc_layout).every((elem) =>
        Object.keys(cenarioHoterway?.pepc_layout[elem]).some((other_elen) => other_elen.startsWith("notRecommended"))
      )
    ) {
      let pepc_layout_copy = { ...cenarioHoterway?.pepc_layout };
      Object.keys(pepc_layout_copy).forEach((layout_key) => {
        if (!("notRecommended" in Object.keys(pepc_layout_copy[layout_key]))) pepc_layout_copy[layout_key]["notRecommended"] = false;
      });
      setCenarioHoterway({ ...cenarioHoterway, pepc_layout: pepc_layout_copy });
    }
  }

  const getTubeStatsByDiamenter = (diametro: number, tipo_tubo) => {
    if (tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.diametro === diametro) return stats;
      }
    }
    return null;
  };

  const getAdjustedDiameter = (caudal, tipo_tubo) => {
    if (defaults && tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };

  function calculatePerdaTermica(insolation_width, tubo_stats, cct, ccppt, tubo_width, running_time) {
    switch (insolation_width) {
      case 0:
        return (tubo_stats.perda_termica_1 * cct * ccppt * tubo_width * running_time) / 24;
      case 1:
        return (tubo_stats.perda_termica_2 * cct * ccppt * tubo_width * running_time) / 24;
      case 2:
        return (tubo_stats.perda_termica_3 * cct * ccppt * tubo_width * running_time) / 24;
      default:
        return parseFloat((tubo_stats.perda_termica * tubo_width).toFixed(2));
    }
  }

  function getCertificateText(certificate_class) {
    switch (certificate_class) {
      case 0:
        return "A+";
      case 1:
        return "A";
      case 2:
        return "B";
      case 3:
        return "B-";
      case 4:
        return "C";
      case 5:
        return "D";
      case 6:
        return "E";
      case 7:
        return "F";
      default:
        return "Erro";
    }
  }

  useEffect(() => {
    if (cenarioBase && cenarioBase !== undefined && cenarioBase !== null) {
      let number_hoterway = 0;
      let number_recirculation = 0;
      let wait_times = 0;
      let expectedEnergy;
      let consumo_bomba;
      let consumo_bomba_money;
      let formattedConsumoBombaMoney;
      let perda_termica_parcial;
      let perda_termica_money;
      let formattedPerdaTermicaMoney;
      let water_cost;
      let water_waste = 0;
      let energy_waste;
      let money_energy;
      let energy_percentage;
      let formattedMoneyEnergy;
      let hoterway_number;
      let division;
      let waiting_time;
      let waiting_time_seconds;

      const getDivMinCaudal = (children: string) => {
        const div_key = Object.keys(cenarioBase?.pepc_layout).find(
          (key) =>
            (cenarioBase?.pepc_layout[key]["nickname"]
              ? cenarioBase?.pepc_layout[key]["nickname"]
              : cenarioBase?.pepc_layout[key]["name"]) === children
        );
        let res = 100000;
        if (div_key) {
          res = cenarioBase?.pepc_layout[div_key]["caudal_minimo"];
        }
        return res;
      };

      const getDivTotalCaudal = (children: string) => {
        const div_key = Object.keys(cenarioBase?.pepc_layout).find(
          (key) =>
            (cenarioBase?.pepc_layout[key]["nickname"]
              ? cenarioBase?.pepc_layout[key]["nickname"]
              : cenarioBase?.pepc_layout[key]["name"]) === children
        );
        let res = 0;
        if (div_key) {
          if (isNaN(parseFloat(cenarioBase?.pepc_layout[div_key]["caudal_total"])))
            console.log("error:" + cenarioBase?.pepc_layout[div_key]["caudal_total"]);
          else res += parseFloat(cenarioBase?.pepc_layout[div_key]["caudal_total"]);
        }

        return res;
      };

      const getChildren = (connectionStart: string) => {
        let res = [];
        if (connectionStart in cenarioBase?.pepc_connections)
          for (let connectionEnd of Object.keys(cenarioBase?.pepc_connections[connectionStart])) {
            const children = getChildren(connectionEnd);
            res = [...res, ...children];
          }
        else return [connectionStart];
        return res;
      };

      const calculateConnectionsAttributes = () => {
        if (!cenarioBase || Object.keys(cenarioBase?.pepc_connections).length === 0) return [];
        const res = [];

        let agua_soma = 0;

        for (let connectionStart of Object.keys(cenarioBase?.pepc_connections)) {
          for (let connectionEnd of Object.keys(cenarioBase?.pepc_connections[connectionStart])) {
            const connectionEndChildren = getChildren(connectionEnd);
            const current_res: {
              ligacao: string;
              comprimento: number;
              coef_simultaniedade: number;
              caudal: number;
              diametro_ajustado: number;
              diametro_interior: number;
              agua_estagnada: number;
              perda_termica: number;
              recirculacao: string;
            } = {
              ligacao: "",
              comprimento: 0,
              coef_simultaniedade: 0,
              caudal: 0,
              diametro_ajustado: 0,
              diametro_interior: 0,
              agua_estagnada: 0,
              perda_termica: 0,
              recirculacao: "",
            };
            current_res.ligacao = connectionStart + " -> " + connectionEnd;
            current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
            if (current_res.coef_simultaniedade < 0.4) current_res.coef_simultaniedade = 0.4;
            let caudal_total = 0;

            for (let child of connectionEndChildren) {
              caudal_total += getDivTotalCaudal(child);
            }

            current_res.caudal = current_res.coef_simultaniedade * caudal_total;

            let tubo_stats = getAdjustedDiameter(current_res.caudal, cenarioBase?.tipo_tubo);
            let tubo_width = 0;
            if (typeof cenarioBase?.pepc_connections[connectionStart][connectionEnd] === "number") {
              tubo_width = cenarioBase?.pepc_connections[connectionStart][connectionEnd];
              current_res.diametro_ajustado = tubo_stats.diametro;
            } else {
              current_res.diametro_ajustado = cenarioBase?.pepc_connections[connectionStart][connectionEnd].adjustedDiameter;
              tubo_stats = getTubeStatsByDiamenter(current_res.diametro_ajustado, cenarioBase?.tipo_tubo);
              tubo_width = cenarioBase?.pepc_connections[connectionStart][connectionEnd].width;
            }

            current_res.comprimento = tubo_width;

            if (tubo_stats) {
              var agua_estagnada_teorica = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

              agua_soma += agua_estagnada_teorica;

              if (cenarioBase?.pepc_connections[connectionStart][connectionEnd].recirculacao === 0) {
                current_res.agua_estagnada = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

                number_recirculation += 1;

                if (numberHoterway === null) {
                  current_res.perda_termica = 0;
                } else {
                  current_res.perda_termica = calculatePerdaTermica(
                    cenarioBase?.insolation_width,
                    tubo_stats,
                    cenarioBase?.cct,
                    cenarioBase?.ccppt,
                    tubo_width,
                    cenarioBase?.running_time_hoterway
                  );
                }

                current_res.recirculacao = t("general.no");
              } else if (cenarioBase?.pepc_connections[connectionStart][connectionEnd].recirculacao === 1) {
                current_res.perda_termica = calculatePerdaTermica(
                  cenarioBase?.insolation_width,
                  tubo_stats,
                  cenarioBase?.cct,
                  cenarioBase?.ccppt,
                  tubo_width,
                  cenarioBase?.running_time
                );

                current_res.recirculacao = t("general.yes");
              } else {
                current_res.recirculacao = t("general.error");
              }
              if (tubo_width === 0) {
                current_res.comprimento = 0;
                current_res.coef_simultaniedade = 1;
                current_res.caudal = 0;
                current_res.diametro_ajustado = 0;
                current_res.agua_estagnada = 0;
                current_res.perda_termica = 0;
                current_res.recirculacao = "";
              }

              current_res.diametro_interior = (2 * Math.sqrt(tubo_stats.agua_estagnada / 1000 / Math.PI) * 1000).toFixed(1);

              res.push(current_res);
            }
          }
        }

        return res;
      };

      const connections_data = calculateConnectionsAttributes();
      setConnectionsData(connections_data);

      const findPathToDiv = (start: string, end: string) => {
        let res = [];

        if (!(start in cenarioBase?.pepc_connections)) return [];
        if (end in cenarioBase?.pepc_connections[start]) return [[start, end]];
        for (let next_point of Object.keys(cenarioBase?.pepc_connections[start])) {
          const path_found = findPathToDiv(next_point, end);
          if (path_found.length !== 0) {
            res.push([start, next_point]);
            res = [...res, ...path_found];
          }
        }
        return res;
      };

      const calculateDivsAttributes = () => {
        const res = [];
        if (!cenarioBase || Object.keys(cenarioBase?.pepc_connections).length === 0 || connections_data.length === 0) return [];
        for (let div of Object.keys(cenarioBase?.pepc_layout)) {
          const pathToDiv = findPathToDiv(
            cenarioBase?.water_heating_system ?? "T0",
            cenarioBase?.pepc_layout[div]["nickname"] ? cenarioBase?.pepc_layout[div]["nickname"] : cenarioBase?.pepc_layout[div]["name"]
          );

          let agua_estagnada = 0;
          let none_div = false;

          for (let connection_to_div of pathToDiv) {
            for (let connection of connections_data) {
              if (connection.ligacao === connection_to_div[0] + " -> " + connection_to_div[1]) {
                agua_estagnada += connection.agua_estagnada;
                if (connection.comprimento === 0) none_div = true;
              }
            }
          }
          if (none_div) agua_estagnada = 0;
          const div_min_pc = getDivMinCaudal(
            cenarioBase?.pepc_layout[div]["nickname"] ? cenarioBase?.pepc_layout[div]["nickname"] : cenarioBase?.pepc_layout[div]["name"]
          );

          if (cenarioBase?.pepc_layout2[div]["hoterway"] === "1" || cenarioBase?.pepc_layout2[div]["hoterway"] === 1) {
            number_hoterway += 1;
            hoterway_number = number_hoterway;
            agua_estagnada = Math.max(agua_estagnada - 10, 0) + cenarioBase?.volume_aqs;
          } else {
            agua_estagnada = agua_estagnada + cenarioBase?.volume_aqs;
          }
          hoterway_number = hoterway_number + 1;
          const wait_time = agua_estagnada / div_min_pc;
          if (wait_times < wait_time) {
            wait_times = wait_time;
            division = cenarioBase?.pepc_layout[div]["nickname"];
          }

          let wait_time_minutes = "" + Math.floor(wait_time);
          let wait_time_seconds = "" + Math.round((wait_time - parseInt(wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (wait_time_seconds === "60") {
            wait_time_minutes = "" + (parseInt(wait_time_minutes) + 1);
            wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          wait_time_minutes = wait_time_minutes.padStart(2, "0");
          wait_time_seconds = wait_time_seconds.padStart(2, "0");

          let max_wait_time_minutes = "" + Math.floor(wait_times);
          let max_wait_time_seconds = "" + Math.round((wait_times - parseInt(max_wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (max_wait_time_seconds === "60") {
            max_wait_time_minutes = "" + (parseInt(max_wait_time_minutes) + 1);
            max_wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          max_wait_time_minutes = max_wait_time_minutes.padStart(2, "0");
          max_wait_time_seconds = max_wait_time_seconds.padStart(2, "0");

          waiting_time = max_wait_time_minutes + ":" + max_wait_time_seconds;
          setWaitingTime(waiting_time);
          waiting_time_seconds = parseInt(max_wait_time_minutes) * 60 + parseInt(max_wait_time_seconds);

          const current_res: {
            nome: string;
            nickname: string;
            agua_estagnada: number;
            espera: string;
            hoterway: string;
          } = {
            nome: cenarioBase?.pepc_layout[div]["name"],
            nickname: cenarioBase?.pepc_layout[div]["nickname"]
              ? cenarioBase?.pepc_layout[div]["nickname"]
              : cenarioBase?.pepc_layout[div]["name"],
            agua_estagnada: agua_estagnada,
            espera: wait_time_minutes + ":" + wait_time_seconds,
            hoterway: cenarioBase?.pepc_layout2[div]["hoterway"] === "1" ? t("general.yes") : t("general.no"),
          };

          res.push(current_res);
        }
        return res;
      };

      const divs_data = calculateDivsAttributes();
      setDivsData(divs_data);

      const getWaterWaste = () => {
        let total_agua_estagnada = 0;
        for (let connectionData of divs_data) {
          total_agua_estagnada += connectionData.agua_estagnada;
        }

        if (cenarioBase?.building_type === 0) {
          return (total_agua_estagnada * cenarioBase?.total_habitants * cenarioBase?.pe_usage * 365) / 1000;
        } else if (cenarioBase?.building_type === 1) {
          return (
            (cenarioBase?.pe_usage * cenarioBase?.total_habitants * (total_agua_estagnada / cenarioBase?.house_parts_count) * 365) / 1000
          );
        } else {
          return (
            (cenarioBase?.pe_usage * cenarioBase?.total_habitants * (total_agua_estagnada / cenarioBase?.house_parts_count) * 365) / 1000
          );
        }
      };

      const getEnergyWaste = () => {
        let perda_termica_total = 0;
        let comprimento_total = 0;

        for (let connection of connections_data) {
          perda_termica_total += connection.perda_termica;
          comprimento_total += connection.comprimento;
        }

        const energy_bomba_recirculacao = 0.544 * cenarioBase?.average_power * perda_termica_total * 24 * 365 * 10 ** -6;

        consumo_bomba = (energy_bomba_recirculacao * cenarioBase?.running_time) / 24;

        let min_perda_termica = 1000;

        if (cenarioBase?.insolation_width === 0) {
          for (let tube_size of defaults.tipos_tubagem[cenarioBase?.tipo_tubo])
            if (tube_size.perda_termica_1 < min_perda_termica) min_perda_termica = tube_size.perda_termica_1;
        } else if (cenarioBase?.insolation_width === 1) {
          for (let tube_size of defaults.tipos_tubagem[cenarioBase?.tipo_tubo])
            if (tube_size.perda_termica_2 < min_perda_termica) min_perda_termica = tube_size.perda_termica_2;
        } else {
          for (let tube_size of defaults.tipos_tubagem[cenarioBase?.tipo_tubo])
            if (tube_size.perda_termica_3 < min_perda_termica) min_perda_termica = tube_size.perda_termica_3;
        }

        perda_termica_parcial = (perda_termica_total * 1.7).toFixed(1);
        perda_termica_money = (perda_termica_parcial * cenarioBase?.price_thermal * 1000).toFixed(2);
        formattedPerdaTermicaMoney = perda_termica_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      };

      getEnergyWaste();
      water_waste = getWaterWaste();
      energy_waste = (parseFloat(perda_termica_parcial) + parseFloat(consumo_bomba)).toFixed(1);
      energy_percentage = (parseFloat(energy_waste) / expectedEnergy).toFixed(2);
      const total_water_consumption = (cenarioBase?.water_consumption * 3 * cenarioBase?.total_habitants * 365) / 1000;

      // REMOVER IF QUANDO SE APAGAREM OS PROJETOS ANTIGOS
      if (cenarioBase?.water_price === undefined || cenarioBase?.water_price === null) {
        water_cost = (4.25 * water_waste).toFixed(2);
      } else {
        water_cost = (cenarioBase?.water_price * water_waste).toFixed(2);
      }

      consumo_bomba_money = (consumo_bomba * cenarioBase?.price_energy * 1000).toFixed(2);
      formattedConsumoBombaMoney = consumo_bomba_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      money_energy = (parseFloat(perda_termica_money) + parseFloat(consumo_bomba_money)).toFixed(2).toLocaleString();
      formattedMoneyEnergy = money_energy.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

      if (consumo_bomba.toFixed(1) == 0.0) {
        consumo_bomba = consumo_bomba.toFixed(3);
      } else {
        consumo_bomba = consumo_bomba.toFixed(1);
      }

      let consumo_pre = 0;
      let ratio = 0;
      switch (cenarioRetorno?.certificate_class) {
        case 0:
          // consumo_pre = cenario?.energy_reference * 0.125;
          consumo_pre = 150 * 0.125;
          break;
        case 1:
          // consumo_pre = cenario?.energy_reference * 0.375;
          consumo_pre = 150 * 0.375;
          break;
        case 2:
          // consumo_pre = cenario?.energy_reference * 0.625;
          consumo_pre = 150 * 0.625;
          break;
        case 3:
          // consumo_pre = cenario?.energy_reference * 0.875;
          consumo_pre = 150 * 0.875;
          break;
        case 4:
          // consumo_pre = cenario?.energy_reference * 1.25;
          consumo_pre = 150 * 1.25;
          break;
        case 5:
          // consumo_pre = cenario?.energy_reference * 1.75;
          consumo_pre = 150 * 1.75;
          break;
        case 6:
          // consumo_pre = cenario?.energy_reference * 2.25;
          consumo_pre = 150 * 2.25;
          break;
        case 7:
          // consumo_pre = cenario?.energy_reference * 3.0;
          consumo_pre = 150 * 3.0;
          break;
        default:
          consumo_pre = 0;
          break;
      }

      expectedEnergy = ((consumo_pre * cenarioBase?.building_area) / 1000 / 0.79).toFixed(2);

      setBase({
        var1_1:
          parseFloat(
            (
              (water_waste /
                (water_waste + ((cenarioBase?.water_consumption ?? 0) * 3 * (cenarioBase?.total_habitants ?? 1) * 365) / 1000)) *
              100
            ).toFixed(1)
          ) ?? 0,
        var1_2: parseFloat((water_waste ?? 0).toFixed(1)),
        var1_3:
          cenarioBase?.certificate_class === 0
            ? "A+"
            : cenarioBase?.certificate_class === 1
            ? "A"
            : cenarioBase?.certificate_class === 2
            ? "B"
            : cenarioBase?.certificate_class === 3
            ? "B-"
            : cenarioBase?.certificate_class === 4
            ? "C"
            : cenarioBase?.certificate_class === 5
            ? "D"
            : cenarioBase?.certificate_class === 6
            ? "E"
            : cenarioBase?.certificate_class === 7
            ? "F"
            : "---",
        var1_4: cenarioBase?.client_name,
        var1_5: cenarioBase?.house_parts_count ?? 0,
        var1_6: cenarioBase?.tipo_tubo === null ? "---" : t(`scenario_report.considerations.tipo_tubo.${cenarioBase?.tipo_tubo}`),
        var1_7: parseInt(cenarioBase?.total_habitants ?? "0"),
        var1_8: waiting_time_seconds ?? 0,
        var1_9: division ?? 0,
        var1_10: parseFloat((water_waste ?? 0).toFixed(1)) * 1000,
        var1_11: divs_data,
        var1_12: cenarioBase?.active_consumption,
        var1_13: cenarioBase?.pe_usage ?? 0,
        var1_14: cenarioBase?.price_thermal ?? 0,
        var1_15: cenarioBase?.price_energy ?? 0,
        var1_16: cenarioBase?.running_time ?? 0,
        var1_17: cenarioBase?.volume_aqs ?? 0,
        var1_18: cenarioBase?.water_consumption ?? 0,
        var1_19: cenarioBase?.water_in_temp ?? 0,
        var1_20: cenarioBase?.hot_water_temp ?? 0,
        var1_21: cenarioBase?.average_power ?? 0,
        var1_22: cenarioBase?.water_price ?? 0,
        var1_23:
          cenarioBase?.insolation_width === 0
            ? t("scenario_report.considerations.insolation_width.0")
            : cenarioBase?.insolation_width === 1
            ? t("scenario_report.considerations.insolation_width.1")
            : cenarioBase?.insolation_width === 2
            ? t("scenario_report.considerations.insolation_width.2")
            : "---",
        var1_24:
          cenarioBase?.ccppt === 1.15
            ? t("scenario_report.considerations.ccppt.1_15")
            : cenarioBase?.ccppt === 1.35
            ? t("scenario_report.considerations.ccppt.1_35")
            : cenarioBase?.ccppt === 1.65
            ? t("scenario_report.considerations.ccppt.1_65")
            : "---",
        var1_25:
          cenarioBase?.cct === 1.68
            ? "20 mW/m.K"
            : cenarioBase?.cct === 1.36
            ? "25 mW/m.K"
            : cenarioBase?.cct === 1.15
            ? "30 mW/m.K"
            : cenarioBase?.cct === 1
            ? "35 mW/m.K"
            : cenarioBase?.cct === 0.89
            ? "40 mW/m.K"
            : cenarioBase?.cct === 0.8
            ? "45 mW/m.K"
            : "---",
        var1_26: cenarioBase?.building_area ?? 0,
        var1_27: expectedEnergy ?? 0,
        var1_28: cenarioBase?.konva_image,
      });
    }
  }, [cenarioBase]);

  useEffect(() => {
    if (cenarioRetorno && cenarioRetorno !== undefined && cenarioRetorno !== null) {
      let number_hoterway = 0;
      let number_recirculation = 0;
      let wait_times = 0;
      let expectedEnergy;
      let consumo_bomba;
      let consumo_bomba_money;
      let formattedConsumoBombaMoney;
      let perda_termica_parcial;
      let perda_termica_money;
      let formattedPerdaTermicaMoney;
      let water_cost;
      let water_waste = 0;
      let energy_waste;
      let money_energy;
      let energy_percentage;
      let formattedMoneyEnergy;
      let hoterway_number;
      let division;
      let waiting_time;
      let waiting_time_seconds;

      const getDivMinCaudal = (children: string) => {
        const div_key = Object.keys(cenarioRetorno?.pepc_layout).find(
          (key) =>
            (cenarioRetorno?.pepc_layout[key]["nickname"]
              ? cenarioRetorno?.pepc_layout[key]["nickname"]
              : cenarioRetorno?.pepc_layout[key]["name"]) === children
        );
        let res = 100000;
        if (div_key) {
          res = cenarioRetorno?.pepc_layout[div_key]["caudal_minimo"];
        }
        return res;
      };

      const getDivTotalCaudal = (children: string) => {
        const div_key = Object.keys(cenarioRetorno?.pepc_layout).find(
          (key) =>
            (cenarioRetorno?.pepc_layout[key]["nickname"]
              ? cenarioRetorno?.pepc_layout[key]["nickname"]
              : cenarioRetorno?.pepc_layout[key]["name"]) === children
        );
        let res = 0;
        if (div_key) {
          if (isNaN(parseFloat(cenarioRetorno?.pepc_layout[div_key]["caudal_total"])))
            console.log("error:" + cenarioRetorno?.pepc_layout[div_key]["caudal_total"]);
          else res += parseFloat(cenarioRetorno?.pepc_layout[div_key]["caudal_total"]);
        }

        return res;
      };

      const getChildren = (connectionStart: string) => {
        let res = [];
        if (connectionStart in cenarioRetorno?.pepc_connections)
          for (let connectionEnd of Object.keys(cenarioRetorno?.pepc_connections[connectionStart])) {
            const children = getChildren(connectionEnd);
            res = [...res, ...children];
          }
        else return [connectionStart];
        return res;
      };

      const calculateConnectionsAttributes = () => {
        if (!cenarioRetorno || Object.keys(cenarioRetorno?.pepc_connections).length === 0) return [];
        const res = [];

        let agua_soma = 0;

        for (let connectionStart of Object.keys(cenarioRetorno?.pepc_connections)) {
          for (let connectionEnd of Object.keys(cenarioRetorno?.pepc_connections[connectionStart])) {
            const connectionEndChildren = getChildren(connectionEnd);
            const current_res: {
              ligacao: string;
              comprimento: number;
              coef_simultaniedade: number;
              caudal: number;
              diametro_ajustado: number;
              diametro_interior: number;
              agua_estagnada: number;
              perda_termica: number;
              recirculacao: string;
            } = {
              ligacao: "",
              comprimento: 0,
              coef_simultaniedade: 0,
              caudal: 0,
              diametro_ajustado: 0,
              diametro_interior: 0,
              agua_estagnada: 0,
              perda_termica: 0,
              recirculacao: "",
            };
            current_res.ligacao = connectionStart + " -> " + connectionEnd;
            current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
            if (current_res.coef_simultaniedade < 0.4) current_res.coef_simultaniedade = 0.4;
            let caudal_total = 0;

            for (let child of connectionEndChildren) {
              caudal_total += getDivTotalCaudal(child);
            }

            current_res.caudal = current_res.coef_simultaniedade * caudal_total;

            let tubo_stats = getAdjustedDiameter(current_res.caudal, cenarioRetorno?.tipo_tubo);
            let tubo_width = 0;
            if (typeof cenarioRetorno?.pepc_connections[connectionStart][connectionEnd] === "number") {
              tubo_width = cenarioRetorno?.pepc_connections[connectionStart][connectionEnd];
              current_res.diametro_ajustado = tubo_stats.diametro;
            } else {
              current_res.diametro_ajustado = cenarioRetorno?.pepc_connections[connectionStart][connectionEnd].adjustedDiameter;
              tubo_stats = getTubeStatsByDiamenter(current_res.diametro_ajustado, cenarioRetorno?.tipo_tubo);
              tubo_width = cenarioRetorno?.pepc_connections[connectionStart][connectionEnd].width;
            }

            current_res.comprimento = tubo_width;

            if (tubo_stats) {
              var agua_estagnada_teorica = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

              agua_soma += agua_estagnada_teorica;

              if (cenarioRetorno?.pepc_connections[connectionStart][connectionEnd].recirculacao === 0) {
                current_res.agua_estagnada = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

                number_recirculation += 1;

                if (numberHoterway === null) {
                  current_res.perda_termica = 0;
                } else {
                  current_res.perda_termica = calculatePerdaTermica(
                    cenarioRetorno?.insolation_width,
                    tubo_stats,
                    cenarioRetorno?.cct,
                    cenarioRetorno?.ccppt,
                    tubo_width,
                    cenarioRetorno?.running_time_hoterway
                  );
                }

                current_res.recirculacao = t("general.no");
              } else if (cenarioRetorno?.pepc_connections[connectionStart][connectionEnd].recirculacao === 1) {
                current_res.perda_termica = calculatePerdaTermica(
                  cenarioRetorno?.insolation_width,
                  tubo_stats,
                  cenarioRetorno?.cct,
                  cenarioRetorno?.ccppt,
                  tubo_width,
                  cenarioRetorno?.running_time
                );

                current_res.recirculacao = t("general.yes");
              } else {
                current_res.recirculacao = t("general.error");
              }
              if (tubo_width === 0) {
                current_res.comprimento = 0;
                current_res.coef_simultaniedade = 1;
                current_res.caudal = 0;
                current_res.diametro_ajustado = 0;
                current_res.agua_estagnada = 0;
                current_res.perda_termica = 0;
                current_res.recirculacao = "";
              }

              current_res.diametro_interior = (2 * Math.sqrt(tubo_stats.agua_estagnada / 1000 / Math.PI) * 1000).toFixed(1);

              res.push(current_res);
            }
          }
        }

        return res;
      };

      const connections_data = calculateConnectionsAttributes();

      const findPathToDiv = (start: string, end: string) => {
        let res = [];

        if (!(start in cenarioRetorno?.pepc_connections)) return [];
        if (end in cenarioRetorno?.pepc_connections[start]) return [[start, end]];
        for (let next_point of Object.keys(cenarioRetorno?.pepc_connections[start])) {
          const path_found = findPathToDiv(next_point, end);
          if (path_found.length !== 0) {
            res.push([start, next_point]);
            res = [...res, ...path_found];
          }
        }
        return res;
      };

      const calculateDivsAttributes = () => {
        const res = [];
        if (!cenarioRetorno || Object.keys(cenarioRetorno?.pepc_connections).length === 0 || connections_data.length === 0) return [];
        for (let div of Object.keys(cenarioRetorno?.pepc_layout)) {
          const pathToDiv = findPathToDiv(
            cenarioRetorno?.water_heating_system ?? "T0",
            cenarioRetorno?.pepc_layout[div]["nickname"]
              ? cenarioRetorno?.pepc_layout[div]["nickname"]
              : cenarioRetorno?.pepc_layout[div]["name"]
          );

          let agua_estagnada = 0;
          let none_div = false;

          for (let connection_to_div of pathToDiv) {
            for (let connection of connections_data) {
              if (connection.ligacao === connection_to_div[0] + " -> " + connection_to_div[1]) {
                agua_estagnada += connection.agua_estagnada;
                if (connection.comprimento === 0) none_div = true;
              }
            }
          }
          if (none_div) agua_estagnada = 0;
          const div_min_pc = getDivMinCaudal(
            cenarioRetorno?.pepc_layout[div]["nickname"]
              ? cenarioRetorno?.pepc_layout[div]["nickname"]
              : cenarioRetorno?.pepc_layout[div]["name"]
          );

          if (cenarioRetorno?.pepc_layout2[div]["hoterway"] === "1" || cenarioRetorno?.pepc_layout2[div]["hoterway"] === 1) {
            number_hoterway += 1;
            hoterway_number = number_hoterway;
            agua_estagnada = Math.max(agua_estagnada - 10, 0) + cenarioRetorno?.volume_aqs;
          } else {
            agua_estagnada = agua_estagnada + cenarioRetorno?.volume_aqs;
          }
          hoterway_number = hoterway_number + 1;
          const wait_time = agua_estagnada / div_min_pc;
          if (wait_times < wait_time) {
            wait_times = wait_time;
            division = cenarioRetorno?.pepc_layout[div]["nickname"];
          }

          let wait_time_minutes = "" + Math.floor(wait_time);
          let wait_time_seconds = "" + Math.round((wait_time - parseInt(wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (wait_time_seconds === "60") {
            wait_time_minutes = "" + (parseInt(wait_time_minutes) + 1);
            wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          wait_time_minutes = wait_time_minutes.padStart(2, "0");
          wait_time_seconds = wait_time_seconds.padStart(2, "0");

          let max_wait_time_minutes = "" + Math.floor(wait_times);
          let max_wait_time_seconds = "" + Math.round((wait_times - parseInt(max_wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (max_wait_time_seconds === "60") {
            max_wait_time_minutes = "" + (parseInt(max_wait_time_minutes) + 1);
            max_wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          max_wait_time_minutes = max_wait_time_minutes.padStart(2, "0");
          max_wait_time_seconds = max_wait_time_seconds.padStart(2, "0");

          waiting_time = max_wait_time_minutes + ":" + max_wait_time_seconds;
          waiting_time_seconds = parseInt(max_wait_time_minutes) * 60 + parseInt(max_wait_time_seconds);

          const current_res: {
            nome: string;
            nickname: string;
            agua_estagnada: number;
            espera: string;
            hoterway: string;
          } = {
            nome: cenarioRetorno?.pepc_layout[div]["name"],
            nickname: cenarioRetorno?.pepc_layout[div]["nickname"]
              ? cenarioRetorno?.pepc_layout[div]["nickname"]
              : cenarioRetorno?.pepc_layout[div]["name"],
            agua_estagnada: agua_estagnada,
            espera: wait_time_minutes + ":" + wait_time_seconds,
            hoterway: cenarioRetorno?.pepc_layout2[div]["hoterway"] === "1" ? t("general.yes") : t("general.no"),
          };

          res.push(current_res);
        }
        return res;
      };

      const divs_data = calculateDivsAttributes();

      const getWaterWaste = () => {
        let total_agua_estagnada = 0;
        for (let connectionData of divs_data) {
          total_agua_estagnada += connectionData.agua_estagnada;
        }

        if (cenarioRetorno?.building_type === 0) {
          return (total_agua_estagnada * cenarioRetorno?.total_habitants * cenarioRetorno?.pe_usage * 365) / 1000;
        } else if (cenarioRetorno?.building_type === 1) {
          return (
            (cenarioRetorno?.pe_usage *
              cenarioRetorno?.total_habitants *
              (total_agua_estagnada / cenarioRetorno?.house_parts_count) *
              365) /
            1000
          );
        } else {
          return (
            (cenarioRetorno?.pe_usage *
              cenarioRetorno?.total_habitants *
              (total_agua_estagnada / cenarioRetorno?.house_parts_count) *
              365) /
            1000
          );
        }
      };

      const getEnergyWaste = () => {
        let perda_termica_total = 0;
        let comprimento_total = 0;

        for (let connection of connections_data) {
          perda_termica_total += connection.perda_termica;
          comprimento_total += connection.comprimento;
        }

        const energy_bomba_recirculacao = 0.544 * cenarioRetorno?.average_power * perda_termica_total * 24 * 365 * 10 ** -6;

        consumo_bomba = (energy_bomba_recirculacao * cenarioRetorno?.running_time) / 24;

        let min_perda_termica = 1000;

        if (cenarioRetorno?.insolation_width === 0) {
          for (let tube_size of defaults.tipos_tubagem[cenarioRetorno?.tipo_tubo])
            if (tube_size.perda_termica_1 < min_perda_termica) min_perda_termica = tube_size.perda_termica_1;
        } else if (cenarioRetorno?.insolation_width === 1) {
          for (let tube_size of defaults.tipos_tubagem[cenarioRetorno?.tipo_tubo])
            if (tube_size.perda_termica_2 < min_perda_termica) min_perda_termica = tube_size.perda_termica_2;
        } else {
          for (let tube_size of defaults.tipos_tubagem[cenarioRetorno?.tipo_tubo])
            if (tube_size.perda_termica_3 < min_perda_termica) min_perda_termica = tube_size.perda_termica_3;
        }

        perda_termica_parcial = (perda_termica_total * 1.7).toFixed(1);
        perda_termica_money = (perda_termica_parcial * cenarioRetorno?.price_thermal * 1000).toFixed(2);
        formattedPerdaTermicaMoney = perda_termica_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      };

      getEnergyWaste();
      water_waste = getWaterWaste();
      energy_waste = (parseFloat(perda_termica_parcial) + parseFloat(consumo_bomba)).toFixed(1);
      energy_percentage = (parseFloat(energy_waste) / expectedEnergy).toFixed(2);
      const total_water_consumption = (cenarioRetorno?.water_consumption * 3 * cenarioRetorno?.total_habitants * 365) / 1000;

      // REMOVER IF QUANDO SE APAGAREM OS PROJETOS ANTIGOS
      if (cenarioRetorno?.water_price === undefined || cenarioRetorno?.water_price === null) {
        water_cost = (4.25 * water_waste).toFixed(2);
      } else {
        water_cost = (cenarioRetorno?.water_price * water_waste).toFixed(2);
      }

      consumo_bomba_money = (consumo_bomba * cenarioRetorno?.price_energy * 1000).toFixed(2);
      formattedConsumoBombaMoney = consumo_bomba_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      money_energy = (parseFloat(perda_termica_money) + parseFloat(consumo_bomba_money)).toFixed(2).toLocaleString();
      formattedMoneyEnergy = money_energy.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

      if (consumo_bomba.toFixed(1) == 0.0) {
        consumo_bomba = consumo_bomba.toFixed(3);
      } else {
        consumo_bomba = consumo_bomba.toFixed(1);
      }

      function calculateFinalCertificate() {
        let consumo_pre = 0;
        let ratio = 0;
        switch (cenarioRetorno?.certificate_class) {
          case 0:
            // consumo_pre = cenario?.energy_reference * 0.125;
            consumo_pre = 150 * 0.125;
            break;
          case 1:
            // consumo_pre = cenario?.energy_reference * 0.375;
            consumo_pre = 150 * 0.375;
            break;
          case 2:
            // consumo_pre = cenario?.energy_reference * 0.625;
            consumo_pre = 150 * 0.625;
            break;
          case 3:
            // consumo_pre = cenario?.energy_reference * 0.875;
            consumo_pre = 150 * 0.875;
            break;
          case 4:
            // consumo_pre = cenario?.energy_reference * 1.25;
            consumo_pre = 150 * 1.25;
            break;
          case 5:
            // consumo_pre = cenario?.energy_reference * 1.75;
            consumo_pre = 150 * 1.75;
            break;
          case 6:
            // consumo_pre = cenario?.energy_reference * 2.25;
            consumo_pre = 150 * 2.25;
            break;
          case 7:
            // consumo_pre = cenario?.energy_reference * 3.0;
            consumo_pre = 150 * 3.0;
            break;
          default:
            consumo_pre = 0;
            break;
        }

        expectedEnergy = ((consumo_pre * cenarioRetorno?.building_area) / 1000 / 0.79).toFixed(2);

        ratio =
          ((consumo_pre * cenarioRetorno?.building_area + parseFloat(energy_waste) * 1000) / (150 * cenarioRetorno?.building_area)) * 100;

        if (ratio > 0 && ratio < 25) {
          return 0;
        } else if (ratio > 25 && ratio <= 50) {
          return 1;
        } else if (ratio > 50 && ratio <= 75) {
          return 2;
        } else if (ratio > 75 && ratio <= 100) {
          return 3;
        } else if (ratio > 100 && ratio <= 150) {
          return 4;
        } else if (ratio > 150 && ratio <= 200) {
          return 5;
        } else if (ratio > 200 && ratio <= 250) {
          return 6;
        } else if (ratio > 250) {
          return 7;
        } else {
          return "Erro";
        }
      }

      calculateFinalCertificate();

      setRetorno({
        var2_1: parseInt(((energy_waste / expectedEnergy) * 100).toFixed(2)) ?? 0,
        var2_2: parseFloat(energy_waste ?? 0),
        var2_3: getCertificateText(calculateFinalCertificate()) ?? "",
        var2_4: parseFloat(money_energy ?? 0),
        var2_5: waiting_time_seconds,
      });
    }
  }, [cenarioRetorno]);

  useEffect(() => {
    if (base && retorno && cenarioHoterway && defaults) {
      let number_hoterway = 0;
      let number_recirculation = 0;
      let wait_times = 0;
      let expectedEnergy;
      let consumo_bomba;
      let consumo_bomba_money;
      let formattedConsumoBombaMoney;
      let perda_termica_parcial;
      let perda_termica_money;
      let formattedPerdaTermicaMoney;
      let water_cost;
      let water_waste = 0;
      let energy_waste;
      let money_energy;
      let energy_percentage;
      let formattedMoneyEnergy;
      let hoterway_number;
      let division;
      let waiting_time;
      let waiting_time_seconds;
      let hoterway_divs = "";

      const getDivMinCaudal = (children: string) => {
        const div_key = Object.keys(cenarioHoterway?.pepc_layout).find(
          (key) =>
            (cenarioHoterway?.pepc_layout[key]["nickname"]
              ? cenarioHoterway?.pepc_layout[key]["nickname"]
              : cenarioHoterway?.pepc_layout[key]["name"]) === children
        );
        let res = 100000;
        if (div_key) {
          res = cenarioHoterway?.pepc_layout[div_key]["caudal_minimo"];
        }
        return res;
      };

      const getDivTotalCaudal = (children: string) => {
        const div_key = Object.keys(cenarioHoterway?.pepc_layout).find(
          (key) =>
            (cenarioHoterway?.pepc_layout[key]["nickname"]
              ? cenarioHoterway?.pepc_layout[key]["nickname"]
              : cenarioHoterway?.pepc_layout[key]["name"]) === children
        );
        let res = 0;
        if (div_key) {
          if (isNaN(parseFloat(cenarioHoterway?.pepc_layout[div_key]["caudal_total"])))
            console.log("error:" + cenarioHoterway?.pepc_layout[div_key]["caudal_total"]);
          else res += parseFloat(cenarioHoterway?.pepc_layout[div_key]["caudal_total"]);
        }

        return res;
      };

      const getChildren = (connectionStart: string) => {
        let res = [];
        if (connectionStart in cenarioHoterway?.pepc_connections)
          for (let connectionEnd of Object.keys(cenarioHoterway?.pepc_connections[connectionStart])) {
            const children = getChildren(connectionEnd);
            res = [...res, ...children];
          }
        else return [connectionStart];
        return res;
      };

      const calculateConnectionsAttributes = () => {
        if (!cenarioHoterway || Object.keys(cenarioHoterway?.pepc_connections).length === 0) return [];
        const res = [];

        let agua_soma = 0;

        for (let connectionStart of Object.keys(cenarioHoterway?.pepc_connections)) {
          for (let connectionEnd of Object.keys(cenarioHoterway?.pepc_connections[connectionStart])) {
            const connectionEndChildren = getChildren(connectionEnd);
            const current_res: {
              ligacao: string;
              comprimento: number;
              coef_simultaniedade: number;
              caudal: number;
              diametro_ajustado: number;
              diametro_interior: number;
              agua_estagnada: number;
              perda_termica: number;
              recirculacao: string;
            } = {
              ligacao: "",
              comprimento: 0,
              coef_simultaniedade: 0,
              caudal: 0,
              diametro_ajustado: 0,
              diametro_interior: 0,
              agua_estagnada: 0,
              perda_termica: 0,
              recirculacao: "",
            };
            current_res.ligacao = connectionStart + " -> " + connectionEnd;
            current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
            if (current_res.coef_simultaniedade < 0.4) current_res.coef_simultaniedade = 0.4;
            let caudal_total = 0;

            for (let child of connectionEndChildren) {
              caudal_total += getDivTotalCaudal(child);
            }

            current_res.caudal = current_res.coef_simultaniedade * caudal_total;

            let tubo_stats = getAdjustedDiameter(current_res.caudal, cenarioHoterway?.tipo_tubo);
            let tubo_width = 0;
            if (typeof cenarioHoterway?.pepc_connections[connectionStart][connectionEnd] === "number") {
              tubo_width = cenarioHoterway?.pepc_connections[connectionStart][connectionEnd];
              current_res.diametro_ajustado = tubo_stats.diametro;
            } else {
              current_res.diametro_ajustado = cenarioHoterway?.pepc_connections[connectionStart][connectionEnd].adjustedDiameter;
              tubo_stats = getTubeStatsByDiamenter(current_res.diametro_ajustado, cenarioHoterway?.tipo_tubo);
              tubo_width = cenarioHoterway?.pepc_connections[connectionStart][connectionEnd].width;
            }

            current_res.comprimento = tubo_width;

            if (tubo_stats) {
              var agua_estagnada_teorica = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

              agua_soma += agua_estagnada_teorica;

              if (cenarioHoterway?.pepc_connections[connectionStart][connectionEnd].recirculacao === 0) {
                current_res.agua_estagnada = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

                number_recirculation += 1;

                if (numberHoterway === null) {
                  current_res.perda_termica = 0;
                } else {
                  current_res.perda_termica = calculatePerdaTermica(
                    cenarioHoterway?.insolation_width,
                    tubo_stats,
                    cenarioHoterway?.cct,
                    cenarioHoterway?.ccppt,
                    tubo_width,
                    cenarioHoterway?.running_time_hoterway
                  );
                }

                current_res.recirculacao = t("general.no");
              } else if (cenarioHoterway?.pepc_connections[connectionStart][connectionEnd].recirculacao === 1) {
                current_res.perda_termica = calculatePerdaTermica(
                  cenarioHoterway?.insolation_width,
                  tubo_stats,
                  cenarioHoterway?.cct,
                  cenarioHoterway?.ccppt,
                  tubo_width,
                  cenarioHoterway?.running_time
                );

                current_res.recirculacao = t("general.yes");
              } else {
                current_res.recirculacao = t("general.error");
              }
              if (tubo_width === 0) {
                current_res.comprimento = 0;
                current_res.coef_simultaniedade = 1;
                current_res.caudal = 0;
                current_res.diametro_ajustado = 0;
                current_res.agua_estagnada = 0;
                current_res.perda_termica = 0;
                current_res.recirculacao = "";
              }

              current_res.diametro_interior = (2 * Math.sqrt(tubo_stats.agua_estagnada / 1000 / Math.PI) * 1000).toFixed(1);

              res.push(current_res);
            }
          }
        }

        return res;
      };

      const connections_data = calculateConnectionsAttributes();

      const findPathToDiv = (start: string, end: string) => {
        let res = [];

        if (!(start in cenarioHoterway?.pepc_connections)) return [];
        if (end in cenarioHoterway?.pepc_connections[start]) return [[start, end]];
        for (let next_point of Object.keys(cenarioHoterway?.pepc_connections[start])) {
          const path_found = findPathToDiv(next_point, end);
          if (path_found.length !== 0) {
            res.push([start, next_point]);
            res = [...res, ...path_found];
          }
        }
        return res;
      };

      const calculateDivsAttributes = () => {
        const res = [];
        if (!cenarioHoterway || Object.keys(cenarioHoterway?.pepc_connections).length === 0 || connections_data.length === 0) return [];
        for (let div of Object.keys(cenarioHoterway?.pepc_layout)) {
          const pathToDiv = findPathToDiv(
            cenarioHoterway?.water_heating_system ?? "T0",
            cenarioHoterway?.pepc_layout[div]["nickname"]
              ? cenarioHoterway?.pepc_layout[div]["nickname"]
              : cenarioHoterway?.pepc_layout[div]["name"]
          );

          let agua_estagnada = 0;
          let none_div = false;

          for (let connection_to_div of pathToDiv) {
            for (let connection of connections_data) {
              if (connection.ligacao === connection_to_div[0] + " -> " + connection_to_div[1]) {
                agua_estagnada += connection.agua_estagnada;
                if (connection.comprimento === 0) none_div = true;
              }
            }
          }
          if (none_div) agua_estagnada = 0;
          const div_min_pc = getDivMinCaudal(
            cenarioHoterway?.pepc_layout[div]["nickname"]
              ? cenarioHoterway?.pepc_layout[div]["nickname"]
              : cenarioHoterway?.pepc_layout[div]["name"]
          );

          if (cenarioHoterway?.pepc_layout2[div]["hoterway"] === "1" || cenarioHoterway?.pepc_layout2[div]["hoterway"] === 1) {
            number_hoterway += 1;
            hoterway_number = number_hoterway;
            hoterway_divs = (() => {
              const newNickname = cenarioBase?.pepc_layout2[div]["nickname"];

              // Convert the existing string into an array and check if the new nickname is already included
              const namesArray = hoterway_divs.split(", ").filter(Boolean); // split and filter to remove empty values

              if (!namesArray.includes(newNickname)) {
                return hoterway_divs ? `${hoterway_divs}, ${newNickname}` : newNickname;
              }

              return hoterway_divs; // If the value already exists, return the previous value
            })();
            agua_estagnada = Math.max(agua_estagnada - 10, 0) + cenarioHoterway?.volume_aqs;
          } else {
            agua_estagnada = agua_estagnada + cenarioHoterway?.volume_aqs;
          }
          hoterway_number = hoterway_number + 1;
          const wait_time = agua_estagnada / div_min_pc;
          if (wait_times < wait_time) {
            wait_times = wait_time;
            division = cenarioHoterway?.pepc_layout[div]["nickname"];
          }

          let wait_time_minutes = "" + Math.floor(wait_time);
          let wait_time_seconds = "" + Math.round((wait_time - parseInt(wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (wait_time_seconds === "60") {
            wait_time_minutes = "" + (parseInt(wait_time_minutes) + 1);
            wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          wait_time_minutes = wait_time_minutes.padStart(2, "0");
          wait_time_seconds = wait_time_seconds.padStart(2, "0");

          let max_wait_time_minutes = "" + Math.floor(wait_times);
          let max_wait_time_seconds = "" + Math.round((wait_times - parseInt(max_wait_time_minutes)) * 60);

          // Round up to the nearest minute if seconds reach 60
          if (max_wait_time_seconds === "60") {
            max_wait_time_minutes = "" + (parseInt(max_wait_time_minutes) + 1);
            max_wait_time_seconds = "00";
          }

          // Ensure two-digit format for minutes and seconds
          max_wait_time_minutes = max_wait_time_minutes.padStart(2, "0");
          max_wait_time_seconds = max_wait_time_seconds.padStart(2, "0");

          waiting_time = max_wait_time_minutes + ":" + max_wait_time_seconds;
          waiting_time_seconds = parseInt(max_wait_time_minutes) * 60 + parseInt(max_wait_time_seconds);

          const current_res: {
            nome: string;
            nickname: string;
            agua_estagnada: number;
            espera: string;
            hoterway: string;
          } = {
            nome: cenarioHoterway?.pepc_layout[div]["name"],
            nickname: cenarioHoterway?.pepc_layout[div]["nickname"]
              ? cenarioHoterway?.pepc_layout[div]["nickname"]
              : cenarioHoterway?.pepc_layout[div]["name"],
            agua_estagnada: agua_estagnada,
            espera: wait_time_minutes + ":" + wait_time_seconds,
            hoterway: cenarioHoterway?.pepc_layout2[div]["hoterway"] === "1" ? t("general.yes") : t("general.no"),
          };

          res.push(current_res);
        }
        return res;
      };

      const divs_data = calculateDivsAttributes();

      const getWaterWaste = () => {
        let total_agua_estagnada = 0;
        for (let connectionData of divs_data) {
          total_agua_estagnada += connectionData.agua_estagnada;
        }

        if (cenarioHoterway?.building_type === 0) {
          return (total_agua_estagnada * cenarioHoterway?.total_habitants * cenarioHoterway?.pe_usage * 365) / 1000;
        } else if (cenarioHoterway?.building_type === 1) {
          return (
            (cenarioHoterway?.pe_usage *
              cenarioHoterway?.total_habitants *
              (total_agua_estagnada / cenarioHoterway?.house_parts_count) *
              365) /
            1000
          );
        } else {
          return (
            (cenarioHoterway?.pe_usage *
              cenarioHoterway?.total_habitants *
              (total_agua_estagnada / cenarioHoterway?.house_parts_count) *
              365) /
            1000
          );
        }
      };

      const getEnergyWaste = () => {
        let perda_termica_total = 0;
        let comprimento_total = 0;

        for (let connection of connections_data) {
          perda_termica_total += connection.perda_termica;
          comprimento_total += connection.comprimento;
        }

        const energy_bomba_recirculacao = 0.544 * cenarioHoterway?.average_power * perda_termica_total * 24 * 365 * 10 ** -6;

        consumo_bomba = (energy_bomba_recirculacao * cenarioHoterway?.running_time) / 24;

        let min_perda_termica = 1000;

        if (cenarioHoterway?.insolation_width === 0) {
          for (let tube_size of defaults.tipos_tubagem[cenarioHoterway?.tipo_tubo])
            if (tube_size.perda_termica_1 < min_perda_termica) min_perda_termica = tube_size.perda_termica_1;
        } else if (cenarioHoterway?.insolation_width === 1) {
          for (let tube_size of defaults.tipos_tubagem[cenarioHoterway?.tipo_tubo])
            if (tube_size.perda_termica_2 < min_perda_termica) min_perda_termica = tube_size.perda_termica_2;
        } else {
          for (let tube_size of defaults.tipos_tubagem[cenarioHoterway?.tipo_tubo])
            if (tube_size.perda_termica_3 < min_perda_termica) min_perda_termica = tube_size.perda_termica_3;
        }

        perda_termica_parcial = (perda_termica_total * 1.7).toFixed(1);
        perda_termica_money = (perda_termica_parcial * cenarioHoterway?.price_thermal * 1000).toFixed(2);
        formattedPerdaTermicaMoney = perda_termica_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      };

      getEnergyWaste();
      water_waste = getWaterWaste();
      energy_waste = (parseFloat(perda_termica_parcial) + parseFloat(consumo_bomba)).toFixed(1);
      energy_percentage = (parseFloat(energy_waste) / expectedEnergy).toFixed(2);
      const total_water_consumption = (cenarioHoterway?.water_consumption * 3 * cenarioHoterway?.total_habitants * 365) / 1000;

      // REMOVER IF QUANDO SE APAGAREM OS PROJETOS ANTIGOS
      if (cenarioHoterway?.water_price === undefined || cenarioHoterway?.water_price === null) {
        water_cost = (4.25 * water_waste).toFixed(2);
      } else {
        water_cost = (cenarioHoterway?.water_price * water_waste).toFixed(2);
      }

      consumo_bomba_money = (consumo_bomba * cenarioHoterway?.price_energy * 1000).toFixed(2);
      formattedConsumoBombaMoney = consumo_bomba_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      money_energy = (parseFloat(perda_termica_money) + parseFloat(consumo_bomba_money)).toFixed(2).toLocaleString();
      formattedMoneyEnergy = money_energy.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

      if (consumo_bomba.toFixed(1) == 0.0) {
        consumo_bomba = consumo_bomba.toFixed(3);
      } else {
        consumo_bomba = consumo_bomba.toFixed(1);
      }

      function calculateFinalCertificate() {
        let consumo_pre = 0;
        let ratio = 0;
        switch (cenarioHoterway?.certificate_class) {
          case 0:
            // consumo_pre = cenario?.energy_reference * 0.125;
            consumo_pre = 150 * 0.125;
            break;
          case 1:
            // consumo_pre = cenario?.energy_reference * 0.375;
            consumo_pre = 150 * 0.375;
            break;
          case 2:
            // consumo_pre = cenario?.energy_reference * 0.625;
            consumo_pre = 150 * 0.625;
            break;
          case 3:
            // consumo_pre = cenario?.energy_reference * 0.875;
            consumo_pre = 150 * 0.875;
            break;
          case 4:
            // consumo_pre = cenario?.energy_reference * 1.25;
            consumo_pre = 150 * 1.25;
            break;
          case 5:
            // consumo_pre = cenario?.energy_reference * 1.75;
            consumo_pre = 150 * 1.75;
            break;
          case 6:
            // consumo_pre = cenario?.energy_reference * 2.25;
            consumo_pre = 150 * 2.25;
            break;
          case 7:
            // consumo_pre = cenario?.energy_reference * 3.0;
            consumo_pre = 150 * 3.0;
            break;
          default:
            consumo_pre = 0;
            break;
        }

        expectedEnergy = ((consumo_pre * cenarioHoterway?.building_area) / 1000 / 0.79).toFixed(2);

        ratio =
          ((consumo_pre * cenarioHoterway?.building_area + parseFloat(energy_waste) * 1000) / (150 * cenarioHoterway?.building_area)) * 100;

        if (ratio > 0 && ratio < 25) {
          return 0;
        } else if (ratio > 25 && ratio <= 50) {
          return 1;
        } else if (ratio > 50 && ratio <= 75) {
          return 2;
        } else if (ratio > 75 && ratio <= 100) {
          return 3;
        } else if (ratio > 100 && ratio <= 150) {
          return 4;
        } else if (ratio > 150 && ratio <= 200) {
          return 5;
        } else if (ratio > 200 && ratio <= 250) {
          return 6;
        } else if (ratio > 250) {
          return 7;
        } else {
          return "Erro";
        }
      }

      calculateFinalCertificate();

      setHoterway({
        var3_1: ((water_waste * 100) / base?.var1_2 - 100).toFixed(1),
        var3_2: ((energy_waste * 100) / retorno?.var2_2 - 100).toFixed(1),
        var3_3: (cenarioHoterway?.active_consumption * 24 * 30) / 1000,
        var3_4: parseFloat(energy_waste),
        var3_5: hoterway_divs ?? 0,
        var3_6: (
          (number_hoterway * defaults?.preco_bateria_in + defaults?.preco_master) /
          (retorno?.var2_4 - parseFloat(money_energy))
        ).toFixed(1),
        var3_7: (
          (number_hoterway * defaults?.preco_bateria_active_vip) /
          (retorno?.var2_4 - base?.var1_12 * number_hoterway * base?.var1_14)
        ).toFixed(1),
        var3_8: parseFloat(money_energy),
        var3_9: Math.abs((base?.var1_12 * base?.var1_5) / retorno?.var2_2 - 100).toFixed(1),
        var3_10: number_hoterway,
      });
    } else {
      return;
    }
  }, [base, retorno, cenarioHoterway, defaults]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleSnackbarCloseDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpenDelete(false); // Close the snackbar
  };

  useEffect(() => {
    console.log(percentage);
  }, [percentage]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: MontserratRegular,
      },
      {
        src: MontserratBold,
        fontWeight: "bold",
      },
      {
        src: MontserratItalic,
        fontStyle: "italic",
      },
      // {
      //   src: FontUbuntu700,
      //   fontWeight: 'normal',
      //   fontStyle: 'italic',
      // },
    ],
  });

  // Disable hyphenation globally by setting a hyphenation callback
  Font.registerHyphenationCallback((word) => [word]);

  // Page 1 styles

  const styles = StyleSheet.create({
    page: {
      lineHeight: 1.4,
      fontFamily: "Montserrat",
    },
    section: {
      display: "flex",
      margin: "20 40 40 40",
      // padding: 20,
      flexGrow: 1,
    },
    header: {
      backgroundColor: "#1D9FBC",
      height: 70,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header_logo: {
      maxHeight: 80,
      maxWidth: "100%",
      objectFit: "contain",
      alignSelf: "center",
      marginLeft: 225,
    },
    headerTitle: {
      fontSize: 14,
      fontWeight: "bold",
      color: "white",
      marginLeft: 40,
      marginTop: 10,
    },
    headerSubtitle: {
      fontSize: 9,
      color: "white",
      marginLeft: 40,
      marginTop: 5,
    },
    subHeader: {
      backgroundColor: "#1B8299",
      height: 50,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
    },
    subHeaderLeft: {
      flexDirection: "column",
      marginLeft: 40,
    },
    subHeaderRight: {
      flexDirection: "column",
      marginRight: 115,
    },
    subHeaderTitleLeft: {
      fontSize: 9,
      color: "white",
      marginTop: 5,
    },
    subHeaderTitleRight: {
      fontSize: 9,
      color: "white",
      marginTop: 5,
    },
    footer: {
      backgroundColor: "#B3B3B3",
      height: 40,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: {
      fontSize: 11,
      fontWeight: "bold",
      marginBottom: "10",
      marginTop: "5",
    },
    title2: {
      fontSize: 11,
      fontWeight: "bold",
      marginBottom: "10",
      marginTop: 10,
    },
    text: {
      fontSize: 10,
      marginBottom: "10",
      textAlign: "justify",
    },
    footer_text: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 0 40 40",
    },
    footer_page: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 40 40 40",
      textAlign: "right",
    },
    container: {
      flexDirection: "row",
      width: "100%",
    },
    leftContainer: {
      width: "55%",
      marginLeft: 40,
      marginTop: "10px",
    },
    rightContainer: {
      width: "28.5%",
      marginLeft: 20,
      marginTop: "10px",
    },
    textSectionLeft: {
      paddingTop: 4,
      paddingBottom: 4,
      lineHeight: 1.1,
      backgroundColor: "#666666",
      textAlign: "center",
      borderRadius: 5,
      marginBottom: 10,
    },
    mainTextLeft: {
      fontSize: 9,
      color: "white",
    },
    subtitleLeft: {
      fontSize: 7,
      color: "white",
    },
    textSectionRight: {
      paddingTop: 4,
      paddingBottom: 4,
      lineHeight: 1.1,
      backgroundColor: "#4D4D4D",
      textAlign: "center",
      borderRadius: 5,
      marginBottom: 15,
    },
    textSectionRightTitle: {
      paddingTop: 7,
      paddingBottom: 9,
      lineHeight: 1.1,
      backgroundColor: "#4D4D4D",
      textAlign: "center",
      borderRadius: 5,
      marginBottom: 10,
    },
    mainTextRight: {
      fontSize: 9,
      fontWeight: "bold",
      color: "white",
    },
    subtitleRight: {
      fontSize: 7,
      color: "white",
    },
    imageSection: {
      height: 198,
      backgroundColor: "#999999",
      borderRadius: 5,
      marginBottom: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "100%", // Set the width to 100% to fill the parent container
      height: "100%", // Specify a fixed height to zoom in the image
      // objectFit: "cover", // This will zoom in and crop the sides
    },
    splitSection: {
      flexDirection: "row",
    },
    splitSectionRight: {
      flexDirection: "row",
      marginBottom: 15,
    },
    splitSectionLast: {
      flexDirection: "row",
    },
    leftIconSection: {
      width: "20%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#666666",
      borderRadius: 5,
    },
    leftIconSectionRight: {
      width: "20%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#1D9FBC",
      borderRadius: 5,
    },
    iconLeft: {
      width: 30,
      height: 30,
      objectFit: "contain",
    },
    iconRight: {
      width: 20,
      height: 20,
      objectFit: "contain",
    },
    titleRight: {
      fontWeight: "bold",
      fontSize: 17,
      color: "white",
    },
    subtitleRight2: {
      paddingTop: -5,
      fontSize: 9,
      color: "white",
    },
    extraTitleRight: {
      paddingTop: -5,
      fontStyle: "italic",
      fontSize: 8,
      color: "white",
      marginBottom: -2,
    },
    rightContentSection: {
      borderRadius: 5,
      backgroundColor: "#999999",
      width: "80%",
      paddingLeft: 10,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    rightContentSectionRight: {
      borderRadius: 5,
      backgroundColor: "#1B8299",
      width: "80%",
      paddingLeft: 10,
      justifyContent: "center",
      alignItems: "center",
    },
    letters: {
      paddingRight: 5,
      paddingLeft: 5,
      paddingTop: 2,
      paddingBottom: 2,
      borderRadius: 5,
      fontWeight: "bold",
      fontSize: 16,
      color: "#FFFFFF",
      marginHorizontal: 5, // Add horizontal margin for spacing
    },
    arrowIcon: {
      fontSize: 24,
      color: "white",
      fontWeight: "bold",
    },
  });

  // Page 2 styles

  const styles2 = StyleSheet.create({
    page: {
      lineHeight: 1.4,
      fontFamily: "Montserrat",
    },
    section: {
      display: "flex",
      margin: "20 40 40 40",
      // padding: 20,
      flexGrow: 1,
    },
    header: {
      backgroundColor: "#1D9FBC",
      height: 70,
    },
    footer: {
      backgroundColor: "#B3B3B3",
      height: 40,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    header_logo: {
      maxHeight: 80,
      maxWidth: "100%",
      textAlign: "right",
      objectFit: "contain",
      marginLeft: 400,
    },
    title: {
      fontSize: 11,
      fontWeight: "bold",
      marginBottom: "10",
    },
    title2: {
      fontSize: 11,
      fontWeight: "bold",
      marginBottom: "10",
      marginTop: 10,
    },
    title_disclaimer: {
      fontSize: 9,
      fontWeight: "bold",
      marginBottom: "5",
    },
    text: {
      fontSize: 10,
      marginBottom: "10",
      textAlign: "justify",
    },
    first_text: {
      fontSize: 9,
      marginBottom: "10",
      marginTop: "40",
      textAlign: "justify",
    },
    subtext: {
      fontSize: 9,
      marginBottom: "10",
      textAlign: "justify",
    },
    text_disclaimer: {
      fontSize: 9,
      fontWeight: "bold",
      marginBottom: "10",
      textAlign: "justify",
    },
    footer_text: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 0 40 40",
    },
    footer_page: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 40 40 40",
      textAlign: "right",
    },
    table: {
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000",
      borderCollapse: "collapse",
      marginTop: 10,
      marginBottom: 25,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      padding: 3,
      paddingTop: 4,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000",
      borderCollapse: "collapse",
      textAlign: "center",
      flex: 1,
      fontSize: 10,
    },
    tableHeader: {
      // fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "#CCCCCC",
    },
    mainHeader: {
      backgroundColor: "#999999",
      textAlign: "center",
      fontSize: 12,
      // fontWeight: "bold",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      padding: 4,
      width: "100%",
    },
  });

  // Page 3 styles

  const styles3 = StyleSheet.create({
    page: {
      lineHeight: 1.4,
      fontFamily: "Montserrat",
    },
    section: {
      display: "flex",
      margin: "20px 40px 40px 40px",
      // padding: 20,
      flexGrow: 1,
    },
    header: {
      backgroundColor: "#1D9FBC",
      height: 70,
    },
    footer: {
      backgroundColor: "#B3B3B3",
      height: 40,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    header_logo: {
      maxHeight: 80,
      maxWidth: "100%",
      textAlign: "right",
      objectFit: "contain",
      marginLeft: 400,
    },
    title: {
      fontSize: 11,
      fontWeight: "bold",
      marginBottom: "10",
    },
    title_disclaimer: {
      fontSize: 9,
      marginTop: "200",
      fontWeight: "bold",
      marginBottom: "5",
    },
    title_disclaimer1: {
      fontSize: 9,
      marginTop: "15",
      fontWeight: "bold",
      marginBottom: "5",
    },
    text: {
      fontSize: 10,
      marginBottom: "10",
      textAlign: "justify",
    },
    first_text: {
      fontSize: 9,
      marginBottom: "10",
      textAlign: "justify",
    },
    subtext: {
      fontSize: 9,
      marginBottom: "10",
      textAlign: "justify",
      hyphens: "none"
    },
    text_disclaimer: {
      fontSize: 9,
      fontWeight: "bold",
      marginBottom: "10",
      textAlign: "justify",
    },
    footer_text: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 0 40 40",
    },
    footer_page: {
      fontSize: 7,
      fontWeight: "bold",
      margin: "20 40 40 40",
      textAlign: "right",
    },
  });

  //A4: [595.28, 841.89],

  const originalDate = cenarioBase?.date;
  const dateObj = new Date(originalDate);

  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}${month}${year}.${hours}${minutes}`;

  const divs_columns = [
    {
      Header: "Tempos de espera e volume de água estagnada",
      columns: [
        {
          Header: "Compartimento",
        },
        {
          Header: "Tempo de Espera (mm:ss)",
        },
        {
          Header: "Desperdício Água (litros)",
        },
      ],
    },
  ];

  console.log(base);

  console.log(retorno);

  console.log(hoterway);

  // Create Document Component
  const MyDocument = () => (
    <Document>
      {/* Página 1  */}
      <Page size="A4" style={styles.page}>
        {/* Header Section with Blue Background */}
        <View style={styles.header}>
          <View>
            <Text style={styles.headerTitle}>AUDITORIA À EFICIÊNCIA DA REDE AQS</Text>
            <Text style={styles.headerSubtitle}>
              RELATÓRIO: {idSimulation}.{"" + Array.from(currentUser?.first_name)[0] + Array.from(currentUser?.last_name)[0] + ""}.
              {formattedDate}
            </Text>
          </View>
          <Image style={styles.header_logo} src={HoterwayLogo} />
        </View>

        <View style={styles.subHeader}>
          <View style={styles.subHeaderLeft}>
            <Text style={styles.subHeaderTitleLeft}>IDENTIFICAÇÃO: {base?.var1_4}</Text>
            <Text style={styles.subHeaderTitleLeft}>COMPARTIMENTOS: {base?.var1_5}</Text>
          </View>
          <View style={styles.subHeaderRight}>
            <Text style={styles.subHeaderTitleRight}>TIPO DE TUBAGEM:{base?.var1_6}</Text>
            <Text style={styles.subHeaderTitleRight}>Nº HABITANTES: {base?.var1_7}</Text>
          </View>
        </View>

        <View style={styles.container}>
          {/* Left Side */}
          <View style={styles.leftContainer}>
            <View style={styles.textSectionLeft}>
              <Text style={styles.mainTextLeft}>DIAGRAMA ABASTECIMENTO AQS</Text>
              <Text style={styles.subtitleLeft}>Versão simplificada</Text>
            </View>

            <View style={styles.imageSection}>
              <Image style={styles.image} src={base?.var1_28} />
            </View>
          </View>

          {/* Right Side - Same as the Left */}
          <View style={styles.rightContainer}>
            {/* Text with subtitle and background */}
            <View style={styles.textSectionRightTitle}>
              <Text style={styles.mainTextRight}>INDICADORES</Text>
            </View>

            {/* Text with subtitle and background */}
            <View style={styles.textSectionRight}>
              <Text style={styles.mainTextRight}>DESPERDÍCIO DE ÁGUA</Text>
              <Text style={styles.subtitleRight}>Sem retorno AQS ativo</Text>
            </View>

            {/* Section divided into two parts */}
            <View style={styles.splitSectionRight}>
              <View style={styles.leftIconSectionRight}>
                <Image style={styles.iconRight} src={WaterIcon} />
              </View>
              <View style={styles.rightContentSectionRight}>
                <Text style={styles.titleRight}>{base?.var1_1}%</Text>
                <Text style={styles.subtitleRight2}>do consumo total</Text>
                <Text style={styles.extraTitleRight}>({base?.var1_2} m³/ano)</Text>
              </View>
            </View>

            {/* Text with subtitle and background */}
            <View style={styles.textSectionRight}>
              <Text style={styles.mainTextRight}>AUMENTO DO CONSUMO ENERGÉTICO</Text>
              <Text style={styles.subtitleRight}>Com retorno AQS ativo</Text>
            </View>

            {/* Section divided into two parts */}
            <View style={styles.splitSectionRight}>
              <View style={styles.leftIconSectionRight}>
                <Image style={styles.iconRight} src={ElectricityIcon} />
              </View>
              <View style={styles.rightContentSectionRight}>
                <Text style={styles.titleRight}>{retorno?.var2_1}%</Text>
                <Text style={styles.subtitleRight2}>face ao consumo previsto</Text>
                <Text style={styles.extraTitleRight}>({retorno?.var2_2} MWh /ano)</Text>
              </View>
            </View>
          </View>
        </View>

        {/* Additional Text Sections */}
        <View style={styles.section}>
          <Text style={styles.title}>RESUMO</Text>

          <Text style={styles.text}>
            A análise ao projeto de A.Q.S. identificou um tempo de espera máximo pela água quente de {base?.var1_8} segundos (ponto de
            entrega: {base?.var1_9}), tendo por base o caudal mínimo do consumo. Para além do desconforto associado à espera, prevê-se um
            desperdício de água diretamente relacionado com a espera de {base?.var1_10} litros/ano, cerca de {base?.var1_1} % do consumo
            total de água previsto.
          </Text>

          <Text style={styles.text}>
            Por outro lado, a utilização de um circuito de retorno AQS (ativação permanente), resultará num aumento do consumo energético do
            edifício de {retorno?.var2_1} %, uma situação incomportável que acabará por levar à desativação do sistema ou a uma utilização
            fortemente condicionada por forma a mitigar a elevada ineficiência.
          </Text>

          <Text style={styles.text}>
            O sistema inovador Hoterway combina eficiências hídrica e energética. Considerando os volumes de água da tubagem e os tempos de
            espera calculados, propomos duas soluções:
          </Text>

          <Text style={styles.text}>
            Solução 1 - {hoterway?.var3_10}x Hoterway IN no(s) ponto(s) de entrega: {hoterway?.var3_5}. Com base na redução do consumo
            energético, a solução que propomos oferece um período de retorno de investimento inferior a {hoterway?.var3_6} ano(s).
          </Text>

          <Text style={styles.text}>
            Solução 2 - {hoterway?.var3_10}x Hoterway ACTIVE no(s) ponto(s) de entrega: {hoterway?.var3_5}. Com base na redução do consumo
            energético, a solução que propomos oferece um período de retorno de investimento inferior a {hoterway?.var3_7} ano(s).
          </Text>
        </View>

        {/* Footer Section with Grey Background */}
        <View style={styles.footer}>
          <Text style={styles.footer_text}>www.hoterway.com</Text>
          <Text style={styles.footer_page}>1</Text>
        </View>
      </Page>

      {/* Página 2  */}
      <Page size="A4" style={styles2.page}>
        {/* Header Section with Blue Background */}
        <View style={styles2.header}>
          <Image style={styles2.header_logo} src={HoterwayLogo}></Image>
        </View>

        {/* Additional Text Sections */}
        <View style={styles2.section}>
          <Text style={styles2.title}>OPÇÃO 1 - Sem retorno AQS ou tecnologia equivalente</Text>

          <Text style={styles2.text}>
            A tabela seguinte apresenta os tempos de espera e volumes de água a desperdiçar em cada utilização de água quente, no caso de
            não ser previsto circuito de retorno AQS ou tecnologia equivalente para eliminar o tempo de espera pela água quente.
          </Text>

          {/* TABELA */}
          <View style={styles2.table}>
            {/* Main Header spanning across the three columns */}
            <View style={styles2.tableRow}>
              <Text style={styles2.mainHeader}>{divs_columns[0].Header}</Text>
            </View>
            {/* Render table header */}

            <View style={styles2.tableRow}>
              {divs_columns[0].columns.map((col, index) => (
                <Text key={index} style={[styles2.tableCell, styles2.tableHeader]}>
                  {col.Header}
                </Text>
              ))}
            </View>

            {/* Render table rows */}
            {base?.var1_11.map((row, index) => (
              <View style={styles2.tableRow} key={index}>
                <Text style={styles2.tableCell}>{row.nickname}</Text>
                <Text style={styles2.tableCell}>{row.espera}</Text>
                <Text style={styles2.tableCell}>{row.agua_estagnada.toFixed(2)}</Text>
              </View>
            ))}
          </View>

          <Text style={styles2.title}>OPÇÃO 2 - Com retorno AQS</Text>

          <Text style={styles2.text}>
            A instalação e utilização permanente de circuito de retorno AQS reduz o tempo de espera pela água quente para {retorno?.var2_5}{" "}
            segundos, no entanto, as perdas térmicas na tubagem e o consumo da bomba representam um consumo energético anual de{" "}
            {retorno?.var2_2} MWh/ano, o que corresponderá um custo operacional de cerca de {retorno?.var2_4}€.
          </Text>

          <Text style={styles2.text}>
            No caso deste consumo energético ser considerado no certificado energético SCE, o impacto implicaria uma redução do certificado
            energético de {base?.var1_3} para {retorno?.var2_3}.
          </Text>

          <Text style={styles2.title2}>OPÇÃO 3 - Com baterias térmicas Hoterway</Text>

          <Text style={styles2.text}>
            A integração do Hoterway na rede de abastecimento de água quente permitirá eliminar o tempo de espera pela água quente,
            oferecendo uma solução com a mesma resposta do cenário com recirculação ativa, mas sem o consumo energético proibitivo dessa
            solução.
          </Text>

          {/* ALTERAR AQUI AS PERCENTAGENS!!! */}

          <Text style={styles2.text}>
            A solução de funcionamento passivo Hoterway IN terá associada um custo energético de apenas {hoterway?.var3_4} MWh por ano
            (cerca de {hoterway?.var3_8} €), apresentado assim uma redução do consumo energético de {hoterway?.var3_9} % quando comparado
            com a solução alternativa, a utilização permanente do circuito de retorno AQS.
          </Text>

          <Text style={styles2.text}>
            Em alternativa, a solução Hoterway ACTIVE terá associada um custo energético anual de{" "}
            {base?.var1_12 === 0.26 && 0.26 * base?.var1_5 + " MWh (versão STD_EPS)"}
            {base?.var1_12 === 0.11 && 0.11 * base?.var1_5 + " MWh (versão STD_VIP)"}
            {base?.var1_12 === 0.31 && 0.31 * base?.var1_5 + " MWh (versão XL_EPS)"}
            {base?.var1_12 === 0.14 && 0.14 * base?.var1_5 + " MWh (versão XL_VIP)"}, apresentando assim uma redução do consumo energético
            de {hoterway?.var3_9} % quando comparado com a utilização permanente do circuito de retorno AQS.
          </Text>
        </View>

        {/* Footer Section with Grey Background */}
        <View style={styles2.footer}>
          <Text style={styles2.footer_text}>www.hoterway.com</Text>
          <Text style={styles2.footer_page}>2</Text>
        </View>
      </Page>

      {/* Página 3  */}
      <Page size="A4" style={styles3.page}>
        {/* Header Section with Blue Background */}
        <View style={styles3.header}>
          <Image style={styles3.header_logo} src={HoterwayLogo}></Image>
        </View>

        {/* Additional Text Sections */}
        <View style={styles3.section}>
          <Text style={styles3.first_text}>
            Foram considerados equipamentos de consumo (chuveiros e torneiras) com a certificação Europeia de Eficiência Hídrica (UWLA)
            classe A.
          </Text>

          <Text style={styles3.subtext}>
            Os diâmetros de tubagem foram retirados do projeto; no caso de não terem sido disponibilizados, estes foram automaticamente
            calculados com base nos caudais considerados. Qualquer alteração de diâmetros e/ou comprimentos de tubagem, ou caudais nominais
            dos equipamentos de consumo, obriga à reformulação do estudo. Todos os resultados obtidos foram calculados recorrendo à
            ferramenta AWS Hoterway.
          </Text>

          <Text style={styles3.subtext}>
            *O consumo dos sistemas de retorno AQS ainda não é considerado na avaliação da eficiência energética do consumo AQS de acordo
            com a versão atual do regulamento SCE (legislação Portuguesa), situação que se prevê que seja corrigida por forma a ajustar o
            valor do certificado ao valor real de consumo do edifício.
          </Text>

          <Text style={styles3.title_disclaimer1}>Parâmetros considerados no estudo</Text>

          <Text style={styles3.subtext}>
            Utilizações AQS / Pontos de Entrega / Dia / Habitante: {base?.var1_13} || Preço Energia Térmica : {base?.var1_14} €/kWh || Preço
            Energia Elétrica : {base?.var1_15} €/kWh || Recirculação Convencional: Tempo de ativação da bomba : {base?.var1_16} horas/dia ||
            Volume de água depois do ponto de retorno AQS : {base?.var1_17} m³ || Consumo médio de água quente por pessoa : {base?.var1_18}{" "}
            litros || Temperatura da água de entrada (rede) : {base?.var1_19} ºC || Temperatura da distribuição da água quente :{" "}
            {base?.var1_20} ºC || Potência média da bomba de recir. AQS : {base?.var1_21} W/L/min || Custo Água : {base?.var1_22}
            €/m³ || Espessura de isolamento térmico:{base?.var1_23} || Coeficiente Correção da Perda Térmica Teórica:
            {base?.var1_24} || Coeficiente Condutividade Térmica do Isolamento Térmico: {base?.var1_25} || Consumo Hoterway ACTIVE em
            standby: {base?.var1_12} MWh/ano || Área Útil do Edifício: {base?.var1_26} m² || Consumo energético previsto: {base?.var1_27}{" "}
            MWH/ano
          </Text>

          <>
            <Text style={styles3.title_disclaimer}>AVISO</Text>

            <Text style={styles3.text_disclaimer}>
              O presente estudo foi elaborado com base num conjunto de simplificações do projeto, tendo como objetivo evidenciar o problema
              do tempo de espera e os desperdícios de água e energia das várias opções de projeto. No caso de proposta ser elaborada e
              apresentada pela equipa comercial Hoterway, após a adjudicação será feita uma reanálise do estudo pela área técnica que
              remeterá ao técnico responsável pela obra uma tabela com os dimensionamentos da rede de abastecimento que deverá ser
              verificada em obra sob pena de poder haver algum condicionamento ao bom funcionamento do Hoterway. Caso existam desvios
              significativos na proposta revista que comprometa os indicadores de poupança (superior a 15%) ou que obrigue a um aumento do
              custo da solução proposta, o cliente poderá desistir da compra, sendo-lhe restituído o valor pago a quando da adjudicação.
            </Text>
          </>
        </View>

        {/* Footer Section with Grey Background */}
        <View style={styles3.footer}>
          <Text style={styles3.footer_text}>www.hoterway.com</Text>
          <Text style={styles3.footer_page}>3</Text>
        </View>
      </Page>
    </Document>
  );

  const divs_table_columns = useMemo(
    () => [
      {
        Header: t("scenario_report.columns.divs_table_columns.header"),
        columns: [
          {
            Header: t("scenario_report.columns.divs_table_columns.nickname"),
            accessor: "nickname",
          },
          {
            Header: t("scenario_report.columns.divs_table_columns.agua_estagnada"),
            accessor: "agua_estagnada",
          },
          {
            Header: t("scenario_report.columns.divs_table_columns.espera"),
            accessor: "espera",
          },
        ],
      },
    ],
    []
  );

  const connections_table_columns = useMemo(
    () => [
      {
        Header: t("scenario_report.columns.connections_table_columns.header"),
        columns: [
          { Header: t("scenario_report.columns.connections_table_columns.ligacao"), accessor: "ligacao" },
          {
            Header: t("scenario_report.columns.connections_table_columns.comprimento"),
            accessor: "comprimento",
          },
          {
            Header: "Diâmetro Exterior Tubagem (mm)",
            accessor: "diametro_ajustado",
          },
          {
            Header: "Diâmetro Interior Tubagem (mm)",
            accessor: "diametro_interior",
          },
          { Header: t("scenario_report.columns.connections_table_columns2.caudal"), accessor: "caudal" },
          {
            Header: t("scenario_report.columns.connections_table_columns2.agua_estagnada"),
            accessor: "agua_estagnada",
          },
          {
            Header: t("scenario_report.columns.connections_table_columns2.coef_simultaniedade"),
            accessor: "coef_simultaniedade",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      {isLoading || defaults === null ? (
        <LoadingScreen />
      ) : (
        <>
          {/* <PDFViewer width="100%" height="100%">
            <MyDocument />
          </PDFViewer> */}
          <Box m="20px">
            <Header title={"Relatório"} subtitle={"Verifique o seu relatório"} />
          </Box>

          <Grid container xs={2} sm={2} md={2} lg={2} xl={2}>
            <Box
              ml="20px"
              onClick={() => navigate("/lite/my_simulations/")}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                As Minhas Simulações
              </Typography>
            </Box>
          </Grid>

          {divsData &&
          connectionsData &&
          cenarioBase &&
          Object.keys(cenarioBase).length > 0 &&
          cenarioRetorno &&
          Object.keys(cenarioRetorno).length > 0 &&
          cenarioHoterway &&
          Object.keys(cenarioHoterway).length > 0 ? (
            <>
              <Container component="main" maxWidth="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  //AQUI
                  // alignItems="center"
                  mt="30px"
                >
                  <Fade
                    in={generalError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setGeneralError(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.general_error.title")}</AlertTitle>
                      {t("create_scenario.alert_fields.general_error.description")}
                    </Alert>
                  </Fade>
                </Box>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="5px"
                    height="50px"
                    backgroundColor={colors.blueAccent[600]}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={colors.grey[100]}
                      sx={{
                        fontWeight: "bold",
                      }}
                      component="h1"
                      variant="h1"
                    >
                      INDICADORES DE EFICIÊNCIA
                    </Typography>
                  </Box>
                </Grid>
                <Grid container spacing={4} mt="10px" justifyContent="left">
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box
                      m="5px 5px 25px 5px"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.blueAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        component="h3"
                        variant="h3"
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                      >
                        TEMPO MÁXIMO ESPERA
                      </Typography>
                    </Box>
                    <Box
                      m="5px"
                      p="5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.redAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                        component="h3"
                        variant="h3"
                      >
                        {waitingTime}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box
                      m="5px 5px 25px 5px"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.blueAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        component="h3"
                        variant="h3"
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                      >
                        DESPERDÍCIO ÁGUA
                      </Typography>
                    </Box>
                    <Box
                      m="5px"
                      p="5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.redAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                        component="h3"
                        variant="h3"
                      >
                        {base?.var1_1} %
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box
                      m="5px 5px 25px 5px"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.blueAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        component="h3"
                        variant="h3"
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                      >
                        AUMENTO DESPERDÍCIO ENERGIA
                      </Typography>
                    </Box>
                    <Box
                      m="5px"
                      p="5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      height="50px"
                      backgroundColor={colors.redAccent[600]}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        color={colors.grey[100]}
                        sx={{
                          ml: "5px",
                          fontWeight: "bold",
                          marginTop: "1px",
                        }}
                        component="h3"
                        variant="h3"
                      >
                        {retorno?.var2_1} %
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    mt="50px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="5px"
                    height="50px"
                    backgroundColor={colors.blueAccent[600]}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={colors.grey[100]}
                      sx={{
                        fontWeight: "bold",
                      }}
                      component="h1"
                      variant="h1"
                    >
                      TABELAS
                    </Typography>
                  </Box>
                </Grid>
                <>
                  <Grid container spacing={8} mt="1px" justifyContent="left">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                      {Object.keys(cenarioBase?.pepc_connections).length !== 0 && (
                        <MatrixTable columns={connections_table_columns} data={connectionsData} />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                      {Object.keys(cenarioBase?.pepc_connections).length !== 0 && (
                        <MatrixTable columns={divs_table_columns} data={divsData} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                    <Box
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="4px"
                      backgroundColor={colors.greenAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <PictureAsPdfOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: "white",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      />

                      <div
                        onClick={() => {
                          // setSnackbarOpenPDF(true);
                          // setIsLoading(true);
                          setTimeout(() => {
                            // Remove specific items from local storage
                            localStorage.removeItem("cenarioBase");
                            localStorage.removeItem("cenarioRetorno");
                            localStorage.removeItem("cenarioHoterway");
                            window.location.reload();
                          }, 3500);
                        }}
                      >
                        <PDFDownloadLink
                          document={<MyDocument />}
                          fileName={"Relatório_Projeto_AQS_Hoterway_" + idSimulation + ".pdf"}
                          style={{
                            textDecoration: "none",
                            paddingTop: "1.5px",
                            paddingBottom: "1.5px",
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {({ loading }) => (loading ? "A gerar PDF... Aguarde!" : "Exportar Relatório")}
                        </PDFDownloadLink>
                      </div>
                    </Box>
                  </Grid>
                </>
              </Container>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                  backgroundColor={colors.blueAccent[600]}
                  sx={{ textDecoration: "none", m: "20px" }}
                >
                  <Typography
                    color={colors.grey[100]}
                    sx={{
                      fontWeight: "bold",
                    }}
                    component="h1"
                    variant="h1"
                  >
                    PARA VISUALIZAR O SEU RELATÓRIO, TERÁ DE CRIAR UM CENÁRIO PRIMEIRO!
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

export default MyReport;
