import { useEffect, useRef, useState, useContext } from "react";
import { defaults_t } from "../../../types";
import ReactPlayer from "react-player";
import "./App.css";
import "./AddProject.css";
import "react-datepicker/dist/react-datepicker.css";
import KonvaEditor from "../../../components/KonvaEditorLite";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import { useParams, useNavigate } from "react-router-dom";
import Konva from "konva";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import LoadingScreen from "../../global/LoadingScreen";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Box, Typography, useTheme, Grid, Fade, Alert, AlertTitle, Container, Dialog, DialogContent } from "@mui/material";
import { Formik } from "formik";
import { format } from "date-fns";
import { fullDiagramDescription, useWindowSize } from "../../../utils/konvaUtils";

function EditReport() {
  const { t } = useTranslation();

  const [currentArrows, setCurrentArrows] = useState<
    {
      start: Konva.Group;
      children: { x: number; y: number }[];
      end: Konva.Group;
    }[]
  >([]);
  const { idSimulation } = useParams();
  const [open, setOpen] = useState(false);
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const editorRef = useRef<Konva.Stage>();
  const editorContainerRef = useRef<HTMLInputElement>();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [project, setProject] = useState<any>();
  const [isSent, setIsSent] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [scenarios, setScenarios] = useState<any>([]);
  const [basicScenarios, setBasicScenarios] = useState<any>([]);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [canCreateScenario, setCanCreateScenario] = useState(false);
  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const [idNav, setIdNav] = useState(0);
  const [hide, setHide] = useState(false);

  let render_counter = 0;

  const navigate = useNavigate();

  const { currentUser, logout } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/dashboard");
      }
    }
  }, []);

  const getDefaults = async () => {
    try {
      setIsLoading(true);
      const responseA = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(responseA.data.data[0].default_values);
      // First API call
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}simulation/${idSimulation}`, config);
      setProject({ ...response.data.data, scenario_name: "" });

      if (response.data.data.email_comercial !== currentUser.email) {
        setIsLoading(true);
        navigate("/lite/simulations");
      }
      console.log(response.data.data);
      setCanCreateScenario(true);

      // Second API call
      const response2 = await axios.get(`${process.env.REACT_APP_API_CALL}scenario/simulation/${idSimulation}`, config);
      setBasicScenarios(response2.data.data);

      if (response2?.data?.data?.[0]?.id) {
        // Third API call using the ID from response2
        const response3 = await axios.get(`${process.env.REACT_APP_API_CALL}scenario/${response2.data.data[0].id}`, config);

        console.log(response3.data.data);

        // Update scenarios state and log the result
        setScenarios(response3.data.data);
        setPePcLayout(response3.data.data.pepc_layout2);
      } else {
        navigate("/lite/create_report/" + idSimulation);
      }

      setIsLoading(false); // Done loading
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDefaults();
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    if (values.scenario_name === "") {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        await axios.patch(process.env.REACT_APP_API_CALL + "scenario", values, config);
        window.scrollTo(0, 0);

        setHide(true);
        setIsLoading(false);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const [pePcConnections, setPePcConnections] = useState<{
    [key: string]: { [key: string]: number };
  }>({});

  const [pePcConnectionsRe, setPePcConnectionsRe] = useState<{
    [key: string]: { [key: string]: number };
  }>({});

  const [konvaWidth, setKonvaWidth] = useState(100);
  useWindowSize(editorContainerRef, setKonvaWidth);

  const [pePcConnectionsAdjustedDiameter, setPePcConnectionsAdjustedDiameter] = useState<{ [key: string]: { [key: string]: number } }>({});

  const getDivTotalCaudal = (children: string) => {
    const div_key = Object.keys(project.pepc_layout).find(
      (key) => (project.pepc_layout[key]["nickname"] ? project.pepc_layout[key]["nickname"] : project.pepc_layout[key]["name"]) === children
    );
    let res = 0;
    if (div_key) {
      if (isNaN(parseFloat(project.pepc_layout[div_key]["caudal_total"])))
        console.log("error:" + project.pepc_layout[div_key]["caudal_total"]);
      else res += parseFloat(project.pepc_layout[div_key]["caudal_total"]);
    }

    return res;
  };

  const getChildren = (connectionStart: string) => {
    let res = [];
    if (connectionStart in scenarios.pepc_connections)
      for (let connectionEnd of Object.keys(scenarios.pepc_connections[connectionStart])) {
        const children = getChildren(connectionEnd);
        res = [...res, ...children];
      }
    else return [connectionStart];
    return res;
  };

  const calculateAdjustedDiameter = (caudal, tipo_tubo) => {
    if (tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };

  const getAdjustedDiameter = (connectionStart: string, connectionEnd: string) => {
    if (!scenarios.pepc_connections) return defaults.tipos_tubagem[project.tipo_tubo][0].diametro;
    const connectionEndChildren = getChildren(connectionEnd);
    const current_res: {
      ligacao: string;
      comprimento: number;
      coef_simultaniedade: number;
      caudal: number;
      diametro_ajustado: number;
      agua_estagnada: number;
      perda_termica: number;
    } = {
      ligacao: "",
      comprimento: 0,
      coef_simultaniedade: 0,
      caudal: 0,
      diametro_ajustado: 0,
      agua_estagnada: 0,
      perda_termica: 0,
    };
    current_res.ligacao = connectionStart + " -> " + connectionEnd;
    current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
    let caudal_total = 0;
    for (let child of connectionEndChildren) {
      caudal_total += getDivTotalCaudal(child);
    }
    current_res.caudal = current_res.coef_simultaniedade * caudal_total;
    const tubo_stats = calculateAdjustedDiameter(current_res.caudal, project.tipo_tubo);
    return tubo_stats?.diametro ? tubo_stats?.diametro : defaults.tipos_tubagem[project.tipo_tubo][0].diametro;
  };

  useEffect(() => {
    if (scenarios && scenarios.pepc_connections && Object.keys(scenarios.pepc_connections).length > 0) {
      const res = {};
      if (scenarios && scenarios.pepc_connections) {
        for (let connectionStart of Object.keys(scenarios.pepc_connections)) {
          res[connectionStart] = {};
          for (let connectionEnd of Object.keys(scenarios.pepc_connections[connectionStart]))
            if (render_counter < 3 && typeof (scenarios.pepc_connections[connectionStart][connectionEnd] === "object")) {
              //First render
              if (!scenarios.pepc_connections[connectionStart][connectionEnd].adjustedDiameter) {
                res[connectionStart][connectionEnd] = getAdjustedDiameter(connectionStart, connectionEnd);
              } else res[connectionStart][connectionEnd] = scenarios.pepc_connections[connectionStart][connectionEnd].adjustedDiameter;
            } else res[connectionStart][connectionEnd] = getAdjustedDiameter(connectionStart, connectionEnd);
        }

        setPePcConnectionsAdjustedDiameter(res);
      }
    }
  }, [scenarios]);

  useEffect(() => {
    const res2 = {};
    if (scenarios?.pepc_connections) {
      for (let connectionStart of Object.keys(scenarios?.pepc_connections)) {
        res2[connectionStart] = {};
        for (let connectionEnd of Object.keys(scenarios?.pepc_connections[connectionStart]))
          res2[connectionStart][connectionEnd] = scenarios?.pepc_connections[connectionStart][connectionEnd].recirculacao;
      }
      setPePcConnectionsRe(res2);
    }
  }, [scenarios]);

  if (isLoading || !project || !scenarios) {
    return <LoadingScreen />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <Box m="20px">
          <Header title="EDITAR CENÁRIO" subtitle="Edite o seu cenário!" />
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          maxWidth="xl"
          fullWidth
          onContextMenu={(e) => e.preventDefault()} // Prevent context menu when the dialog is open
        >
          <DialogContent sx={{ padding: 0 }}>
            {" "}
            {/* Remove padding to maximize video space */}
            <ReactPlayer
              playing
              url="https://vimeo.com/1025230068?share=copy#t=0"
              width="100%"
              height="100%"
              style={{ aspectRatio: "16/9" }} // Maintains a 16:9 aspect ratio
            />
          </DialogContent>
        </Dialog>

        <Grid container justifyContent="space-between" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            ml="20px"
            onClick={() => navigate("/lite/my_simulations/")}
            p="5px 7px 5px 5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            alignItems="center"
            backgroundColor={colors.blueAccent[600]}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <ArrowBackOutlinedIcon
              sx={{
                textDecoration: "none",
                color: colors.primary[100],
                justifyContent: "center",
              }}
            />
            <Typography
              justifyContent="center"
              color={colors.primary[100]}
              sx={{
                ml: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                justifyContent: "center",
                marginTop: "1px",
              }}
            >
              As Minhas Simulações
            </Typography>
          </Box>
          <Box
            onClick={() => handleClickOpen()}
            mr="40px"
            p="5px 7px 5px 5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            alignItems="center"
            backgroundColor={colors.greenAccent[700]}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <MovieOutlinedIcon
              sx={{
                textDecoration: "none",
                color: colors.primary[100],
                justifyContent: "center",
              }}
            />
            <Typography
              justifyContent="center"
              color={colors.primary[100]}
              sx={{
                ml: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                justifyContent: "center",
                marginTop: "1px",
              }}
            >
              Ver Tutorial (00:28)
            </Typography>
          </Box>
        </Grid>

        {project && Object.keys(project).length > 0 && scenarios && Object.keys(scenarios).length > 0 ? (
          <>
            <Container component="main" maxWidth="100%">
              <Box
                display="flex"
                flexDirection="column"
                //AQUI
                // alignItems="center"
                mt="30px"
              >
                <Fade
                  in={generalError}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      setGeneralError(false);
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.general_error.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.general_error.description")}
                  </Alert>
                </Fade>

                <Fade
                  in={isResidential}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      setIsResidential(false);
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_residential.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.is_residential.description")}
                  </Alert>
                </Fade>

                <Fade
                  in={sessionError}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      setSessionError(false);
                      navigate("/login");
                      logout();
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.session_error.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.session_error.description")}
                  </Alert>
                </Fade>

                <Fade
                  in={isEmpty}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      setIsEmpty(false);
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_empty.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.is_empty.description")}
                  </Alert>
                </Fade>

                <Fade
                  in={isSent}
                  timeout={{ enter: 1000, exit: 1000 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    setTimeout(() => {
                      if (idSimulation) {
                        navigate("/lite/report/" + idSimulation);
                      }
                      setIsSent(false);
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_sent.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.is_sent.description")}
                  </Alert>
                </Fade>

                <Fade
                  in={isEliminated}
                  timeout={{ enter: 1000, exit: 1000 }}
                  unmountOnExit={true}
                  addEndListener={() => {
                    setTimeout(() => {
                      setIsEliminated(false);
                    }, 3000);
                  }}
                >
                  <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.alert_fields.is_eliminated.title")}</AlertTitle>
                    {t("create_scenario.alert_fields.is_eliminated.description")}
                  </Alert>
                </Fade>

                {hide === false ? (
                  <Formik
                    // enableReinitialize={true}
                    onSubmit={handleFormSubmit}
                    initialValues={project}
                    validateOnChange={false}
                    validateOnBlur={true}
                  >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={4} justifyContent="left">
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h3">
                              {t("create_scenario.form.title2")}
                            </Typography>
                          </Grid>

                          {project && Object.keys(project).length > 0 && scenarios && Object.keys(scenarios).length > 0 ? (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box>
                                <div className="canvasContainerContainer">
                                  <div className="canvasContainer" ref={editorContainerRef}>
                                    <KonvaEditor
                                      floors_count={project?.floors_count}
                                      floorsNames={project?.floors_names}
                                      sourceTitle={project?.water_heating_system ?? "T0"}
                                      width={konvaWidth}
                                      refEditor={editorRef}
                                      pePcLayout={project?.pepc_layout}
                                      loadFromSavedObject={scenarios?.editor_image}
                                      pePcConnections={scenarios?.pepc_connections}
                                      canEdit={true}
                                      defaults={defaults}
                                      pePcConnectionsAdjustedDiameter={pePcConnectionsAdjustedDiameter}
                                      pePcConnectionsRe={pePcConnectionsRe}
                                      project={project}
                                      setProject={(newProject) => {
                                        setProject(newProject);
                                      }}
                                      setPePcConnectionsRe={(pepcConnectionsRe) => {
                                        setPePcConnectionsRe(pepcConnectionsRe);
                                      }}
                                      t={t}
                                      setPePcConnectionsAdjustedDiameter={(pepcConnectionsAdjustedDiameter) => {
                                        setPePcConnectionsAdjustedDiameter(pepcConnectionsAdjustedDiameter);
                                      }}
                                      setPePcConnections={(pepcConnections) => {
                                        const newPePcConnections = JSON.parse(JSON.stringify(scenarios?.pepc_connections));
                                        // delete entries that are not in the pepcConnections
                                        // add entries that are not in the scenarios.pepc_connections
                                        if (Object.keys(pepcConnections).length === 0) return;
                                        Object.keys(newPePcConnections).forEach((connectionStart) => {
                                          Object.keys(newPePcConnections[connectionStart]).forEach((connectionEnd) => {
                                            if (!pepcConnections[connectionStart]) {
                                              delete newPePcConnections[connectionStart];
                                            } else if (!pepcConnections[connectionStart][connectionEnd]) {
                                              delete newPePcConnections[connectionStart][connectionEnd];
                                            }
                                          });
                                        });
                                        Object.keys(pepcConnections).forEach((connectionStart) => {
                                          if (!newPePcConnections[connectionStart]) {
                                            newPePcConnections[connectionStart] = {};
                                          }
                                          Object.keys(pepcConnections[connectionStart]).forEach((connectionEnd) => {
                                            if (!newPePcConnections[connectionStart][connectionEnd])
                                              newPePcConnections[connectionStart][connectionEnd] = {
                                                width: 5,
                                                adjustedDiameter: undefined,
                                                recirculacao: 0,
                                              };
                                          });
                                        });

                                        setScenarios({
                                          ...scenarios,
                                          pepc_connections: newPePcConnections,
                                        });
                                      }}
                                      currentArrows={currentArrows}
                                      setCurrentArrows={setCurrentArrows}
                                    />
                                  </div>
                                </div>
                              </Box>
                            </Grid>
                          ) : (
                            <>asda</>
                          )}

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Alert severity="warning" variant="outlined" sx={{ fontSize: "16px" }}>
                              <AlertTitle sx={{ fontSize: "16px" }}>{t("create_scenario.form.guide.title")}</AlertTitle>
                              {t("create_scenario.form.guide.description")}
                            </Alert>
                          </Grid>

                          <Grid container justifyContent="flex-end" spacing={4}>
                            <Grid item xs={12} sm={5} md={2.5} lg={3} xl={1.5} mt="40px">
                              <Box
                                p="5px 7px 5px 5px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="4px"
                                backgroundColor={colors.blueAccent[600]}
                                sx={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={async () => {
                                  const complete_project = { ...scenarios };
                                  const connections_res = {};
                                  const connections_res_retorno = {};

                                  for (let connectionStart of Object.keys(scenarios.pepc_connections)) {
                                    connections_res[connectionStart] = {};
                                    for (let connectionEnd of Object.keys(scenarios.pepc_connections[connectionStart])) {
                                      if (typeof scenarios.pepc_connections[connectionStart][connectionEnd] === "number")
                                        connections_res[connectionStart][connectionEnd] = {
                                          width: scenarios.pepc_connections[connectionStart][connectionEnd],
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                        };
                                      else
                                        connections_res[connectionStart][connectionEnd] = {
                                          width: scenarios.pepc_connections[connectionStart][connectionEnd].width,
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                        };
                                    }
                                  }

                                  for (let connectionStart of Object.keys(scenarios.pepc_connections)) {
                                    connections_res_retorno[connectionStart] = {};
                                    for (let connectionEnd of Object.keys(scenarios.pepc_connections[connectionStart])) {
                                      if (typeof scenarios.pepc_connections[connectionStart][connectionEnd] === "number")
                                        connections_res_retorno[connectionStart][connectionEnd] = {
                                          width: scenarios.pepc_connections[connectionStart][connectionEnd],
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: pePcConnectionsRe[connectionStart][connectionEnd],
                                        };
                                      else
                                        connections_res_retorno[connectionStart][connectionEnd] = {
                                          width: scenarios.pepc_connections[connectionStart][connectionEnd].width,
                                          adjustedDiameter: pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd],
                                          recirculacao: 1,
                                        };
                                    }
                                  }

                                  complete_project["pepc_connections"] = connections_res;
                                  complete_project["editor_image"] = JSON.stringify(
                                    fullDiagramDescription(editorRef.current, currentArrows)
                                  );

                                  // Create a deep copy of pePcLayout
                                  const pePcLayoutWithHoterway = JSON.parse(JSON.stringify(pePcLayout));

                                  // Change hoterway to 1 in the copied object
                                  Object.keys(pePcLayoutWithHoterway).forEach((key) => {
                                    pePcLayoutWithHoterway[key].hoterway = "1";
                                  });

                                  localStorage.removeItem("editor_image");
                                  localStorage.removeItem("connections");

                                  const formattedDate = format(Date.now(), "yyyy-MM-dd HH:mm:ss");

                                  // Define your different sets of values for submission
                                  const submitValues = [
                                    {
                                      id_simulation: scenarios.id_simulation,
                                      scenario_name: basicScenarios[0].scenario_name,
                                      email: complete_project["email"],
                                      email_comercial: complete_project["email_comercial"],
                                      date: formattedDate,
                                      editor_image: complete_project["editor_image"],
                                      konva_image: editorRef?.current?.toDataURL(),
                                      pepc_connections: connections_res,
                                      pepc_layout2: pePcLayout,
                                      notes: complete_project["notes"],
                                      id: basicScenarios[0].id,
                                    },
                                    {
                                      id_simulation: scenarios.id_simulation,
                                      scenario_name: basicScenarios[1].scenario_name,
                                      email: complete_project["email"],
                                      email_comercial: complete_project["email_comercial"],
                                      date: formattedDate,
                                      editor_image: complete_project["editor_image"],
                                      konva_image: editorRef?.current?.toDataURL(),
                                      pepc_connections: connections_res_retorno,
                                      pepc_layout2: pePcLayout,
                                      notes: values.notes,
                                      id: basicScenarios[1].id,
                                    },
                                    {
                                      id_simulation: scenarios.id_simulation,
                                      scenario_name: basicScenarios[2].scenario_name,
                                      email: complete_project["email"],
                                      email_comercial: complete_project["email_comercial"],
                                      date: formattedDate,
                                      editor_image: complete_project["editor_image"],
                                      konva_image: editorRef?.current?.toDataURL(),
                                      pepc_connections: connections_res,
                                      pepc_layout2: pePcLayoutWithHoterway,
                                      notes: values.notes,
                                      id: basicScenarios[2].id,
                                    },
                                  ];

                                  // Submit each set of values
                                  for (const submitData of submitValues) {
                                    await handleFormSubmit(submitData);
                                  }

                                  setIsSent(true);
                                }}
                              >
                                <LoupeOutlinedIcon
                                  sx={{
                                    textDecoration: "none",
                                    color: colors.grey[100],
                                    justifyContent: "center",
                                  }}
                                />
                                <Typography
                                  variant="h3"
                                  justifyContent="center"
                                  color={colors.grey[100]}
                                  sx={{
                                    ml: "5px",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    marginTop: "1px",
                                  }}
                                >
                                  Guardar
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <LoadingScreen />
                )}
              </Box>
            </Container>
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
}

export default EditReport;
