import { useState, useEffect, useContext, cloneElement } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  useTheme,
  Typography,
  Container,
  Avatar,
  Grid,
  Fade,
  InputAdornment,
  IconButton,
  Stack,
  Card,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { tokens } from "../../theme";
import YupPassword from "yup-password";
import { useTranslation } from "react-i18next";
YupPassword(yup);

const items = [
  {
    icon: <NoteAddRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "CRIE SIMULAÇÕES",
    description:
      "Defina as características de base do edifício, como a quantidade de compartimentos servidos por A.Q.S., quantidade de pisos, tipo de tubagem e etiquetas a usar no esquema A.Q.S.",
  },
  {
    icon: <BorderColorRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "EDITE AS CARACTERÍSTICAS",
    description:
      "Editar as características técnicas do edifício, como por exemplo, o tipo de isolamento térmico da tubagem, a área útil, o número de habitantes e o consumo A.Q.S. por habitante.*",
  },
  {
    icon: <AccountTreeRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "DESENHE O SEU DIAGRAMA",
    description:
      "Defina as ligações entre o ponto de produção A.Q.S. e os compartimentos, com a possibilidade de criar diferentes traçados, utilizar retorno A.Q.S e/ou baterias térmicas Hoterway.",
  },
  {
    icon: <AssessmentRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "GERE O SEU RELATÓRIO PERSONALIZADO",
    description:
      "Defina um cenário de base (sem solução para o problema), um cenário com retorno A.Q.S. e um cenário com baterias Hoterway para ser gerado um relatório comparativo.*",
  },
];

const initialValues = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  email_supervisor: "",
  profile_image: null,
  password: "",
  user_type: "",
  license: "",
};

const Register = () => {
  const { t } = useTranslation();
  const [logoSrc, setLogoSrc] = useState("../../assets/company_logo.png");
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [completeRegister, setCompleteRegister] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [errorMessageAlreadyCreated, setErrorMessageAlreadyCreated] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [limitError, setLimitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (theme.palette.mode === "light") {
      setLogoSrc("../../assets/company_logo.png");
    } else {
      setLogoSrc("../../assets/company_logo_white.png");
    }
  }, [theme.palette.mode]);

  const navigate = useNavigate();

  const { currentUser, logout } = useContext(AuthContext);

  // const userSchema = yup.object().shape({
  //   first_name: yup.string().required(t("register.schema.first_name")).typeError(t("register.schema.first_name")),
  //   last_name: yup.string().required(t("register.schema.last_name")).typeError(t("register.schema.last_name")),
  //   license: yup.string().required(t("register.schema.license")).typeError(t("register.schema.license")),
  //   email: yup.string().email(t("register.schema.email.string")).required(t("register.schema.email.required")),
  //   email_supervisor: yup
  //     .string()
  //     .email(t("register.schema.email_supervisor.string"))
  //     .required(t("register.schema.email_supervisor.required"))
  //     .typeError(t("register.schema.email_supervisor.required")),
  //   password: yup
  //     .string()
  //     .password()
  //     .required(t("register.schema.password.required"))
  //     .min(8, t("register.schema.password.min"))
  //     .minLowercase(1, t("register.schema.password.min_lower_case"))
  //     .minUppercase(1, t("register.schema.password.min_upper_case"))
  //     .minNumbers(1, t("register.schema.password.min_numbers"))
  //     .minSymbols(0, t("register.schema.password.min_symbols")),
  //   password2: yup
  //     .string()
  //     .password()
  //     .required(t("register.schema.password2.required"))
  //     .min(8, t("register.schema.password2.min"))
  //     .minLowercase(1, t("register.schema.password2.min_lower_case"))
  //     .minUppercase(1, t("register.schema.password2.min_upper_case"))
  //     .minNumbers(1, t("register.schema.password2.min_numbers"))
  //     .minSymbols(0, t("register.schema.password2.min_symbols"))
  //     .oneOf([yup.ref("password"), null], t("register.schema.password2.one_of")),
  // });

  const userSchema = yup.object().shape({
    first_name: yup.string().required(t("register.schema.first_name")).typeError(t("register.schema.first_name")),
    last_name: yup.string().required(t("register.schema.last_name")).typeError(t("register.schema.last_name")),
    email: yup.string().email(t("register.schema.email.string")).required(t("register.schema.email.required")),
    password: yup
      .string()
      .password()
      .required(t("register.schema.password.required"))
      .min(8, t("register.schema.password.min"))
      .minLowercase(1, t("register.schema.password.min_lower_case"))
      .minUppercase(1, t("register.schema.password.min_upper_case"))
      .minNumbers(1, t("register.schema.password.min_numbers"))
      .minSymbols(0, t("register.schema.password.min_symbols")),
    password2: yup
      .string()
      .password()
      .required(t("register.schema.password2.required"))
      .min(8, t("register.schema.password2.min"))
      .minLowercase(1, t("register.schema.password2.min_lower_case"))
      .minUppercase(1, t("register.schema.password2.min_upper_case"))
      .minNumbers(1, t("register.schema.password2.min_numbers"))
      .minSymbols(0, t("register.schema.password2.min_symbols"))
      .oneOf([yup.ref("password"), null], t("register.schema.password2.one_of")),
  });

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const config2 = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const handleAxios = async (event) => {
    const formData = new FormData();
    formData.append("file", event);

    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_CALL + "upload/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data", "access-token": process.env.REACT_APP_ACCESS_TOKEN },
      });
      setError(false);
      setImageAdded(true);
    } catch (error) {
      setError(true);
      setLimitError(true);
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    handleAxios(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsImageLoading(true);
    setTimeout(() => {
      setIsImageLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 4) {
        navigate("/lite/my_simulations");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  // const handleFormSubmitComplete = async (values) => {
  //   try {
  //     let valores;
  //     let valores2;
  //     let licenseValores;
  //     let licenses;

  //     const response = await axios.get(process.env.REACT_APP_API_CALL + "licenses/", config2);

  //     licenses = response.data.data;

  //     // The key you're searching for
  //     const searchKey = values.license;

  //     // Function to find the key in the object
  //     function findLicenseByKey(key) {
  //       for (const licenseIndex in licenses) {
  //         if (licenses[licenseIndex].license === key) {
  //           return licenses[licenseIndex];
  //         }
  //       }
  //       return null; // Key not found
  //     }

  //     // Find the license with the searchKey
  //     const foundLicense = findLicenseByKey(searchKey);

  //     if (foundLicense) {
  //       const response2 = await axios.get(process.env.REACT_APP_API_CALL + `licenses/${foundLicense.id}`, config2);

  //       setLicenseError(false);

  //       if (values.password === values.password2) {
  //         if (selectedFile && error === true) {
  //           valores = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: "",
  //             password: values.password,
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //           valores2 = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: "",
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //         } else if (selectedFile && error === false) {
  //           valores = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: selectedFile.name,
  //             password: values.password,
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //           valores2 = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: "",
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //         } else {
  //           valores = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: currentUser.profile_image,
  //             password: values.password,
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //           valores2 = {
  //             id: currentUser.id,
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             email: currentUser.email,
  //             email_supervisor: currentUser.email_supervisor,
  //             profile_image: "",
  //             user_type: currentUser.user_type,
  //             license: values.license,
  //           };
  //         }

  //         licenseValores = {
  //           id: response2.data.data[0].id,
  //           license: response2.data.data[0].license,
  //           date: response2.data.data[0].date,
  //           email: currentUser?.email,
  //           type: response2.data.data[0].type,
  //           time: response2.data.data[0].time,
  //           active: 1,
  //         };

  //         await axios.patch(process.env.REACT_APP_API_CALL + "users/password-reset/", valores, config);

  //         await axios.patch(process.env.REACT_APP_API_CALL + "licenses/", licenseValores, config2);

  //         localStorage.setItem("user", JSON.stringify(valores2));
  //         setProfileComplete(true);
  //         // setHide(true);
  //       } else {
  //         console.log("Error!");
  //       }
  //     } else {
  //       setLicenseError(true);
  //       return;
  //     }
  //   } catch (error) {
  //     if (error.response.data.auth === false) {
  //       setSessionError(true);
  //     } else {
  //       setGeneralError(true);
  //       console.log(error);
  //     }
  //   }
  // };

  const handleFormSubmit = async (values) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config);

      if (response.data.data.length !== 0) {
        setErrorMessageAlreadyCreated(true);
      } else {
        let valores;
        if (values.password === values.password2) {
          if (selectedFile && error === true) {
            valores = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              email_supervisor: values.email_supervisor,
              profile_image: "",
              password: values.password,
              user_type: 4,
              license: values.license,
            };
          } else if (selectedFile && error === false) {
            valores = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              email_supervisor: values.email_supervisor,
              profile_image: selectedFile.name,
              password: values.password,
              user_type: 4,
              license: values.license,
            };
          } else {
            valores = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              email_supervisor: values.email_supervisor,
              profile_image: values.profile_image,
              password: values.password,
              user_type: 4,
              license: values.license,
            };
          }

          await axios.post(process.env.REACT_APP_API_CALL + "users/register", valores, config);
          setProfileComplete(true);
        } else {
          console.log("Error!");
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Container component="main" maxWidth="xl" m="30">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.general_error.title")}</AlertTitle>
                  {t("register.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.session_error.title")}</AlertTitle>
                  {t("register.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isRegistered}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsRegistered(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.is_registered.title")}</AlertTitle>
                  {t("register.alert_fields.is_registered.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessageAlreadyCreated}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setErrorMessageAlreadyCreated(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.error_message_already_created.title")}</AlertTitle>
                  {t("register.alert_fields.error_message_already_created.description")}
                </Alert>
              </Fade>

              <Fade
                in={licenseError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setLicenseError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.license_error.title")}</AlertTitle>
                  {t("register.alert_fields.license_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={profileComplete}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setProfileComplete(false);
                    navigate(`/`);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>Sucesso!</AlertTitle>
                  Utilizador registado com sucesso! Aguarde...
                </Alert>
              </Fade>

              <Fade
                in={imageAdded}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setImageAdded(false);
                  }, 2000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.image_added.title")}</AlertTitle>
                  {t("register.alert_fields.image_added.description")}
                </Alert>
              </Fade>

              <Fade
                in={limitError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setLimitError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.limit_error.title")}</AlertTitle>
                  {t("register.alert_fields.limit_error.description")}
                </Alert>
              </Fade>

              {/* <Fade
                in={isWarning}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsWarning(false);
                  }, 8000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="info">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.is_warning.title")}</AlertTitle>
                  {t("register.alert_fields.is_warning.description")}
                </Alert>
              </Fade> */}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "25px" }}>
              <img alt="company-logo" width="25%" height="auto" src={logoSrc} />
            </Box>
            <Typography component="h2" variant="h2" mb="30px" textAlign={"center"} mt="50px">
              Bem-vindo(a) ao simulador AWS!
            </Typography>
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="info">
              É recomendado utilizar o simulador em ambiente Desktop.
            </Alert>
            <Grid container spacing={8} mt="0px">
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <Stack sx={{ flexDirection: "column", alignSelf: "center", gap: 4 }}>
                  {items.map((item, index) => (
                    <Stack key={index} direction="row" sx={{ gap: 2 }}>
                      {cloneElement(item.icon, { fontSize: "large" })} {/* Adjust size */}
                      <div>
                        <Typography gutterBottom component="h3" variant="h3">
                          {item.title}
                        </Typography>
                        <Typography component="body1" variant="body1" sx={{ color: "text.secondary" }}>
                          {item.description}
                        </Typography>
                      </div>
                    </Stack>
                  ))}
                  <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <Typography gutterBottom component="body2" variant="body2">
                      *A edição das características padrão do edifício e a criação de cenários alternativos para além dos cenários de base,
                      retorno AQS e Hoterway (gerados de forma automática) está disponível apenas na versão AWS PRO.
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <Card
                  variant="outlined"
                  sx={{
                    bgcolor: colors.blueAccent[900],
                    color: colors.grey[900],
                    border: "3px solid",
                    borderColor: colors.grey[300],
                    padding: 4,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                      <HowToRegOutlinedIcon />
                    </Avatar>
                    <Typography component="h2" variant="h2" textAlign="center" color={colors.grey[100]} fontWeight="bold">
                      REGISTAR
                    </Typography>
                  </Box>

                  {/* <Box display="flex" justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        marginTop: "10px",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      {(() => {
                        if (selectedFile && isImageLoading === false) {
                          return (
                            <img
                              style={{ borderRadius: "50%", objectFit: "cover" }}
                              alt="profile"
                              width="100px"
                              height="100px"
                              src={process.env.REACT_APP_API_CALL + "files/" + selectedFile.name}
                            />
                          );
                        } else if (selectedFile && isImageLoading === true) {
                          return <CircularProgress style={{ color: colors.greenAccent[500] }} />;
                        } else {
                          return <InsertPhotoOutlinedIcon sx={{ fontSize: "50px" }} />;
                        }
                      })()}
                    </Avatar>
                  </Box>

                  <Button
                    style={{
                      backgroundColor: colors.blueAccent[300],
                      color: "#FFFFFF",
                    }}
                    fullWidth
                    variant="contained"
                    component="label"
                    sx={{
                      mt: 3,
                      color: colors.blueAccent[500],
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                      {t("register.form.photo_title")}
                    </Typography>
                    <input type="file" onChange={handleSubmit} label="Escolha a Imagem" hidden />
                  </Button> */}

                  <>
                    <Formik
                      onSubmit={handleFormSubmit}
                      initialValues={initialValues}
                      validationSchema={userSchema}
                      validateOnChange={false}
                      validateOnBlur={true}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                          <Grid container spacing={2} mt="10px">
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label={t("register.form.first_name")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                name="first_name"
                                error={!!touched.first_name && !!errors.first_name}
                                helperText={touched.first_name && errors.first_name}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label={t("register.form.last_name")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                name="last_name"
                                error={!!touched.last_name && !!errors.last_name}
                                helperText={touched.last_name && errors.last_name}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label={t("register.form.email")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                              />
                            </Grid>

                            <>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  type={showOldPassword ? "text" : "password"}
                                  label={t("register.form.password")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password}
                                  name="password"
                                  disabled={false}
                                  error={!!touched.password && !!errors.password}
                                  helperText={touched.password && errors.password}
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  type={showNewPassword ? "text" : "password"}
                                  label={t("register.form.password2")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password2}
                                  name="password2"
                                  disabled={false}
                                  error={!!touched.password2 && !!errors.password2}
                                  helperText={touched.password2 && errors.password2}
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => setShowNewPassword(!showNewPassword)}
                                          edge="end"
                                          // style={{ marginRight: "0px" }}
                                        >
                                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                />
                              </Grid>
                            </>
                          </Grid>

                          <Button
                            type="submit"
                            color="secondary"
                            fullWidth
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              fontWeight: "bold",
                              color: colors.primary[500],
                            }}
                          >
                            <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                              COMPLETAR REGISTO
                            </Typography>
                          </Button>
                        </form>
                      )}
                    </Formik>
                    <Grid container justifyContent="center">
                      <Grid item m>
                        <Typography justifyContent="center" variant="h5" color={colors.grey[100]} component={Link} to="/">
                          Já tem conta criada? Faça o login aqui!
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default Register;
