import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { Table, TableHead, TableBody, TableRow, TableCell, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { tokens } from "../theme";

const TableContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center', // Center horizontally
  alignItems: 'flex-start', // Align items at the top
});

const StyledTable = styled(Table)(({ theme, isPrintMediaType }) => ({
  borderCollapse: 'collapse',
  border: `1px solid ${isPrintMediaType ? 'black' : 'white'}`, // Border around the entire table
  '& th, & td': {
    border: `1px solid ${isPrintMediaType ? 'black' : 'white'}`,
    padding: theme.spacing(1),
    color: isPrintMediaType ? 'black' : theme.palette.text.primary,
    fontFamily: 'Montserrat, sans-serif', // Apply Montserrat font
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, isPrintMediaType }) => ({
  backgroundColor: isPrintMediaType ? 'white' : theme.palette.background.default,
  '&:nth-of-type(odd)': {
    backgroundColor: isPrintMediaType ? 'white' : theme.palette.action.hover,
  },
}));



function MatrixTable({ columns, data }) {
  const [isPrintMediaType, setIsPrintMediaType] = useState(window.matchMedia('print').matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('print');
    const listener = (e) => {
      setIsPrintMediaType(e.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <TableContainer>
      <StyledTable {...getTableProps()} isPrintMediaType={isPrintMediaType}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow key={`headerGroup_${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  key={column.id}
                  {...column.getHeaderProps()}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: isPrintMediaType ? 'lightgray' : colors.blueAccent[600],
                    color: isPrintMediaType ? 'black' : 'white',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif', // Apply Montserrat font to header
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i1) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()} key={`row_${i1}`} isPrintMediaType={isPrintMediaType}>
                {row.cells.map((cell, i2) => (
                  <TableCell
                    key={`cell_${i1 * i2}`}
                    {...cell.getCellProps()}
                    style={{ whiteSpace: 'nowrap', textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }} // Apply Montserrat font to cells
                  >
                    {typeof cell.value === 'number' ? parseFloat(cell.value).toFixed(2) : cell.render('Cell')}
                  </TableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

export default MatrixTable;
