import { useEffect, useState, useContext } from "react";
import { defaults_t } from "../../../types";
import ReactPlayer from "react-player";
import "react-datepicker/dist/react-datepicker.css";
import CoolTable from "../../../components/CoolTable";
import SimpleCoolTable from "../../../components/SimpleCoolTable";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import LoadingScreen from "../../global/LoadingScreen";
import { tokens } from "../../../theme";
import { format } from "date-fns";
import Header from "../../../components/Header";
import {
  Box,
  TextField,
  Alert,
  AlertTitle,
  Fade,
  Container,
  useTheme,
  Typography,
  Grid,
  MenuItem,
  Select,
  Paper,
  Dialog,
  DialogContent,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";

function CreateSimulation() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const [pcCount, setPcCount] = useState(1);
  const [floorsCount, setFloorsCount] = useState(0);
  const [floorNames, setFloorNames] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [idNav, setIdNav] = useState(0);
  const [emails0, setEmails0] = useState([]);
  const [emails1, setEmails1] = useState([]);
  const [hide, setHide] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});

  const [pePcConnections, setPePcConnections] = useState<{
    [key: string]: { [key: string]: number };
  }>({});

  const navigate = useNavigate();

  const { currentUser, logout } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/dashboard");
      }
    }
  }, []);

  const [project, setProject] = useState<any>({
    client_name: "",
    email: "",
    email_comercial: currentUser?.email,
    state: 0,
    date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
    tipo_tubo: "Multicamada",
    has_solar_panels: false,
    house_parts_count: 1,
    pe_usage: 2,
    running_time: 24,
    running_time_hoterway: 0.5,
    volume_aqs: 0.4,
    water_consumption: 60,
    water_in_temp: 10,
    hot_water_temp: 55,
    average_power: 25,
    solar_heater_water: 0,
    insolation_width: 1,
    ccppt: 1.35,
    cct: 1,
    active_consumption: 0.11,
    obs: "",
    pc_count: 1,
    floors_count: 1,
    isShared: 0,
    price_thermal: 0.22,
    price_energy: 0.22,
    water_heating_system: null,
    water_price: 4.25,
    building_area: 150,
    energy_reference: 150,
    certificate_class: 1,
    building_type: 0,
    simulation_type: "lite",
  });

  useEffect(() => {
    if (floorNames.length === floorsCount) return;
    const newNames = [...floorNames];
    if (floorNames.length > floorsCount) for (let i = floorNames.length; i > floorsCount; i--) newNames.pop();
    else if (floorNames.length < floorsCount) for (let i = floorsCount; i < floorNames.length; i++) newNames.push("");
    setFloorNames(newNames);
  }, [floorsCount]);

  const getEmails = async () => {
    setIsLoading(true);
    try {
      const response1 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/0", config);
      setEmails0(response1.data.data);
      const response2 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/1", config);
      setEmails1(response2.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getDefaults = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDefaults();
    getEmails();
    setFloorsCount(project.floors_count);
  }, []);

  let array = [];
  if (emails0 === undefined || emails1 === undefined) {
    console.log("undefined");
  } else {
    for (let i = 0; i < emails0.length; i++) {
      array.push(emails0[i].email);
    }

    for (let i = 0; i < emails1.length; i++) {
      array.push(emails1[i].email);
    }
  }

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    if (values.client_name === "" || values.email_comercial === "") {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        const response = await axios.post(process.env.REACT_APP_API_CALL + "simulation", values, config);
        window.scrollTo(0, 0);
        setIsSent(true);
        setIdNav(response.data.data.insertId);
        setIsLoading(false);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: "30px", // Set height for the input field here
      marginBottom: "15px",
    },
    "& .MuiFilledInput-root": {
      height: "40px", // Adjust height for filled input
    },
  };

  // const styleRequired = {
  //   "& label.Mui-focused": {
  //     color: colors.primary[100],
  //     fontSize: "16px",
  //     fontWeight: "bold",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: colors.primary[100],
  //       fontWeight: "bold",
  //     },
  //   },
  //   "& .MuiFormHelperText-root": {
  //     fontSize: "14px",
  //     fontWeight: "bold",
  //   },
  //   backgroundColor: colors.blueAccent[1000],
  //   fontWeight: "bold",
  // };

  const simulationSchema = yup.object().shape({
    client_name: yup.string().required(t("create_simulation.schema.client_name")),
    email_comercial: yup.string().email(t("create_simulation.schema.email_comercial")),
    house_parts_count: yup
      .number()
      .typeError(t("create_simulation.schema.house_parts_count.type_error"))
      .required(t("create_simulation.schema.house_parts_count.required"))
      .positive(t("create_simulation.schema.house_parts_count.positive")),
    floors_count: yup
      .number()
      .typeError(t("create_simulation.schema.floors_count.type_error"))
      .required(t("create_simulation.schema.floors_count.required"))
      .min(0, t("create_simulation.schema.floors_count.min")),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("create_simulation.title")} subtitle={t("create_simulation.subtitle")} />
          </Box>

          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            maxWidth="xl"
            fullWidth
            onContextMenu={(e) => e.preventDefault()} // Prevent context menu when the dialog is open
          >
            <DialogContent sx={{ padding: 0 }}>
              {" "}
              {/* Remove padding to maximize video space */}
              <ReactPlayer
                playing
                url="https://vimeo.com/1025178317?share=copy#t=0"
                width="100%"
                height="100%"
                style={{ aspectRatio: "16/9" }} // Maintains a 16:9 aspect ratio
              />
            </DialogContent>
          </Dialog>

          <Grid container justifyContent="space-between" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              ml="20px"
              onClick={() => navigate("/lite/my_simulations")}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                As Minhas Simulações
              </Typography>
            </Box>
            <Box
              onClick={() => handleClickOpen()}
              mr="40px"
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.greenAccent[700]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <MovieOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                Ver Tutorial (00:21)
              </Typography>
            </Box>
          </Grid>
          <Container component="main" maxWidth="100%">
            <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
              <Fade
                in={isEmpty}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsEmpty(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.is_empty.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.is_empty.description")}
                </Alert>
              </Fade>

              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.general_error.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.session_error.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isSent}
                timeout={{ enter: 1000, exit: 1000 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsSent(false);
                    if (idNav !== 0) {
                      navigate("/lite/create_report/" + idNav);
                    }
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.is_sent.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.is_sent.description")}
                </Alert>
              </Fade>

              {hide === false ? (
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={project}
                  validationSchema={simulationSchema}
                  validateOnChange={false}
                  validateOnBlur={true}
                >
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4} mt="10px" justifyContent="left">
                        <Grid container m="30px">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Alert severity="warning" variant="outlined" sx={{ fontSize: "16px" }}>
                              <AlertTitle sx={{ fontSize: "16px" }}>ATENÇÃO!</AlertTitle>Os campos seguintes não podem ser editados
                              posteriormente. Preencha com atenção.
                            </Alert>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3.5}>
                          <Typography
                            sx={{
                              textDecoration: "none",
                              paddingLeft: "6px",
                              marginBottom: "15px",
                              justifyContent: "left",
                              fontWeight: "bold",
                            }}
                            component="h1"
                            variant="h5"
                          >
                            {t("create_simulation.form.client_name")}
                          </Typography>
                          <TextField
                            fullWidth
                            multiline
                            variant="filled"
                            type="text"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.client_name}
                            name="client_name"
                            error={!!touched.client_name && !!errors.client_name}
                            helperText={touched.client_name && errors.client_name}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={2.5}>
                          <Typography
                            sx={{
                              textDecoration: "none",
                              paddingLeft: "6px",
                              marginBottom: "15px",
                              justifyContent: "left",
                              fontWeight: "bold",
                            }}
                            component="h1"
                            variant="h5"
                          >
                            Qtd. Compartimentos com A.Q.S.*
                          </Typography>
                          <TextField
                            id="filled-number"
                            fullWidth
                            variant="filled"
                            type="number"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);

                              if (parseInt(e.target.value) > 0) {
                                let updatePePcLayout = Object.keys(pePcLayout).length - parseInt(e.target.value);
                                if (updatePePcLayout !== 0) {
                                  let pepc_layout_copy = { ...pePcLayout };
                                  for (let i = 0; i < Math.abs(updatePePcLayout); i++) {
                                    if (updatePePcLayout > 0) delete pepc_layout_copy["div_" + (Object.keys(pePcLayout).length - i - 1)];
                                    else {
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)] = {};
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["name"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["nickname"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_minimo"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_total"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["hoterway"] = "0";
                                    }
                                  }
                                  setPePcLayout(pepc_layout_copy);
                                }

                                setProject({
                                  ...project,
                                  house_parts_count: parseInt(e.target.value),
                                });
                              } else {
                                setProject({
                                  ...project,
                                  house_parts_count: 0,
                                });
                              }
                            }}
                            value={values.house_parts_count}
                            name="house_parts_count"
                            error={!!touched.house_parts_count && !!errors.house_parts_count}
                            helperText={touched.house_parts_count && errors.house_parts_count}
                            inputProps={{ style: { fontSize: 16 }, type: "number" }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={1.5}>
                          <Typography
                            sx={{
                              textDecoration: "none",
                              paddingLeft: "6px",
                              marginBottom: "15px",
                              justifyContent: "left",
                              fontWeight: "bold",
                            }}
                            component="h1"
                            variant="h5"
                          >
                            {t("create_simulation.form.floors_count")}
                          </Typography>
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              let value = parseInt(e.target.value);
                              if (value > 0) setFloorsCount(value);
                              if (value === 0) setFloorsCount(0);
                            }}
                            value={values.floors_count}
                            name="floors_count"
                            error={!!touched.floors_count && !!errors.floors_count}
                            helperText={touched.floors_count && errors.floors_count}
                            inputProps={{ style: { fontSize: 16 }, type: "number" }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        {defaults && Object.keys(defaults).length !== 0 && (
                          <Grid item xs={12} sm={6} md={3.5} lg={2} xl={2} sx={{ maxWidth: "100% !important" }}>
                            <Typography
                              sx={{
                                textDecoration: "none",
                                paddingLeft: "6px",
                                marginBottom: "15px",
                                justifyContent: "left",
                                fontWeight: "bold",
                              }}
                              component="h1"
                              variant="h5"
                            >
                              {t("edit_simulation.form.tipo_tubo.title")}
                            </Typography>
                            <Select
                              fullWidth
                              hiddenLabel
                              variant="filled"
                              labelId="tipo_tubo"
                              id="tipo_tubo"
                              value={values.tipo_tubo}
                              onChange={handleChange("tipo_tubo")}
                              sx={{
                                fontSize: "15px",
                                height: "40px",
                              }}
                            >
                              {Object.keys(defaults["tipos_tubagem"]).map((tipo, index) => {
                                return (
                                  <MenuItem key={"tipo_tubo_" + index} value={tipo} sx={{ fontSize: "16px" }}>
                                    {t(`edit_simulation.form.tipo_tubo.${tipo}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={6} md={6} lg={2.5} xl={2}>
                          <Typography
                            sx={{
                              textDecoration: "none",
                              paddingLeft: "6px",
                              marginBottom: "15px",
                              justifyContent: "left",
                              fontWeight: "bold",
                            }}
                            component="h1"
                            variant="h5"
                          >
                            {"Etiqueta Sistema A.Q.S."}
                          </Typography>

                          <TextField
                            fullWidth
                            multiline
                            variant="filled"
                            type="text"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.water_heating_system}
                            name="water_heating_system"
                            error={!!touched.water_heating_system && !!errors.water_heating_system}
                            helperText={touched.water_heating_system && errors.water_heating_system}
                            inputProps={{ style: { fontSize: 16 }, maxLength: 3 }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                          <Typography component="h1" variant="h6" mt="-20px" fontWeight="bold">
                            {t("create_simulation.form.required")}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                          <Typography component="h1" variant="h3" mt="20px" mb="-5px" fontWeight="bold">
                            {t("create_simulation.form.title3")}
                          </Typography>
                        </Grid>

                        <Grid container spacing={4} sx={{ flexDirection: "row", flexWrap: "wrap", m: "0px" }}>
                          {Array.from(Array(project.house_parts_count).keys()).map((i) => {
                            const setPe_PCValues = (items_in) => {
                              let res = {};
                              items_in.forEach((item, index) => {
                                if (index === 0) res["name"] = item;
                                else if (index === 1) res["nickname"] = item;
                                else if (index === 2) res["caudal_minimo"] = item;
                                else if (index === 3) res["caudal_total"] = item;
                                else if (index === 4) res["hoterway"] = item;
                              });

                              let pePcLayoutCopy = pePcLayout;
                              pePcLayoutCopy["div_" + i] = res;
                              setPePcLayout({ ...pePcLayoutCopy });
                            };

                            return (
                              <Grid item xs={12} sm={12} md={12} lg={3} xl={3} key={`compartment-${i}`}>
                                <Paper sx={{ padding: 2, width: "100%" }}>
                                  <Paper sx={{ backgroundColor: colors.blueAccent[500], p: "5px", textAlign: "center" }}>
                                    <Typography component="h4" variant="h4" fontWeight="bold">
                                      {`${t("create_simulation.form.compartment")} ${i + 1}`}
                                    </Typography>
                                  </Paper>

                                  {/* Add a container to center the CoolTable */}
                                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <CoolTable
                                      t={t}
                                      editMode={Object.keys(pePcConnections).length === 0 ? 0 : 1}
                                      index={i}
                                      pepcLayout={null}
                                      setPe_PCValues={setPe_PCValues}
                                      pcCount={pcCount}
                                      sx={{ width: "100%" }} // Ensuring CoolTable takes full width
                                    />
                                  </Box>
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>

                        <Grid container spacing={4} sx={{ flexDirection: "row", flexWrap: "wrap", m: "0px" }}>
                          {Array.from(Array(floorsCount).keys()).map((i) => {
                            const setName = (name) => {
                              const newNames = [...floorNames];
                              newNames[i] = name;
                              setFloorNames(newNames);
                            };

                            return (
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={`floor-${i}`}>
                                <Paper sx={{ padding: 2, width: "100%" }}>
                                  <Paper sx={{ backgroundColor: colors.greenAccent[500], p: "5px", textAlign: "center" }}>
                                    <Typography component="h4" variant="h4" fontWeight="bold">
                                      {t("create_simulation.form.floor") + " " + (i + 1)}
                                    </Typography>
                                  </Paper>

                                  {/* Add a container to center the CoolTable */}
                                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <SimpleCoolTable t={t} index={i} setName={setName} editMode={0} />
                                  </Box>
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px" mr="25px">
                          <Box
                            p="5px 7px 5px 5px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="4px"
                            mb="20px"
                            backgroundColor={colors.blueAccent[600]}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              const complete_project = { ...project };
                              complete_project["pepc_layout"] = {
                                ...pePcLayout,
                              };

                              var system;

                              if (values.water_heating_system === null) {
                                system = "AQS";
                              } else {
                                system = values.water_heating_system;
                              }

                              handleFormSubmit({
                                client_name: values.client_name,
                                email: complete_project["email"],
                                email_comercial: currentUser?.email,
                                house_parts_count: values.house_parts_count,
                                tipo_tubo: values.tipo_tubo,
                                isShared: values.isShared,
                                address: complete_project["address"],
                                average_power: complete_project["average_power"],
                                date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
                                has_solar_panels: complete_project["has_solar_panels"],
                                hot_water_temp: complete_project["hot_water_temp"],
                                insolation_width: complete_project["insolation_width"],
                                ccppt: complete_project["ccppt"],
                                cct: complete_project["cct"],
                                active_consumption: complete_project["active_consumption"],
                                nif: complete_project["nif"],
                                obs: complete_project["obs"],
                                pe_usage: complete_project["pe_usage"],
                                pepc_layout: complete_project["pepc_layout"],
                                phone: complete_project["phone"],
                                postal_code: complete_project["postal_code"],
                                running_time: complete_project["running_time"],
                                running_time_hoterway: complete_project["running_time_hoterway"],
                                volume_aqs: complete_project["volume_aqs"],
                                solar_heater_water: complete_project["solar_heater_water"],
                                state: complete_project["state"],
                                total_habitants: values.house_parts_count,
                                version: complete_project["version"],
                                water_consumption: complete_project["water_consumption"],
                                price_thermal: complete_project["price_thermal"],
                                price_energy: complete_project["price_energy"],
                                water_in_temp: complete_project["water_in_temp"],
                                water_price: complete_project["water_price"],
                                floors_count: floorsCount,
                                floors_names: floorNames,
                                building_area: complete_project["building_area"],
                                energy_reference: complete_project["energy_reference"],
                                certificate_class: complete_project["certificate_class"],
                                building_type: complete_project["building_type"],
                                simulation_type: complete_project["simulation_type"],
                                water_heating_system: system,
                              });
                            }}
                          >
                            <LoupeOutlinedIcon
                              sx={{
                                textDecoration: "none",
                                color: colors.grey[100],
                                justifyContent: "center",
                              }}
                            />
                            <Typography
                              variant="h3"
                              justifyContent="center"
                              color={colors.grey[100]}
                              sx={{
                                ml: "5px",
                                textDecoration: "none",
                                fontWeight: "bold",
                                justifyContent: "center",
                                marginTop: "1px",
                              }}
                            >
                              {t("create_simulation.form.buttons.create_simulation")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              ) : (
                <LoadingScreen />
              )}
            </Box>
          </Container>
        </>
      )}
    </>
  );
}

export default CreateSimulation;
