import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Alert, AlertTitle, useTheme, Typography, Container, Avatar, Grid, Fade } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { tokens } from "../../theme";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
};

const config2 = {
  headers: {
    "access-token": process.env.REACT_APP_ACCESS_TOKEN,
    "Content-Type": "application/json", // Adjust the content type if needed
  },
};

const config = {
  headers: {
    "access-token": process.env.REACT_APP_ACCESS_TOKEN,
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [errorMessageUser, setErrorMessageUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userSchema = yup.object().shape({
    email: yup.string().email(t("forgot_password.schema.email.string")).required(t("forgot_password.schema.email.required")),
  });

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [tempUser, setTempUser] = useState(null);

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentUser) {
      navigate("/forgot_password");
    } else if (
      (currentUser && currentUser.user_type === 4)
      (currentUser && currentUser.user_type === 3) ||
      (currentUser && currentUser.user_type === 2) ||
      (currentUser && currentUser.user_type === 1) ||
      (currentUser && currentUser.user_type === 0)
    ) {
      logout();
      navigate("/forgot_password");
      // window.location.reload();
    }
  }, [currentUser]);

  const handleFormSubmit = async (values) => {
    try {
      const response1 = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config2);
      if (response1.data.data.length <= 0) {
        setErrorMessageUser(true);
      } else {
        setIsSent(true);
        setErrorMessageUser(false);

        let values2 = {
          password: response1.data.data[0].password,
          user_type: response1.data.data[0].user_type,
          first_name: response1.data.data[0].first_name,
          last_name: response1.data.data[0].last_name,
          id: response1.data.data[0].id,
          email: response1.data.data[0].email,
        };

        await axios.post(process.env.REACT_APP_API_CALL + "users/forgot-password", values2, config);
      }
    } catch (error) {
      setGeneralError(true);
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Container component="main" maxWidth="xs">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "-25px", marginBottom: "-50px" }}>
              <img alt="company-logo" width="100%" height="auto" src={`../../assets/company_logo.png`} />
            </Box>

            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("forgot_password.alert_fields.general_error.title")}</AlertTitle>
                {t("forgot_password.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isSent}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsSent(false);
                  navigate("/");
                }, 5000);
              }}
            >
              <Alert severity="success">
                <AlertTitle>{t("forgot_password.alert_fields.is_sent.title")}</AlertTitle>
                {t("forgot_password.alert_fields.is_sent.description")}
              </Alert>
            </Fade>

            <Fade
              in={errorMessageUser}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setErrorMessageUser(false);
                }, 3000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("forgot_password.alert_fields.error_message_user.title")}</AlertTitle>
                {t("forgot_password.alert_fields.error_message_user.description")}
              </Alert>
            </Fade>

            <>
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <AlternateEmailOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h2">
                {t("forgot_password.verify_button")}
              </Typography>
            </>
          </Box>

          <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={userSchema}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  multiline
                  variant="filled"
                  type="text"
                  label={t("forgot_password.form.email")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  sx={style}
                />

                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                >
                  <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                    {t("forgot_password.form.buttons.recover_password")}
                  </Typography>
                </Button>
              </form>
            )}
          </Formik>

          <Grid container justifyContent="flex-end">
            <Grid item m>
              <Typography justifyContent="end" variant="h5" color={colors.grey[100]} component={Link} to="/login">
                {t("forgot_password.form.buttons.already_have")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ForgotPassword;
