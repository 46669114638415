import React from 'react';

import {
    Box,
    TextField,
    Alert,
    AlertTitle,
    Fade,
    Container,
    useTheme,
    Typography,
    Grid,
    MenuItem,
    Select,
    Paper,
    Button,
} from "@mui/material";

export default class DynamicTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.initialName || '', // Initialize with the initialName prop
            t: this.props.t,
            editMode: this.props.editMode,
            index: this.props.index,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editMode !== this.props.editMode) {
            this.setState({ editMode: this.props.editMode });
        }
    }

    handleNameChanged(event) {
        const newName = event.target.value;
        this.setState({ name: newName });
        this.props.setName(newName);
    }

    renderRows() {
        const context = this;
        return (
            <tr key={"name-" + this.state.index} className="projectFieldContainerContainer">
                <Grid item
                    width="100%"
                    textAlign="center"
                    display="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        sx={{
                            textDecoration: "none",
                            marginTop: "10px",
                            marginBottom: "10px",
                            justifyContent: "left",
                            textAlign: "center",
                            fontWeight: "bold"
                        }}
                        component="h1"
                        variant="h5"
                    >
                        {context.state.t('components.simple_cool_table.description')}
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        variant="filled"
                        type="text"
                        disabled={context.state.editMode >= 1 ? true : false}
                        onChange={context.handleNameChanged.bind(context)}
                        value={this.state.name}
                        name="water_heating_system"
                        inputProps={{
                            style: {
                                fontSize: 16,
                                padding: "60px 0", // Adjust top and bottom padding for vertical centering
                                height: "40px", // Set a custom height
                            },
                            maxLength: 12,
                        }}
                        InputLabelProps={{
                            style: { fontSize: 16 },
                        }}
                        sx={{
                            "& .MuiFilledInput-root": {
                                height: "40px", // Set the same custom height for the filled input
                                display: 'flex',
                                alignItems: 'center', // Center the content vertically
                            }
                        }}
                    />
                </Grid>
            </tr>
        );
    }

    render() {
        return (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr></tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        );
    }
}