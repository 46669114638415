import { Box, useTheme, Typography, Grid, Stack, MenuItem, Select, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/Logout";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import PortugalIcon from "../../images/portugal.png";
import UnitedKingdomIcon from "../../images/united-kingdom.png";
import GermanyIcon from "../../images/germany.png";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [version, setVersion] = useState("lite");
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  const { currentUser, logout, verifyUser, calculateRemainingTime } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("i18nextLng") || "pt");
  const handleLanguageChange = (event, newLanguage) => {
    console.log("New Language:", newLanguage); // Debugging output
    if (newLanguage !== null) {
      setCurrentLanguage(newLanguage);
      console.log("Current Language State Updated:", newLanguage); // Debugging output
      changeLanguage(newLanguage);
    }
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        if (location.pathname !== "/") {
          logout();
        }
        navigate("/");
      } else if (response.data.data[0].time === 0) {
        if (location.pathname !== "/") {
          logout();
        }
        navigate("/");
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const path = location.pathname;

    if (currentUser) {
      verifyUser(currentUser);
      if (currentUser?.user_type !== 4) {
        verifyLicense();
        calculateRemainingTime(currentUser);
        localStorage.setItem("version", "pro");
      } else {
        localStorage.setItem("version", "lite");
      }
    }

    const isResetPasswordPage = path.startsWith("/reset_password");

    if (!currentUser && path !== "/" && path !== "/register" && path !== "/forgot_password" && !isResetPasswordPage) {
      logout();
      navigate("/");
    }
  }, [currentUser, location.pathname]);

  return (
    <Stack spacing={4}>
      <Grid container xs={12} justifyContent="flex-end" paddingTop={2} paddingLeft={4} paddingRight={2} spacing={2}>
        {currentUser &&
          (currentUser.user_type === 0 ||
            currentUser.user_type === 1 ||
            currentUser.user_type === 2 ||
            currentUser.user_type === 3 ||
            currentUser.user_type === 4) && (
            <>
              {/* <Grid item xs={12} sm={2.5} md={1.5} lg={1.5} xl={1}>
                <Box
                  m="0 auto"
                  p="5px 7px 5px 5px"
                  display="flex"
                  justifyContent="center"
                  borderRadius="4px"
                  alignItems="center"
                  height="31px"
                  backgroundColor={colors.blueAccent[600]}
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                  component={Link}
                  to={`/edit_profile/${currentUser.id}`}
                >
                  <AccountBoxOutlinedIcon sx={{ color: colors.primary[100] }} />
                  <Typography
                    color={colors.primary[100]}
                    sx={{
                      ml: "5px",
                      fontWeight: "bold",
                      marginTop: "1px",
                    }}
                  >
                    {t("topbar.profile")}
                  </Typography>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={2.5} md={1.5} lg={2} xl={1.5}>
                <Box
                  m="0 auto"
                  p="5px 7px 5px 5px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="4px"
                  height="31px"
                  backgroundColor={colors.redAccent[600]}
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => logout()}
                >
                  <LogoutOutlinedIcon sx={{ color: colors.grey[100] }} />
                  <Typography
                    color={colors.grey[100]}
                    sx={{
                      ml: "5px",
                      fontWeight: "bold",
                      marginTop: "1px",
                    }}
                  >
                    {t("topbar.logout")}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

        {/* <Grid item xs={12} sm={2.5} md={1.5} lg={2} xl={1.5}>
          <Box display="flex" alignItems="center" backgroundColor={colors.blueAccent[600]} borderRadius="4px">
            <ToggleButtonGroup
              fullWidth
              height="auto"
              sx={{ cursor: "pointer", textDecoration: "none" }}
              value={currentLanguage}
              exclusive
              onChange={handleLanguageChange}
            >
              <ToggleButton
                value="pt"
                selected={currentLanguage === "pt"}
                sx={{
                  height: "30px",
                  backgroundColor: currentLanguage === "pt" ? "green" : "transparent",
                  "&:hover": {
                    backgroundColor: currentLanguage === "pt" ? "darkgreen" : "lightgray",
                  },
                  // Ensure it appears green when selected
                  "&.Mui-selected": {
                    backgroundColor: colors.greenAccent[600],
                    color: "white",
                  },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  alt="Portugal Flag"
                  src={PortugalIcon}
                />
              </ToggleButton>

              <ToggleButton
                value="en"
                selected={currentLanguage === "en"}
                sx={{
                  height: "30px",
                  backgroundColor: currentLanguage === "en" ? "green" : "transparent",
                  "&:hover": {
                    backgroundColor: currentLanguage === "en" ? "darkgreen" : "lightgray",
                  },
                  "&.Mui-selected": {
                    backgroundColor: colors.greenAccent[600],
                    color: "white",
                  },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  alt="UK Flag"
                  src={UnitedKingdomIcon}
                />
              </ToggleButton>

              <ToggleButton
                value="de"
                selected={currentLanguage === "de"}
                sx={{
                  height: "30px",
                  backgroundColor: currentLanguage === "de" ? "green" : "transparent",
                  "&:hover": {
                    backgroundColor: currentLanguage === "de" ? "darkgreen" : "lightgray",
                  },
                  "&.Mui-selected": {
                    backgroundColor: colors.greenAccent[600],
                    color: "white",
                  },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  alt="German Flag"
                  src={GermanyIcon}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid> */}

        <Grid item xs={12} sm={3.5} md={2.5} lg={1.5} xl={1}>
          <Box display="flex" alignItems="center" backgroundColor={colors.blueAccent[600]} borderRadius="4px">
            <ToggleButtonGroup
              fullWidth
              height="auto"
              sx={{ cursor: "pointer", textDecoration: "none" }}
              value={theme.palette.mode}
              exclusive
              onChange={colorMode.toggleColorMode}
            >
              <ToggleButton
                value="dark"
                selected={theme.palette.mode === "dark"}
                sx={{
                  height: "30px",
                  backgroundColor: theme.palette.mode === "dark" ? "green" : "transparent",
                  color: theme.palette.mode === "dark" ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.mode === "dark" ? "darkgreen" : "lightgray",
                  },
                  "&.Mui-selected": {
                    backgroundColor: colors.greenAccent[600],
                  },
                }}
              >
                <DarkModeOutlinedIcon sx={{ color: theme.palette.mode === "dark" ? "white" : colors.grey[100] }} />
              </ToggleButton>

              <ToggleButton
                value="light"
                selected={theme.palette.mode === "light"}
                sx={{
                  height: "30px",
                  backgroundColor: theme.palette.mode === "light" ? "green" : "transparent",
                  color: theme.palette.mode === "light" ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.mode === "light" ? "darkgreen" : "lightgray",
                  },
                  "&.Mui-selected": {
                    backgroundColor: colors.greenAccent[600],
                  },
                }}
              >
                <LightModeOutlinedIcon sx={{ color: theme.palette.mode === "light" ? "white" : colors.grey[100] }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {/* <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.greenAccent[600]}
                    sx={{ cursor: "pointer", textDecoration: "none" }}
                    onClick={colorMode.toggleColorMode}
                >
                    {theme.palette.mode === "dark" ? (
                        <LightModeOutlinedIcon sx={{ color: colors.grey[100] }} />
                    ) : (
                        <DarkModeOutlinedIcon sx={{ color: colors.grey[100] }} />
                    )}
                    <Typography
                        color={colors.grey[100]}
                        sx={{
                            ml: "5px",
                            fontWeight: "bold",
                            marginTop: "1px",
                        }}
                    >
                        Mudar Tema
                    </Typography>
                </Box> */}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Topbar;
