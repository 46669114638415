import { useState, useEffect, useContext, cloneElement } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Stack,
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  Typography,
  useTheme,
  Avatar,
  Container,
  Grid,
  Fade,
  InputAdornment,
  Card,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";

const items = [
  {
    icon: <NoteAddRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "CRIE SIMULAÇÕES",
    description:
      "Defina as características de base do edifício, como a quantidade de compartimentos servidos por A.Q.S., quantidade de pisos, tipo de tubagem e etiquetas a usar no esquema A.Q.S.",
  },
  {
    icon: <BorderColorRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "EDITE AS CARACTERÍSTICAS",
    description:
      "Editar as características técnicas do edifício, como por exemplo, o tipo de isolamento térmico da tubagem, a área útil, o número de habitantes e o consumo A.Q.S. por habitante.*",
  },
  {
    icon: <AccountTreeRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "DESENHE O SEU DIAGRAMA",
    description:
      "Defina as ligações entre o ponto de produção A.Q.S. e os compartimentos, com a possibilidade de criar diferentes traçados, utilizar retorno A.Q.S e/ou baterias térmicas Hoterway.",
  },
  {
    icon: <AssessmentRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "GERE O SEU RELATÓRIO PERSONALIZADO",
    description:
      "Defina um cenário de base (sem solução para o problema), um cenário com retorno A.Q.S. e um cenário com baterias Hoterway para ser gerado um relatório comparativo.*",
  },
];

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const { t } = useTranslation();
  const [logoSrc, setLogoSrc] = useState("../../assets/company_logo.png");
  const [isSent, setIsSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [choices, setChoices] = useState(true);
  const [complete, setComplete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [errorMessageUser, setErrorMessageUser] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (theme.palette.mode === "light") {
      setLogoSrc("../../assets/company_logo.png");
    } else {
      setLogoSrc("../../assets/company_logo_white.png");
    }
  }, [theme.palette.mode]);

  const navigate = useNavigate();

  const userSchema = yup.object().shape({
    email: yup.string().email(t("login.schema.email.string")).required(t("login.schema.email.require")),
    password: yup
      .string()
      .password()
      .required(t("login.schema.password.required"))
      .min(8, t("login.schema.password.min"))
      .minLowercase(1, t("login.schema.password.min_lower_case"))
      .minUppercase(1, t("login.schema.password.min_upper_case"))
      .minNumbers(1, t("login.schema.password.min_numbers"))
      .minSymbols(0, t("login.schema.password.min_symbols")),
  });

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const { currentUser, login, logout } = useContext(AuthContext);
  let currentLicense = 10;

  useEffect(() => {
    if (currentUser && currentUser.license !== null) {
      if (currentUser.user_type === 4) {
        navigate("/lite/my_simulations");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const handleFormSubmit = async (values) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${values.email}`, config);

      if (response.data.data.length === 0) {
        currentLicense = 0;
      } else if (
        response.data.data[0].time === "0" ||
        response.data.data[0].time === 0 ||
        response.data.data[0].active === 0 ||
        response.data.data[0].active === "0"
      ) {
        currentLicense = 1;
      } else if (response.data.data[0].active === 1) {
        currentLicense = 2;
      }

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config);

      if (response2.data.data.length <= 0) {
        setErrorMessageUser(true);
      } else {
        let compareBody = {
          id: response2.data.data[0].id,
          first_name: response2.data.data[0].first_name,
          last_name: response2.data.data[0].last_name,
          email: values.email,
          email_supervisor: response2.data.data[0].email_supervisor,
          profile_image: response2.data.data[0].profile_image,
          password: values.password,
          user_type: response2.data.data[0].user_type,
          license: response2.data.data[0].license,
        };
        let valores = {
          id: response2.data.data[0].id,
          first_name: response2.data.data[0].first_name,
          last_name: response2.data.data[0].last_name,
          email: values.email,
          email_supervisor: response2.data.data[0].email_supervisor,
          profile_image: response2.data.data[0].profile_image,
          user_type: response2.data.data[0].user_type,
          license: response2.data.data[0].license,
        };
        const response3 = await axios.post(process.env.REACT_APP_API_CALL + "users/verify", compareBody, config);

        if (response3.data.success !== 1) {
          setErrorMessagePassword(true);
          setErrorMessageUser(false);
          setIsSent(false);
          setIsNone(false);
          setIsExpired(false);
          return;
        } else {
          if (response2.data.data[0].user_type !== 4) {
            if (currentLicense === 1) {
              setIsExpired(true);
              setErrorMessagePassword(false);
              setErrorMessageUser(false);
              setIsSent(false);
              setIsNone(false);
              return;
            } else if (currentLicense === 0) {
              localStorage.setItem("user", JSON.stringify(valores));
              setIsNone(true);
              setErrorMessagePassword(false);
              setErrorMessageUser(false);
              setIsSent(false);
              setIsExpired(false);
              return;
            } else {
              setIsSent(true);
              setIsNone(false);
              setErrorMessagePassword(false);
              setErrorMessageUser(false);
              setIsExpired(false);
              setTimeout(() => {
                login(values);
              }, 1000);
            }
          } else {
            setIsSent(true);
            setIsNone(false);
            setErrorMessagePassword(false);
            setErrorMessageUser(false);
            setIsExpired(false);
            setTimeout(() => {
              login(values);
            }, 1000);
          }
        }
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Container component="main" maxWidth="xl" m="30">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.general_error.title")}</AlertTitle>
                  {t("login.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.session_error.title")}</AlertTitle>
                  {t("login.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isExpired}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsExpired(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_expired.title")}</AlertTitle>
                  {t("login.alert_fields.is_expired.description")}
                </Alert>
              </Fade>

              <Fade
                in={isNone}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsNone(false);
                    navigate(`/register/`);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_none.title")}</AlertTitle>
                  {t("login.alert_fields.is_none.description")}
                </Alert>
              </Fade>

              <Fade
                in={isSent}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsSent(false);
                    window.location.reload();
                  }, 2000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_sent.title")}</AlertTitle>
                  {t("login.alert_fields.is_sent.description")}
                </Alert>
              </Fade>

              <Fade
                in={isComplete}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsComplete(false);
                    navigate("/register/");
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_complete.title")}</AlertTitle>
                  {t("login.alert_fields.is_complete.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessageUser}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setErrorMessageUser(false);
                    window.location.reload();
                  }, 5000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.error_message_user.title")}</AlertTitle>
                  {t("login.alert_fields.error_message_user.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessagePassword}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setErrorMessagePassword(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.error_message_password.title")}</AlertTitle>
                  {t("login.alert_fields.error_message_password.description")}
                </Alert>
              </Fade>

              <Fade
                in={isRegistered}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsRegistered(false);
                    window.location.reload();
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_registered.title")}</AlertTitle>
                  {t("login.alert_fields.is_registered.description")}
                </Alert>
              </Fade>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "25px" }}>
              <img alt="company-logo" width="25%" height="auto" src={logoSrc} />
            </Box>
            <Typography component="h2" variant="h2" mb="30px" textAlign={"center"} mt="50px">
              Bem-vindo(a) ao simulador AWS!
            </Typography>
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="info">
              É recomendado utilizar o simulador em ambiente Desktop.
            </Alert>
            <Grid container spacing={8} mt="0px">
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <Stack sx={{ flexDirection: "column", alignSelf: "center", gap: 4 }}>
                  {items.map((item, index) => (
                    <Stack key={index} direction="row" sx={{ gap: 2 }}>
                      {cloneElement(item.icon, { fontSize: "large" })} {/* Adjust size */}
                      <div>
                        <Typography gutterBottom component="h3" variant="h3">
                          {item.title}
                        </Typography>
                        <Typography component="body1" variant="body1" sx={{ color: "text.secondary" }}>
                          {item.description}
                        </Typography>
                      </div>
                    </Stack>
                  ))}
                  <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <Typography gutterBottom component="body2" variant="body2">
                      *A edição das características padrão do edifício e a criação de cenários alternativos para além dos cenários de base,
                      retorno AQS e Hoterway (gerados de forma automática) está disponível apenas na versão AWS PRO.
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <Card
                  variant="outlined"
                  sx={{
                    bgcolor: colors.blueAccent[900],
                    color: colors.grey[900],
                    border: "3px solid",
                    borderColor: colors.grey[300],
                    padding: 4,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                      <LoginOutlinedIcon />
                    </Avatar>
                    <Typography component="h2" variant="h2" textAlign="center" color={colors.grey[100]} fontWeight="bold">
                      ENTRAR
                    </Typography>
                  </Box>
                  <>
                    <Formik
                      onSubmit={handleFormSubmit}
                      initialValues={initialValues}
                      validationSchema={userSchema}
                      validateOnChange={false}
                      validateOnBlur={true}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                          <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            type="text"
                            label={t("login.form.email")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                          <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            type={showPassword ? "text" : "password"}
                            label={t("login.form.password")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              style: { fontSize: 16 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    // style={{ marginRight: "0px" }}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />

                          {/* <Grid container justifyContent="flex-end">
                            <Grid item m>
                              <Typography justifyContent="end" variant="h5" color={colors.grey[100]} component={Link} to="/forgot_password">
                                {t("login.form.lost_password")}
                              </Typography>
                            </Grid>
                          </Grid> */}

                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            fullWidth
                            sx={{
                              mt: 3,
                              mb: 2,
                            }}
                          >
                            <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                              {t("login.login")}
                            </Typography>
                          </Button>
                        </form>
                      )}
                    </Formik>
                    <Grid container justifyContent="center">
                      <Grid item m>
                        <Typography justifyContent="center" variant="h5" color={colors.grey[100]} component={Link} to="/register">
                          Não tem conta? Registe-se aqui!
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default Login;
